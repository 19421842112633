import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
//import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { apiUrl, ageCalculate } from '../../global';
export default function AddEducation({ setAddGoal, aspire }) {

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

  const Back = () => {
    setAddGoal('');
  }

  const onSubmit = education => {

    const required_after_cal = 17 - profileAge;
    const required_after = required_after_cal < 1 ? 1 : required_after_cal;
    const required_till = required_after + 6;
    const postData = {
      "goal_type": "Education",
      "aspire": aspire,
      "goal_name": "Education",
      "profile_id": sessionStorage.getItem('profile_id'),
      "plan_for": {
        "profile_details_id": education.plan_for,
        "current_age": profileAge < 1 ? 1 : profileAge,
        "relation": 'child',
      },
      "yearly_expense": {
        "0": education.g_amount * education.gcurrencyDigit,
        "1": education.pg_amount * education.pgcurrencyDigit
      },
      "no_years": {
        "0": 4,
        "1": 2
      },
      "goal_params": {
        "age": profileAge < 1 ? 1 : profileAge,
        "post_inflation": 7,
        "post_return": 7,
        "goal_inflation": 7,
        "required_after": required_after,
        "required_till": required_till
      },
      "risk_profile": {
        "available": false,
        "pre_return": 8,
        "risk_profile_id": sessionStorage.getItem('profile_id')
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": 7.5,
        "income_grow": "10"
      },
      "total_abcd": [{
        "amount": parseInt(education.g_amount) + parseInt(education.pg_amount),
        "frequency": 12,
        "no_of_times": 1,
        "gap": 0
      }],
      "actual": [{
        "actual_y": 0,
        "frequency": 1,
        "actual_ls": 1,
        "grow": "0"

      }]
    };

    axios.post(`${apiUrl}goals/add`, postData)
      .then(function (response, data) {
        
        setAddGoal('');
        const profile_id = sessionStorage.getItem('profile_id');
        const gpostData = { profile_id: profile_id };
        axios.post(`${apiUrl}goals/reset`, gpostData)
          .then(function (response) {
            axios.post(`${apiUrl}profile/summary-update`, { profile_id: profile_id }).then(
              (response, data) => {
                window.location.reload();
              }
            );
          })

      })


  }

  const [goalFor, setGoalFor] = useState([]);

  useEffect(() => {
    console.log("First call on mount..");

    axios.get(`${apiUrl}profile-details/getfamily/` + sessionStorage.getItem('profile_id')).then(function (response, data) {

      const filtered = response.data.filter(obj => {
        return obj.relation === 'child' || obj.relation === 'son' || obj.relation === 'daughter';
      });
      setGoalFor(filtered)


    });

    return () => console.log("Cleanup..");
  }, []);


  const [profileAge, setProfileAge] = useState();

  const [goalFound, setGoalFound] = useState(false);
  const profileSelect = (e) => {

    setProfileAge(ageCalculate(e.target[e.target.selectedIndex].getAttribute('g_dob')));
    const postData = {
      'plan_for': e.target.value,
      'goal_type': "Education"
    }
    axios.post(`${apiUrl}goals/check-plan-for-goal`, postData)
      .then(function (response) {
        if (response.data.count > 0) {
          setGoalFound(true);
        } else {
          setGoalFound(false);
        }
      });

  };

  return (
    <Fragment>
      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" class="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
            </div>
            <div className="font14 fw500 color182">
              Add {aspire} Goal
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner pl25">
            <div className="font18 fw600 color182 mt50">Kids Education</div>
            <div className="font14 fw500 color485">Add kids Education plan in your financial journey</div>
          </div>

          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="expandtab font12 color353 fw500">Education plan</div>

              <div className="form-row">
                <div className="col-md-12 pr20">

                  <div className="font14 color212 fw600">Goal Focused On</div>
                  <div className="selectgoal propertysearch mt10">
                    <div className="input-group selectarrow">
                      <select className="custom-select font14 color212 fw500" id="inputGroupSelect01" name='plan_for' {...register("plan_for", { required: 'Required' })} onChange={profileSelect}>
                        <option value="">select</option>
                        {
                          goalFor.map((prof_det, i) => {

                            return (
                              <>
                                <option value={prof_det.profile_details_id} g_dob={prof_det.dob} g_relation={prof_det.relation}>{prof_det.full_name}</option>
                              </>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <span className="text-danger"> {goalFound === true ? 'You have already created goal for this profile.' : errors.plan_for && errors.plan_for.message}</span>
                </div>
              </div>

              <div className="formdevider mt25 mb25" />


              <div className="form-row">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Graduation Amount</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="g_amount" {...register("g_amount", { required: 'Required' })} />
                    <select className="custom-select font14" id="inputGroupSelect01" name='gcurrencyDigit' {...register("gcurrencyDigit")}>
                      <option selected value="100000">Lakhs</option>
                      <option value="10000000">Crore</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.g_amount && errors.g_amount.message}</span>
                </div>
              </div>

              <div className="formdevider mt25 mb25" />

              <div className="form-row mt20">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Post Graduation Amount</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="pg_amount" {...register("pg_amount", { required: 'Required' })} />
                    <select className="custom-select font14" id="inputGroupSelect01" name='pgcurrencyDigit' {...register("pgcurrencyDigit")}>
                      <option selected value="100000">Lakhs</option>
                      <option value="10000000">Crore</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.g_amount && errors.g_amount.message}</span>
                </div>
              </div>


              <div className="saveBtn backsaveBtn">
                <button type="button" class="btn btn-primary" onClick={() => Back()}>Cancel </button>
                <button type="submit" class="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
