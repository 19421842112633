import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../components/global'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import Register from './Register';
import Modal from 'react-bootstrap/Modal';
import * as copy from 'copy-to-clipboard';
import { Dropdown } from 'react-bootstrap'
import SweetAlert from 'sweetalert2'

export default function AdminDashboard() {
  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);
  const loginUser = (id, emailAddress) => {
    axios.get(`${apiUrl}admin/user-login/` + id).then(
      (response, data) => {
        sessionStorage.setItem('token', response.data.token);
        const pdata = { "email": emailAddress };
        axios.post(`${apiUrl}profile/view`, pdata).then(function (response) {
          sessionStorage.setItem('profile_id', response.data.profile_id);
          sessionStorage.setItem('full_name', response.data.first_name + '\'s');
          sessionStorage.setItem('profile_details_id', response.data.profile_details_id);
          if (response.data.step > 3) {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/basic-profile";
          }
        });
      }
    );
  }

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {
      axios.post(`${apiUrl}admin/user-profiles`, { 'rel_manager': sessionStorage.getItem('admin_id'), 'admin_role': sessionStorage.getItem('admin_role') }).then(
        (response, data) => {
          //console.log(response.data);
          setData(response.data);
          setTableRowsData(response.data);
        }
      );

      axios.get(`${apiUrl}admin/get-admins`).then(
        (response, data) => {
          //console.log(response.data);
          setRelManager(response.data);
        }
      );

      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axios.post(`${apiUrl}admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);






  const columns = [
    {
      name: 'ID',
      selector: row => row.profile_id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Rel Manager',
      selector: row => row.admin_name,
      sortable: true,
    },
    {
      name: 'Created On',
      selector: row => row.create_on,
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <><span className='cursor_pointer' onClick={() => loginUser(d.id, d.email)}><i className="fas fa-sync-alt" style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span>         <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" className='planstatus_dots'>
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => EditRelManager(d.profile_id)}><i className="fa fa-user text-primary" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Edit Rel Manager</Dropdown.Item>

          <Dropdown.Item onClick={() => deleteUser(d.id, d.profile_id)}><i className="fas fa-trash-alt text-danger" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Delete User</Dropdown.Item>

          <Dropdown.Item onClick={() => RiskLink(d.id, d.enc_profile)}> <i className="fas fa-paste text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Risk Profile Link</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown></>,
    },
  ];

  const RiskLink = (id, enc_profile) => {
    const link = apiUrl + 'risk-profile-set?profile_id=' + enc_profile;

    if (copy(link)) {

      const config = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
      };

      const postData = { link: link, module_name: 'risk_profile', profile_id: id, created_by: sessionStorage.getItem('admin_id') };
      axios.post(`${apiUrl}url-shared`, postData, config).then(
        (response, data) => {

        }
      );

      SweetAlert.fire({
        toast: true,
        icon: 'success',
        title: 'Link Copied Successfully',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', SweetAlert.stopTimer)
          toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
        }
      })
    } else {
      SweetAlert.fire(
        'Copy Link',
        'Something went wrong',
        'error'
      );
    }


  }

  const UpdateRelManager = () => {
    var postData = {
      'profile_id': profile_id,
      'rel_manager': admin_id
    }
    axios.post(`${apiUrl}profile/update-relmanager`, postData)
      .then(function (response) {

        SweetAlert.fire(
          'Profile',
          'Successfully Updated Rel Manager.',
          'success'
        ).then(function () {
          window.location.href = "/admin-dashboard"
        })

      })
      .catch(function (error) {
        SweetAlert.fire(
          'Profile',
          'Failed.',
          'error'
        )
      });
  }

  const deleteUser = (id, profile_id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this user?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.post(`${apiUrl}admin/user-delete`, { 'id': id, 'profile_id': profile_id }).then(
            function (response) {
              SweetAlert.fire(
                'User',
                'Successfully Deleted.',
                'success'
              ).then(function () {
                window.location.href = "/admin-dashboard";
              });
            }
          )
        }
      });
  }

  const EditRelManager = (id) => {
    setProfile_id(id);
    toggleShow();
  }

  const [title, setTitle] = useState("");
  const onChange = async (e) => {
    setTitle(e.target.value);
    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }


    });
    setTableRowsData(searchData);
  };

  const [relManager, setRelManager] = useState([])
  const [profile_id, setProfile_id] = useState();
  const [modal, setModal] = useState(false);
  const toggleShow = () => setModal(true);
  const toggleClose = () => setModal(false);

  const [admin_id, setadmin_id] = useState();


  const handleChange = (e) => {
    const { value } = e.target;
    setadmin_id(value);
  }



  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Register ulogin={1} relManager={sessionStorage.getItem('admin_id')} />
        </Modal.Body>
      </Modal>

      <Modal show={modal} onHide={toggleClose}>
        <Modal.Header>{"Assign/Update Rel Manager"}</Modal.Header>
        <Modal.Body>
          <div className='col-md-12'>
            <br />
            <select className="form-control digits" name={"admin_id"} value={admin_id} onChange={handleChange} autoComplete="off">
              <option value="">select</option>
              {relManager.map((data, i) => {
                return (
                  <option value={data.admin_id} key={i}>{data.admin_name}</option>
                )
              })}
            </select>
            <br />
            <button className="btn btn-primary" type='button' onClick={() => UpdateRelManager()}> Update Rel Manager </button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="innercontainer mb40">
        <div class="mainwrapper">
          <div className='form_title'><div class="mt30 font21 fw600 color384">Admin Dashboard</div></div>

          <div className="row">
            <div className="col-md-6 m5">
              <button className="btn btn-primary" type='button' onClick={handleShow}> Add User </button>
            </div>
            <div className="col-md-6">
              <div className='text-end'>
                <input
                  type="text"
                  placeholder="Name Search"
                  value={title}
                  onChange={(e) => onChange(e)}
                  style={{ width: "80%" }}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div class="">
            <div class="dashboard_outer mt30">
              <div class="dashboard_table">
                <div class="graphtabs_outer mt25">
                  <div class="graph_container">
                    <div class="dashboardTable">
                      <div class="table-responsive">
                        <DataTable
                          columns={columns}
                          data={tableRowsData}
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}