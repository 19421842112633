import React, { Fragment, useState } from 'react'
import NormalQuestion from './NormalQuestion'
import QnaFirst from './QnaFirst';
import QnaLast from './QnaLast';
import QnaResponse from './QnaResponse';
import questions from './mock.json'; 
import SideImg from '../../img/BasicProfile.png';


export default function Qna() {
 

  //eslint-disable-next-line
  const [questionIndex, setQuestionIndex] = useState(1);
  const [userSlectedAns, setUserSlectedAns] = useState(null);
  const [userSlectedWeight, setUserSlectedWeight] = useState(null);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);

  const handleNext = (ans_id, ques_id, ques_title, weight, category) => {


    setQuestionIndex(questionIndex + 1);
    setUserSlectedAns(ans_id);
    setUserSlectedWeight(weight);

    const qna = questionsAndAnswers;
    qna.push({
      id: questions[questionIndex - 1].id,
      user_answer: ans_id,
      category: category
    });

    setUserSlectedAns(null);
    setUserSlectedWeight(null);
    setQuestionsAndAnswers(qna);

    if (questionIndex === 12) {
      setSteps(3);
      sessionStorage.setItem("QnaJson", JSON.stringify(questionsAndAnswers))
      return;
    }

  }


  const prevClick = () => {

    if (questionIndex === 1) return;
    setQuestionIndex(questionIndex - 1);
    setUserSlectedAns(null);
    setUserSlectedWeight(null);

  }


  const questionList = questions.filter(question =>
    question.itemId.toString() === questionIndex.toString()
  ).map((question) => {
    return <NormalQuestion question={question} setUserSlectedAns={setUserSlectedAns} setUserSlectedWeight={setUserSlectedWeight} userSlectedAns={userSlectedAns} userSlectedWeight={userSlectedWeight} handleNext={handleNext} />
  })

  const [steps, setSteps] = useState(1);
  //console.log(steps);
  return (


    <Fragment>

      <div className="innercontainer mb40">
 
        {steps === 1 && (<QnaFirst steps={steps} setSteps={setSteps} />)}
        {steps === 3 && (<QnaLast steps={steps} setSteps={setSteps} />)}
        {steps === 4 && (<QnaResponse steps={steps} setSteps={setSteps} />)}
        {steps === 2 && (
          <>
            <div className="mainwrapper">
              <div className='form_title'>
                <div className="mt30 font20 fw600">FinnFit Quiz </div>
                <div className="font15 color495">Few questions to understand your Financial Fitness Score</div>
              </div>
              <div className="row formWpr mt25">
                <div className="col-lg-8 formouter">
                   <ul className="form_swipe_tab font12 fw600"> 
                    <li>{questionIndex}</li>
                    { /*<li className="act">1</li>
                    <li>2</li>*/ } 
                  </ul> 
                  <div className="formtitle flex_center justify_center">
                    <div>
                      <div className="font20 fw600">Know your Financial Fitness Score</div>
                      <div className="font15 color495 ">Participate in the Quiz to Win your complimentary 30 Minute Session</div>
                    </div>
                    <div className='d-none d-md-block'>
                      <input type="button" className="bluebtn font13 fw600 mr-3" onClick={prevClick} value="Prev" />
                      <input type="button" className="bluebtn font13 fw600 mr-3" onClick={handleNext} value="Next" disabled={!userSlectedAns} />
                    </div>
                  </div>
                  {/* Form */}
                  <div className="p35">
                    <form className="custome_form">
                      {questionList} 
                    </form>
                  </div>
                  {/* Form End */}
                </div>
                <div className="col-lg-4 qouteouter d-none d-md-block">
                  <div className="qoutetext font18 fw500 color212 pl45 pr45">
                    <div className="qoutesign">
                      &#8220;
                    </div>
                    Don’t worry about enclosing PAN details to finnovate. Your details will be secure and encrypted.
                  </div>
                  <div className="qouteimg"><img src={SideImg} alt='Completed'/></div>
                </div>
              </div>
              <div class="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" class="btn btn-primary" onClick={prevClick}>Prev </button>
                <button type="button" class="btn btn-primary" onClick={handleNext} disabled={!userSlectedAns} >Next </button>
              </div>
            </div>
          </>
        )}

      </div>
    </Fragment>
  )
}
