import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { apiUrl, inWords } from '../global'
import formatAmount from 'indian-currency-formatter';

export default function GoalSummary() {

    // eslint-disable-next-line
    const [goals, setGoals] = useState([]);
    // eslint-disable-next-line
    const [retirement, setRetirement] = useState([]);
    // eslint-disable-next-line
    const [summary, setSummary] = useState([]);

    const [cashflow, setCashflow] = useState([]);

    const [assumption, setAssumption] = useState([]);

    const [linked, setLinked] = useState();

    const [yearlyRequired, setYearlyRequired] = useState();


    const [thisYearNeed, setThisYearNeed] = useState();


   // const [emergencyFund, setEmergencyFund] = useState();
    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {
                setGoals(response.data.goals);
                setSummary(response.data.profgoalSummary);
                let find_retirement = response.data.goals.find(product => product.goal_type === "Retirement");
                setRetirement(find_retirement);

                setAssumption(response.data.assumption);

                setLinked(response.data.InvestmentSummary.c_amount);

                setYearlyRequired(response.data.profgoalSummary.suggested_fund/response.data.profgoalSummary.shortfall_yearly_with_increase);

                setThisYearNeed(response.data.thisyearneed);

               // setEmergencyFund(response.data.emergency_fund);
            });


        axios.get(`${apiUrl}cashflow-summary/${profile_id}`).then(
            (response, data) => {
                setCashflow(response.data.cashflow);
            });

    }, []);

    return (
        <>
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Goal Summary</span></div>

            <div className="font22 fw700 color0C4 pt30">Goal Summary</div>

            <div className="font14 fw500 color182 mt20">We have presented below a snapshot of all your major financial goals as shared by you. It is important to note that the Financial Plan below will largely revolve around helping you lay a roadmap for achieving your financial goals through various strategies as outlined therein.</div>

            <div className="font14 fw600 color626 mt50">DEFAULT GOAL</div>

            <div className="goalbox_outer mt20">
                <div className="goalbox_title">
                    <div>
                        <div className="font16 color263 fw600">{retirement.goal_name} <span className="font13 fw500 color626">- {retirement.full_name}</span></div>
                    </div>
                    <div>
                        <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">{retirement.achived_percentage}%</span></div>
                    </div>
                </div>
                <div className="goalbox_content mt20">
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Current Monthly Expense</div>
                        <div class="font24 fw500 color263">{inWords(retirement.total_abcd / 12)}</div>
                    </div>
                    <div className="goalbox_inner pr30">
                        <div className="goalstatus_outer">
                            <div className="fw500 font12 color182">{retirement.goal_inflation}% Assumed Inflation</div>
                            <div className="goalstatus_bar mt10">
                                <div className="fw500 font12 color626 mr10">{new Date().getFullYear()}</div>
                                <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                <div className="fw500 font12 color626 ml10">{retirement.achived_by}</div>
                            </div>
                        </div>
                    </div>
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Total amount required</div>
                        <div class="font24 fw500 color22a">{inWords(retirement.suggested_fund)}</div>
                    </div>
                </div>
            </div>


            <div className="font14 fw600 color626 mt50 mt40">CRUCIAL GOAL</div>

            {
                goals.map((data, index) => {
                    return (
                        <>
                            {
                                data.goal_type === "Education" && (
                                    <>
                                        <div className="goalbox_outer mt20">
                                            <div className="goalbox_title">
                                                <div>
                                                    <div className="font16 color263 fw600">{data.goal_name} <span className="font13 fw500 color626">- {data.full_name}</span></div>
                                                </div>
                                                <div>
                                                    <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">{data.achived_percentage}%</span></div>
                                                </div>
                                            </div>
                                            <div className="goalbox_content mt20">
                                                <div className="goalbox_inner">
                                                    <div class="font13 fw500 color626">Current value as of Today</div>
                                                    <div class="font24 fw500 color263">{inWords(data.total_abcd)}</div>
                                                </div>
                                                <div className="goalbox_inner pr30">
                                                    <div className="goalstatus_outer">
                                                        <div className="fw500 font12 color182">{data.goal_inflation}% Assumed Inflation</div>
                                                        <div className="goalstatus_bar mt10">
                                                            <div className="fw500 font12 color626 mr10">{new Date().getFullYear()}</div>
                                                            <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                                            <div className="fw500 font12 color626 ml10">{data.achived_by}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="goalbox_inner">
                                                    <div class="font13 fw500 color626">Total amount required</div>
                                                    <div class="font24 fw500 color22a">{inWords(data.suggested_fund)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                })
            }




            <div className="font14 fw600 color626 mt50 mt40">LUXURY GOAL</div>


            {
                goals.map((data, index) => {
                    return (
                        <>
                            {
                                (data.goal_type === "Wealth" || data.goal_type === "Marriage") && (
                                    <>

                                        <div className="goalbox_outer mt20">
                                            <div className="goalbox_title">
                                                <div>
                                                    <div className="font16 color263 fw600">{data.goal_name} <span className="font13 fw500 color626">- {data.full_name}</span></div>
                                                </div>
                                                <div>
                                                    <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">{data.achived_percentage}%</span></div>
                                                </div>
                                            </div>
                                            <div className="goalbox_content mt20">
                                                <div className="goalbox_inner">
                                                    <div class="font13 fw500 color626">Current value as of Today</div>
                                                    <div class="font24 fw500 color263">{inWords(data.total_abcd)}</div>
                                                </div>
                                                <div className="goalbox_inner pr30">
                                                    <div className="goalstatus_outer">
                                                        <div className="fw500 font12 color182">{data.goal_inflation}% Assumed Inflation</div>
                                                        <div className="goalstatus_bar mt10">
                                                            <div className="fw500 font12 color626 mr10">{new Date().getFullYear()}</div>
                                                            <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                                            <div className="fw500 font12 color626 ml10">{data.achived_by}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="goalbox_inner">
                                                    <div class="font13 fw500 color626">Total amount required</div>
                                                    <div class="font24 fw500 color22a">{inWords(data.suggested_fund)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                })
            }


            <div className="mt50">ASSUMPTIONS</div>

            <div className="assumptionbox_outer mt25">
                <ul className="assumptionbox_list font13 fw500 color182">

                   


                    <li>
                        <div>Asset</div>
                        <div>Growth</div>
                    </li>
                    
                    <li>
                        <div className="font14 fw600">Equity Investments will grow at</div>
                        <div className="font14 fw600">{assumption.equity}%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Debt Investments will grow at</div>
                        <div className="font14 fw600">{assumption.debt}%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">EPF will grow at</div>
                        <div className="font14 fw600">{assumption.epf}%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Gold Investments will grow at</div>
                        <div className="font14 fw600">{assumption.gold}%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Property value will grow at</div>
                        <div className="font14 fw600">{assumption.property}%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Rental Income from property will grow at</div>
                        <div className="font14 fw600">{assumption.rental}%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Yearly investment increment</div>
                        <div className="font14 fw600">{assumption.income_grow}%</div>
                    </li>
                </ul>
            </div>


            <div className="mt50">OTHER ASSUMPTIONS</div>

            <div className="assumptionbox_outer mt25" style={{'background-color':'#f7f9fb'}}>
                <ul className="assumptionbox_list font13 fw500 color182">

                
                    <li>
                        <div>Asset</div>
                        <div>Growth</div>
                    </li>
                    
                    <li>
                        <div className="font14 fw600">Post retirement inflation</div>
                        <div className="font14 fw600">{Math.round(assumption.post_retire_inflation)}%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Life Expectancy</div>
                        <div className="font14 fw600">{Math.round(assumption.life_expentancy)}Y</div>
                    </li>
                </ul>
            </div>

            <div className="goalsum_chart_outer mt50">
                <div className="font14 fw600 color626">GOAL SUMMARY</div>
                <div className="goalsum_chart_box mt20">
                    <div className="font16 fw600 color636">Age of Retirement : {Math.round(assumption.retire_age)}</div>
                    <div className="goalsum_chart_devider mt20" />
                    <div className="goalbox_content mt20">
                        <div className="goalbox_inner">
                            <div class="font13 fw500 color626">Total Goal Amount</div>
                            <div class="font24 fw500 color263">{inWords(summary.suggested_fund)}</div>
                        </div>
                        <div className="goalbox_inner alignCenter pr30">
                            <div class="font13 fw500 color626">Yearly required</div>
                            <div class="font24 fw500 color263">{inWords(yearlyRequired)}</div>
                        </div>
                        <div className="goalbox_inner">
                            <div class="font13 fw500 color626">Currently linked</div>
                            <div class="font24 fw500 color22a">{inWords(linked)}</div>
                        </div>
                    </div>
                    <div className="goalsum_chart_devider mt20" />
                    <div className="goalbox_content mt20">
                        <div className="goalbox_inner">
                            <div class="font13 fw500 color626">This year’s need</div>
                            <div class="font24 fw500 color263">{inWords(thisYearNeed)}</div>
                        </div>
                        <div className="goalbox_inner alignCenter pr30">
                            <div class="font13 fw500 color626">Increased yearly by</div>
                            <div class="font24 fw500 color263">{assumption.income_grow}%</div>
                        </div>
                        <div className="goalbox_inner">
                            <div class="font13 fw500 color626">ROI</div>
                            <div class="font24 fw500 color22a">{summary.pre_return*100}%</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="font18 fw600 color181 mt70">Cashflow for existing goal plans  </div>
            <div className="goalPlan_tbl mt25">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Year</th>
                                <th scope="col"><span className="dotstatus">Fresh Inv.</span></th>
                                <th scope="col"><span className="dotstatus darkyellowdot">Existing Inv.</span></th>
                                <th scope="col"><span className="dotstatus greendot">FV</span></th>
                                <th scope="col"><span className="dotstatus bluedot">Other Inc</span></th>
                                <th scope="col">Outflows</th>
                                <th scope="col">Net Amount</th>
                            </tr>
                        </thead>
                        <tbody>

                            {// eslint-disable-next-line
                                cashflow.length > 0 ?
                                    cashflow.map((item, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{item.year}</td>
                                                    <td>{formatAmount(item.fresh_investment)}</td>
                                                    <td>{formatAmount(item.actual_fund)}</td>
                                                    <td>{formatAmount(item.fv)}</td>
                                                    <td>{item.other_income}</td>
                                                    <td>{formatAmount(item.outflow)}</td>
                                                    <td className={item.nett < 0 ? 'text-danger' : ''}>{formatAmount(item.nett)}</td>
                                                </tr>
                                            </>
                                        )
                                    }) : ''}


                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}
