import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { apiUrl } from '../../global';
import formatAmount from 'indian-currency-formatter';

export default function EditEmiById({ liability, i }) {

    console.log('liability ', liability);

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    const onSubmit = data => {

        const Postdata = {
            "profile_id": data.profileId,
            "profile_details_id": data.profile_details_id,
            "co_name": data.profileId,
            "owner": data.profileId,
            "instr_name": data.instr_name,
            "type": 20,
            "sub_type": "1",
            "loan_amount": data.totalLoan * data.moneyDigits,
            "outstanding_loan": data.outstandingLoan * data.moneyDigits,
            "emi": data.emi.replace(/,/g, ""),
            "liability_id": data.liability_id,

        };


        console.log(Postdata);

        axios.post(`${apiUrl}prof-liability`, Postdata)
            .then(function (response) {
                window.location.reload(true)
            });
    }


    const [emi, setEmi] = useState(liability.emi > 0 ? formatAmount(Math.round(liability.emi)) : '0');

    const handleChange = (e) => {

        const { value } = e.target;

        if (e.target.name === 'emi') {
            setEmi(formatAmount(value.replace(/,/g, "")));
        }

    };

    return (
        <>
            <Fragment>


                <div class="card card-body">
                    <div className="form_prnt">
                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <input type="hidden" name="liability_id" {...register("liability_id")} defaultValue={liability.liability_id} />
                            <input type="hidden" name="sub_type" {...register("sub_type")} defaultValue={liability.sub_type} />
                            <input type="hidden" name="instr_name" {...register("instr_name")} defaultValue={liability.instr_name} />
                            <input type="hidden" name="type" {...register("type")} defaultValue={liability.type} />

                            <input type="hidden" name="profileId" {...register("profileId")} defaultValue={liability.profile_id} />
                            <input type="hidden" name="profile_details_id" {...register("profile_details_id")} defaultValue={liability.profile_details_id} />

                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">Total loan</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="totalLoan" {...register("totalLoan", { required: 'Required.' })} defaultValue={liability.loan_amount > 0 ? liability.loan_amount.toString().length >= 8 ? liability.loan_amount/10000000 : liability.loan_amount/100000 : ''} maxLength={3} minLength={1}/>
                                        <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")} defaultValue={liability.loan_amount.toString().length >= 8 ? 10000000 : 100000}>
                                            <option value="100000">Lakhs</option>
                                            <option value="10000000">Crore</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.totalLoan && errors.totalLoan.message}</span>
                                </div>
                            </div>

                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">EMI</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control font14 color212 fw500" name="emi" {...register("emi", { required: 'Required.' })} value={emi} maxLength={10} minLength={1} onChange={handleChange} />
                                    </div>
                                    <span className="text-danger"> {errors.emi && errors.emi.message}</span>
                                </div>
                            </div>

                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">Remaining loan</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="outstandingLoan" {...register("outstandingLoan", { required: 'Required.' })} defaultValue={liability.outstanding_loan > 0 ? liability.outstanding_loan.toString().length >= 8 ? liability.outstanding_loan/10000000 : liability.outstanding_loan/100000 : ''} maxLength={3} minLength={1}/>
                                        <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")} defaultValue={liability.outstanding_loan.toString().length >= 8 ? 10000000 : 100000}>
                                            <option value="100000">Lakhs</option>
                                            <option value="10000000">Crore</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.outstandingLoan && errors.outstandingLoan.message}</span>
                                </div>
                            </div>

                            <div className="saveBtn backsaveBtn">
                                <button type="button" class="btn btn-primary">Cancel </button>
                                <button type="submit" class="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>
                            </div>

                        </form>
                    </div>
                </div>
            </Fragment>
        </>
    )
}

