import React, { useState, useEffect } from 'react'
import { apiUrl, inWords } from '../../global';
import axios from 'axios';
import EditFamily from '../EditFamily/EditFamily';

export default function DashboardFamily() {

    // eslint-disable-next-line
    const [childs, setChilds] = useState([]);
    // eslint-disable-next-line
    const [self, setSelf] = useState([]);
    // eslint-disable-next-line
    const [spouse, setSpouse] = useState([]);

    const [lifeGoal, setLifeGoal] = useState([]);
    // eslint-disable-next-line
    const [spouselifeGoal, setSpouselifeGoal] = useState([]);

    const [healthGoal, setHealthGoal] = useState([]);

    // eslint-disable-next-line
    const [healthActive, setHealthActive] = useState(1);
    // eslint-disable-next-line
    const [termActive, setTermActive] = useState(1);
    // eslint-disable-next-line
    const [spousetermActive, setSpousetermActive] = useState(1);

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile-details/get-child-details/${profile_id}`).then(
            (response, data) => {
                setChilds(response.data);
            });

        axios.get(`${apiUrl}profile-details/get-details/${profile_id}`).then(
            (response, data) => {

                let self = response.data.find(product => product.relation === "self");

                console.log('response.data', response.data);
                setSelf(self);


                let spouse = response.data.find(product => product.relation === "spouse");

                console.log(spouse);
                setSpouse(spouse);

            });

        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {

                console.log('response.data.risk_goals', response.data.risk_goals);

                let life_goal = response.data.risk_goals.find(product => (product.goal_type === "Life Insurance" && product.relation === "self"));
                let health_goal = response.data.risk_goals.find(product => product.goal_type === "Health Insurance");

                let life_goal_spouse = response.data.risk_goals.find(product => product.plan_for === parseInt(sessionStorage.getItem('spouse_prof_det_id')));


                setLifeGoal(life_goal);

                setSpouselifeGoal(life_goal_spouse);

                setHealthGoal(health_goal);
            });


    }, []);

    const [isActive, setActive] = useState(false);
    const [profileData, setProfileData] = useState([]);
    const toggleClass = (data) => {
        setActive(true);

        setProfileData(data)
    };


    console.log('healthGoal', healthGoal)

    return (
        <>
            <div className="db_cashflow_outer pt20 pl30 pr30">
                <ul className="nav nav-pills mb-3 font12 fw500 color353" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="pills-members-tab" data-toggle="pill" href="#pills-members" role="tab" aria-controls="pills-members" aria-selected="true">Members</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-ranjan-tab" data-toggle="pill" href="#pills-ranjan" role="tab" aria-controls="pills-ranjan" aria-selected="false">{self.full_name}</a>
                    </li>

                    {
                        spouse.profile_details_id ?
                            <li className="nav-item">
                                <a className="nav-link" id="pills-spouse-tab" data-toggle="pill" href="#pills-spouse" role="tab" aria-controls="pills-spouse" aria-selected="false">{spouse.full_name}</a>
                            </li> : ''
                    }
                    {/*<li className="nav-item">
                        <a className="nav-link" id="pills-spouse-tab" data-toggle="pill" href="#pills-spouse" role="tab" aria-controls="pills-spouse" aria-selected="false">Spouse</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-kids-tab" data-toggle="pill" href="#pills-kids" role="tab" aria-controls="pills-kids" aria-selected="false">Kids</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-dependents-tab" data-toggle="pill" href="#pills-dependents" role="tab" aria-controls="pills-dependents" aria-selected="false">Dependents</a>
                    </li>

                    <li className='addFamily'><span className="color0d6 font12 fw600" onClick={() => toggleClass([])}>Add</span></li>
                    */}
                </ul>

                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-members" role="tabpanel" aria-labelledby="pills-members-tab">



                        <ul className="familylist ">

                            <li>
                                <div className="font14 fw500 color182 pointer" onClick={() => toggleClass(self)}> {self.full_name}<span className="crownblue_icon ml4" /></div>
                                <div className="familyinfo mt10">
                                    <div className="familyinfo_left">
                                        <div className="familyinfo_tab font12 fw500 color353">
                                            <span className="clock_icon mr4" /> {self.age} yrs
                                        </div>
                                        <div className="familyinfo_tab font12 fw500 color353">
                                            <span className="rupicon color777 mr4">&#8377;</span>{(self.income_self / 10000000).toFixed(2)} Cr
                                        </div>
                                        <div className="familyinfo_tab font12 fw500 color353">
                                            Income {self.income_self > 0 ? ((self.income_self / self.total) * 100).toFixed(2) : '0'}%
                                        </div>
                                    </div>
                                </div>
                            </li>

                            {
                                spouse.profile_details_id && (<li>
                                    <div className="font14 fw500 color182 mt15 pointer" onClick={() => toggleClass(spouse)}> {spouse.full_name}<span className="crown_icon ml4" /></div>
                                    <div className="familyinfo mt10">
                                        <div className="familyinfo_left">
                                            <div className="familyinfo_tab font12 fw500 color353">
                                                <span className="clock_icon mr4" /> {spouse.age} yrs
                                            </div>
                                            <div className="familyinfo_tab font12 fw500 color353">
                                                <span className="rupicon color777 mr4">&#8377;</span> {(spouse.income_spouse / 10000000).toFixed(2)} Cr
                                            </div>
                                            <div className="familyinfo_tab font12 fw500 color353">
                                                Income {spouse.income_spouse > 0 ? ((spouse.income_spouse / self.total) * 100).toFixed(2) : '0'}%
                                            </div>
                                        </div>
                                    </div>
                                </li>)
                            }


                            {
                                childs.length > 0 && (<li>
                                    <div className="font14 fw500 color182 mt15">KIDS <span className="color182 font11 fw600 ml4"></span></div>

                                    <div className="familyinfo mt10">
                                        {
                                            childs.map((data, index) => {
                                                return (
                                                    <>

                                                        <div className="familyinfo_left">
                                                            <div className="font13 fw500 color182 mb8 pointer" onClick={() => toggleClass(data)}>{data.first_name}<span className="crowngreen_icon ml4" /></div>
                                                            <div className="familyinfo_tab font12 fw500 color353">
                                                                <span className="clock_icon mr4" /> {data.age} y
                                                            </div>
                                                            <div className="familyinfo_tab font12 fw500 color353">
                                                                <span className="goal_gray mr4" />{data.goal_count}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>

                                </li>)
                            }


                        </ul>
                    </div>
                    <div className="tab-pane fade" id="pills-ranjan" role="tabpanel" aria-labelledby="pills-ranjan-tab">
                        <div className="persondtl_wpr">
                            <div className="persondtl_title">
                                <div className="font13 fw500 color353">{self.full_name}, {self.age}yrs</div>
                                <div>
                                    <span className="color0d6 font12 fw600" onClick={() => toggleClass([])}>Add</span>
                                </div>
                            </div>

                            {
                                healthGoal && (<>
                                    <div className="person_cover_wpr mt15">
                                        <div className="person_cover_outer">
                                            <div className="person_cover_left">
                                                <div className="font13 fw500 color353">Health Coverage</div>
                                                <div className="font22 fw500 color162">{healthGoal.existing_cover > 0 ? inWords(healthGoal.existing_cover) : 0}</div>
                                                <div className="coverprogress_bar">
                                                    <progress value={healthGoal.bar_perc > 100 ? 100 : healthGoal.bar_perc} max="100" />
                                                </div>
                                                <div className="mt5 font12 fw700 color5c6">{healthGoal.bar_perc > 100 ? 100 : healthGoal.bar_perc}% protected</div>
                                            </div>
                                            <div className="person_cover_right pl20">
                                                <div className="suggestbox">
                                                    <div className="font16 fw500 color495">{healthActive === 1 ? (healthGoal.max_suggested > 0 ? inWords(healthGoal.max_suggested) : 0) : (healthGoal.min_suggested > 0 ? inWords(healthGoal.min_suggested) : 0)}</div>
                                                    <div className="font12 fw500 color6d7">
                                                        {healthActive === 1 ? 'Suggested Max amount' : 'Suggested Min amount'}
                                                    </div>
                                                </div>
                                                <div className="updown_arrow pl12" >
                                                    <div className="before" onClick={() => setHealthActive(1)} />
                                                    <div className="after" onClick={() => setHealthActive(2)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            }

                            {
                                lifeGoal && (<>
                                    <div className="person_cover_wpr mt15">
                                        <div className="person_cover_outer ">
                                            <div className="person_cover_left">
                                                <div className="font13 fw500 color353">Life Coverage</div>
                                                <div className="font22 fw500 color162">{lifeGoal.existing_cover > 0 ? inWords(lifeGoal.existing_cover) : 0}</div>
                                                <div className="coverprogress_bar">
                                                    <progress value={lifeGoal.bar_perc > 100 ? 100 : lifeGoal.bar_perc} max="100" />
                                                </div>
                                                <div className="mt5 font12 fw700 color5c6">{lifeGoal.bar_perc > 100 ? 100 : lifeGoal.bar_perc}% protected</div>
                                            </div>
                                            <div className="person_cover_right pl20">
                                                <div className="suggestbox">
                                                    <div className="font16 fw500 color495">{termActive === 1 ? (lifeGoal.suggestedoption1 > 0 ? inWords(lifeGoal.suggestedoption1) : 0) : (lifeGoal.suggestedoption2 > 0 ? inWords(lifeGoal.suggestedoption2) : 0)}</div>
                                                    <div className="font12 fw500 color6d7">
                                                        {termActive === 1 ? 'Suggested based on expense' : 'Suggested based on income'}
                                                    </div>
                                                </div>
                                                <div className="updown_arrow pl12" >
                                                    <div className="before" onClick={() => setTermActive(1)} />
                                                    <div className="after" onClick={() => setTermActive(2)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            }


                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-spouse" role="tabpanel" aria-labelledby="pills-spouse-tab">
                        {

                            spouselifeGoal && (<>

                                <div className="persondtl_title">
                                    <div className="font13 fw500 color353">{spouse.full_name}, {spouse.age}yrs</div>
                                    <div>
                                        <a href="/#" className="color0d6 font12 fw600">Add</a>
                                    </div>
                                </div>
                                <div className="person_cover_wpr mt15">


                                    <div className="person_cover_outer ">
                                        <div className="person_cover_left">
                                            <div className="font13 fw500 color353">Life Coverage</div>
                                            <div className="font22 fw500 color162">{spouselifeGoal.existing_cover > 0 ? inWords(spouselifeGoal.existing_cover) : 0}</div>
                                            <div className="coverprogress_bar">
                                                <progress value={spouselifeGoal.bar_perc > 100 ? 100 : spouselifeGoal.bar_perc} max="100" />
                                            </div>
                                            <div className="mt5 font12 fw700 color5c6">{spouselifeGoal.bar_perc > 100 ? 100 : spouselifeGoal.bar_perc}% protected</div>
                                        </div>
                                        <div className="person_cover_right pl20">
                                            <div className="suggestbox">
                                                <div className="font16 fw500 color495">{spousetermActive === 1 ? (spouselifeGoal.suggestedoption1 > 0 ? inWords(spouselifeGoal.suggestedoption1) : 0) : (spouselifeGoal.suggestedoption2 > 0 ? inWords(spouselifeGoal.suggestedoption2) : 0)}</div>
                                                <div className="font12 fw500 color6d7">
                                                    {spousetermActive === 1 ? 'Suggested based on expense' : 'Suggested based on income'}
                                                </div>
                                            </div>
                                            <div className="updown_arrow pl12" >
                                                <div className="before" onClick={() => setSpousetermActive(1)} />
                                                <div className="after" onClick={() => setSpousetermActive(2)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        }
                    </div>
                    <div className="tab-pane fade" id="pills-kids" role="tabpanel" aria-labelledby="pills-kids-tab">
                        Suggested allocation
                    </div>
                    <div className="tab-pane fade" id="pills-dependents" role="tabpanel" aria-labelledby="pills-dependents-tab">
                        Suggested allocation
                    </div>

                </div>
            </div>

            {
                isActive === true && (<EditFamily toggleClass={toggleClass} setActive={setActive} profileData={profileData} />)
            }
        </>
    )
}
