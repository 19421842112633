import React, { useState, useEffect } from 'react'
import { apiUrl, inWords } from '../global';
import axios from 'axios';

export default function EmergencyFund() {


  const [emergencyFund, setEmergencyFund] = useState("");
  const [requiredFund, setRequiredFund] = useState("");

  const getAssetsData = (property = "1", primary_flag = "N") => {
    const profile_id = sessionStorage.getItem('profile_id');
    axios.post(`${apiUrl}investment/recommended-assets`, { 'profile_id': profile_id, 'radio_type': 10, 'primary_flag': primary_flag }).then(
      (response, data) => {

        setEmergencyFund(response.data.grand_total.emergency_fund);
        setRequiredFund(response.data.grand_total.required_emergency_fund);
      });
  }

  useEffect(() => {


    getAssetsData();


  }, []);

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Emergency Fund</span></div>

      <div className="font22 fw700 color0C4 pt30">Emergency Funds</div>

      <div className="font14 fw500 color182 mt20">It is important to maintain an emergency fund to meet the expenses arising out of any unforeseen events like unexpected medical expenses . Emergency funds should be invested in short-term investments such as liquid funds, Bank Flexi- Deposits or a combination of such ultra liquid instruments.</div>

      <div className="font14 fw500 color182 mt50">Note : Current mapped amount for Emergency fund is {emergencyFund ? inWords(emergencyFund) : '0'}</div>

      <div className="suggestBox mt20">
        <div className="suggestBox_left">
          <div className="font16 fw600 color0f7">Suggested Emergency fund</div>
          <div className="font14 fw400 color505">6 months of expenses, in ultra liquid instruments.</div>
        </div>
        <div className="suggestBox_right">
          <div className="font24 fw500 color182">{inWords(requiredFund)}</div>
        </div>
      </div>

    </>
  )
}
