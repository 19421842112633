import React, { Fragment, useState } from 'react'
import DashboardTargets from './tabs/DashboardTargets'
import DashboardGoals from './tabs/DashboardGoals'
import DashboardCashflow from './tabs/DashboardCashflow'
import DashboardInvestment from './tabs/DashboardInvestment'
import DashboardFamily from './tabs/DashboardFamily'
import RiskProfile from '../RiskProfile'
import { Modal, ModalBody } from 'react-bootstrap';
import GenerateRiskProfile from './GenerateRiskProfile'
import ViewRiskProfile from './ViewRiskProfile'
import EditAssumptions from './EditAssumptions'

export default function DashboardLeft({ familyCount, assumptions, riskDescription, name, roi, roiOption, riskAssetMax }) {

    console.log(riskAssetMax);
    const [activeTab, setActiveTab] = useState('plan');

    const checkTab = (tab) => {
        setActiveTab(tab);
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);

    const [isActive, setActive] = useState(false);
    const [action, setAction] = useState();

    const toggleClass = (status, action) => {
        setActive(status);
        setAction(action);
    };


    console.log('isActive '+isActive+ ' action'+action);

    return (
        <Fragment>
            <div className="dashboard_left pb20">
                <div className="dashboard_left_title font16 fw500 color182">{name} financial input</div>
                <div className="categoryTabs_outer mt20">
                    <li onClick={() => toggleClass(true,  riskDescription !== 'Set' ? 'viewRisk' : 'geneRisk')}>
                        <div className="categoryTab font12 fw500 color353"><span className="risk mr5" /> {riskDescription} Risk ROI<span className="font12 fw600 color06d ml5">{roi}%</span></div>
                    </li>

                    <li onClick={() => toggleClass(true, 'assumption')}>
                        <div className="categoryTab font12 fw500 color353"><span className="family_icon mr5" /> Assumptions </div>
                    </li>
                    <li onClick={() => checkTab('family')}>
                        <div className="categoryTab font12 fw500 color353"><span className="family_icon mr5" /> Family of {familyCount}</div>
                    </li>
                </div>


                <div className="db_maintab_outer mt25">
                    <div className="db_maintab_wpr">
                        <nav className="font13">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a className={activeTab === "plan" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-plan-tab" data-toggle="tab" href="#nav-plan" role="tab" aria-controls="nav-plan" aria-selected="true" onClick={() => checkTab('plan')}>Plan</a>

                                <a className={activeTab === "goals" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-goal-tab" data-toggle="tab" href="#nav-goal" role="tab" aria-controls="nav-goal" aria-selected="false" onClick={() => checkTab('goals')}>Goals</a>

                                <a className={activeTab === "cashflow" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-cashflow-tab" data-toggle="tab" href="#nav-cashflow" role="tab" aria-controls="nav-cashflow" aria-selected="false" onClick={() => checkTab('cashflow')}>Cashflow</a>

                                <a className={activeTab === "investment" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-investments-tab" data-toggle="tab" href="#nav-investments" role="tab" aria-controls="nav-investments" aria-selected="false" onClick={() => checkTab('investment')}>Investments</a>

                                <a className={activeTab === "family" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-family-tab" data-toggle="tab" href="#nav-family" role="tab" aria-controls="nav-family" aria-selected="false" onClick={() => checkTab('family')}>Family</a>
                            </div>
                        </nav>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div className={activeTab === "plan" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-plan" role="tabpanel" aria-labelledby="nav-plan-tab">
                            {activeTab === 'plan' && <DashboardTargets />}
                        </div>
                        <div className={activeTab === "goals" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-goal" role="tabpanel" aria-labelledby="nav-goal-tab">
                            {activeTab === 'goals' && <DashboardGoals />}
                        </div>
                        <div className={activeTab === "cashflow" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-cashflow" role="tabpanel" aria-labelledby="nav-cashflow-tab">
                            {activeTab === 'cashflow' && <DashboardCashflow />}
                        </div>
                        <div className={activeTab === "investment" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-investments" role="tabpanel" aria-labelledby="nav-investments-tab">
                            {activeTab === 'investment' && <DashboardInvestment />}
                        </div>
                        <div className={activeTab === "family" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-family" role="tabpanel" aria-labelledby="nav-family-tab">
                            {activeTab === 'family' && <DashboardFamily />}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <ModalBody>
                    <div className="p25">
                        <h1 className='font20 fw600'>Set your Risk Profile</h1>
                        <div className="font13 color495 ">These are not even goals, but ust haves to financially safe life</div>
                        <div className="mt25">
                            <RiskProfile />
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {
                (isActive === true && action === 'viewRisk') && (<ViewRiskProfile toggleClass={toggleClass} setActive={setActive} setAction={setAction} riskDescription={riskDescription} />)
            }

            {
                (isActive === true && action === 'geneRisk') && (<GenerateRiskProfile toggleClass={toggleClass} setActive={setActive} setAction={setAction} riskDescription={riskDescription} />)
            }

            {
                (isActive === true && action === 'assumption') && (<EditAssumptions toggleClass={toggleClass} setActive={setActive} setAction={setAction} roiOption={roiOption} riskAssetMax={riskAssetMax}/>)
            }

        </Fragment>
    )
}
