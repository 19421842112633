import React, { useEffect, Fragment, useState } from 'react'
import { apiUrl } from '../global';
import axios from 'axios';
import { useForm } from 'react-hook-form'
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./tabs/slider.css";

export default function EditAssumptions({ toggleClass, setActive, roiOption, riskAssetMax }) {

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const { register, handleSubmit, formState: { errors } } = useForm();





    const onSubmit = data => {


        const profile_id = sessionStorage.getItem('profile_id');


        const postData = { profile_id: profile_id, assumption: { equity: equityReturn, debt: debtReturn, gold: goldReturn, property: propertyReturn } };

        axios.post(`${apiUrl}profile-assumptions/update_assumption`, postData)
            .then(function (response) {


                if (roiOption === "1") {

                    axios.post(`${apiUrl}summary/calculate-roi`, { profile_id: profile_id, asset_alloc: riskAssetMax }).then(
                        (response, data) => {
                            const summaryUpdate = {
                                profile_id: profile_id,
                                roiChange: 1,
                                roi: response.data.roi * 100,
                                asset_alloc: (response.data.asset_alloc * 100)
                            }

                            axios.post(`${apiUrl}goals/reset`, { profile_id: profile_id })
                                .then(function (response) {


                                    axios.post(`${apiUrl}profile/summary-update`, summaryUpdate).then(
                                        (response, data) => {
                                            window.location.reload();
                                        }
                                    );


                                })

                        });

                } else {



                    const gpostData = { profile_id: profile_id };
                    axios.post(`${apiUrl}goals/reset`, gpostData)
                        .then(function (response) {

                            axios.post(`${apiUrl}profile/summary-update`, { profile_id: profile_id }).then(
                                (response, data) => {
                                    window.location.reload();
                                }
                            );


                        })


                }




            })

    }

    const [equityReturn, setEquityReturn] = useState();
    const [debtReturn, setDebtReturn] = useState();
    const [goldReturn, setGoldReturn] = useState();
    const [propertyReturn, setPropertyReturn] = useState();

    const equityPerc = (e) => {
        setEquityReturn(e[1]);
    }

    const debtPerc = (e) => {
        setDebtReturn(e[1]);
    }

    const goldPerc = (e) => {
        setGoldReturn(e[1]);
    }

    const propertyPerc = (e) => {
        setPropertyReturn(e[1]);
    }

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {



                setEquityReturn(parseInt(response.data.assumption.equity));
                setDebtReturn(parseInt(response.data.assumption.debt));
                setGoldReturn(parseInt(response.data.assumption.gold));
                setPropertyReturn(parseInt(response.data.assumption.property));


            });

    }, []);


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" class="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Edit Assumption
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Edit Assumption</div>
                        <div className="font14 fw500 color485">plan in your financial journey</div>
                    </div>

                    <div className="form_prnt p20">
                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="rangesliderbox">
                                <div className="rangesliderbox_title">
                                    <div>
                                        <div className="font12 fw500 color8c9">Equity Investments will grow at</div>
                                    </div>
                                    <div class="tragetvalue font13 fw700">{equityReturn}</div>
                                </div>
                                <div className="rangebox_bar mt12">
                                    <div class="form-group">


                                        <RangeSlider id="range-slider-grey"
                                            className="single-thumb"
                                            value={[0, equityReturn]}
                                            thumbsDisabled={[true, false]}
                                            rangeSlideDisabled={false}
                                            step={1}
                                            min={5}
                                            max={20}
                                            onInput={e => equityPerc(e)}
                                        />

                                    </div>
                                </div>
                                <div className="flex_center justify_center mt8">
                                    <div className="font12 fw500 color9ba">5</div>
                                    <div className="font12 fw500 color9ba">20</div>
                                </div>
                                <input type="hidden" className="font14 fw500 ml15 mr15" name='equityReturn' value={equityReturn} {...register("equityReturn")} />
                            </div>


                            <div className="devider_line mt25 mb25" />

                            <div className="rangesliderbox">
                                <div className="rangesliderbox_title">
                                    <div>
                                        <div className="font12 fw500 color8c9">Debt Investments will grow at</div>
                                    </div>
                                    <div class="tragetvalue font13 fw700">{debtReturn}</div>
                                </div>
                                <div className="rangebox_bar mt12">
                                    <div class="form-group">

                                        <RangeSlider id="range-slider-grey"
                                            className="single-thumb"
                                            value={[0, debtReturn]}
                                            thumbsDisabled={[true, false]}
                                            rangeSlideDisabled={false}
                                            step={1}
                                            min={5}
                                            max={20}
                                            onInput={e => debtPerc(e)}
                                        />

                                    </div>
                                </div>
                                <div className="flex_center justify_center mt8">
                                    <div className="font12 fw500 color9ba">5</div>
                                    <div className="font12 fw500 color9ba">20</div>
                                </div>
                                <input type="hidden" className="font14 fw500 ml15 mr15" name='debtReturn' value={debtReturn} {...register("debtReturn")} />
                            </div>


                            <div className="devider_line mt25 mb25" />

                            <div className="rangesliderbox">
                                <div className="rangesliderbox_title">
                                    <div>
                                        <div className="font12 fw500 color8c9">Gold Investments will grow at</div>
                                    </div>
                                    <div class="tragetvalue font13 fw700">{goldReturn}</div>
                                </div>
                                <div className="rangebox_bar mt12">
                                    <div class="form-group">



                                        <RangeSlider id="range-slider-grey"
                                            className="single-thumb"
                                            value={[0, goldReturn]}
                                            thumbsDisabled={[true, false]}
                                            rangeSlideDisabled={false}
                                            step={1}
                                            min={5}
                                            max={20}
                                            onInput={e => goldPerc(e)}
                                        />


                                    </div>
                                </div>
                                <div className="flex_center justify_center mt8">
                                    <div className="font12 fw500 color9ba">5</div>
                                    <div className="font12 fw500 color9ba">20</div>
                                </div>
                                <input type="hidden" className="font14 fw500 ml15 mr15" name='goldReturn' value={goldReturn} {...register("goldReturn")} />
                            </div>


                            <div className="devider_line mt25 mb25" />

                            <div className="rangesliderbox">
                                <div className="rangesliderbox_title">
                                    <div>
                                        <div className="font12 fw500 color8c9">Property value will grow at</div>
                                    </div>
                                    <div class="tragetvalue font13 fw700">{propertyReturn}</div>
                                </div>
                                <div className="rangebox_bar mt12">
                                    <div class="form-group">





                                        <RangeSlider id="range-slider-grey"
                                            className="single-thumb"
                                            value={[0, propertyReturn]}
                                            thumbsDisabled={[true, false]}
                                            rangeSlideDisabled={false}
                                            step={1}
                                            min={5}
                                            max={20}
                                            onInput={e => propertyPerc(e)}
                                        />


                                    </div>
                                </div>
                                <div className="flex_center justify_center mt8">
                                    <div className="font12 fw500 color9ba">5</div>
                                    <div className="font12 fw500 color9ba">20</div>
                                </div>
                                <input type="hidden" className="font14 fw500 ml15 mr15" name='propertyReturn' value={propertyReturn} {...register("propertyReturn")} />
                            </div>

                            <div className="saveBtn backsaveBtn">
                                <button type="button" class="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" class="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}
