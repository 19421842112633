import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { apiUrl, inWords } from '../global'
import formatAmount from 'indian-currency-formatter';

export default function LoanManagement() {

  // eslint-disable-next-line
  const [loans, setLoans] = useState([]);

  //const [liabilities, setLiabilities] = useState();
  // const [emergencyFund, setEmergencyFund] = useState();


  const [totalLoan, setTotalLoan] = useState();
  const [totalEmi, setTotalEmi] = useState();
  const [totalBalance, setTotalBalance] = useState();
  const [totalIncome, setTotalIncome] = useState(null);

  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');
    axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
      (response, data) => {
        setLoans(response.data.liability);
        //setLiabilities(response.data.liability_sum);
        
setTotalIncome(response.data.incomeSum.incomeSum);
        const loanSum = response.data.liability.reduce((prev, curr, index, array) => prev + curr.loan_amount, 0)

        const emiSum = response.data.liability.reduce((prev, curr, index, array) => prev + curr.emi, 0)

        const balanceSum = response.data.liability.reduce((prev, curr, index, array) => prev + curr.outstanding_loan, 0)

        setTotalLoan(loanSum);
        setTotalEmi(emiSum * 12);
        setTotalBalance(balanceSum);

      });


  }, []);

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Loan Management</span></div>

      <div className="font22 fw700 color0C4 pt30">Loan Management</div>

      <div className="font14 fw500 color182 mt20">It is important to maintain an emergency fund to meet the expenses arising out of any unforeseen events like unexpected medical expenses . Emergency funds should be invested in short-term investments such as liquid funds, Bank Flexi- Deposits or a combination of such ultra liquid instruments</div>

      <div className="loanBox_outer mt25 p25">
        <div className="loandtl">
          <div className="font13 fw500 color6d7">Toal Loan Amount</div>
          <div className="font24 fw500 color182">{totalLoan ? inWords(totalLoan) : '00'}</div>
        </div>
        <div className="loandtl">
          <div className="font13 fw500 color6d7">Toal EMI Yearly</div>
          <div className="font24 fw500 color182">{totalEmi ? inWords(totalEmi) : '00'}</div>
        </div>
        <div className="loandtl">
          <div className="font13 fw500 color6d7">Toal Remaining Amount</div>
          <div className="font24 fw500 color182">{totalBalance ? inWords(totalBalance) : '00'}</div>
        </div>
      </div>

      {
        loans.length > 0 && (<div className="font14 fw500 color182 mt30">Loan Category breakup</div>)
      }

      

      {
        loans.map((item, index) => {
          return (
            <>
              <div className="loanBox_outer mt20 p25">
                <div className="loandtl">
                  <div className="font16 fw600 color263">{item.instr_name}</div>
                  <div className="font11 fw500 color7b8">-</div>
                </div>
                <div className="loandtl">
                  <div className="font12 fw500 color6d7">Loan Amount</div>
                  <div className="font16 fw500 color162">{inWords(item.loan_amount)}</div>
                </div>
                <div className="loandtl">
                  <div className="font12 fw500 color6d7">EMI’ Yearly</div>
                  <div className="font16 fw500 color162">{item.emi !== null ? formatAmount(item.emi*12) : '0'}</div>
                </div>
                <div className="loandtl">
                  <div className="font12 fw500 color6d7">Remaining Amount</div>
                  <div className="font16 fw500 color162">{inWords(item.outstanding_loan)}</div>
                </div>
              </div>
            </>
          );
        })
      }


      {
        loans.length > 0 && (

          <>
            <div className="loaninfo font13 mt60">
              Your Total Yearly EMIs are <b>{totalEmi ? formatAmount(totalEmi) : '00'}</b>
            </div>
            <div className="loaninfo font13 mt8">
              Your EMIs are <b>{Math.round(((totalEmi/totalIncome) * 100))}% </b>  of your Income.
            </div>
            <div className="loaninfo font13 mt8">
              A % higher than <b>60%</b> suggests that you would find it difficult to get any further financing.
            </div>
          </>
        )
      }




    </>
  )
}
