import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { apiUrl, inWords } from '../../global';
import EditRetirement from '../EditGoals/EditRetirement';
import EditMarriage from '../EditGoals/EditMarriage';
import EditWealth from '../EditGoals/EditWealth';
import EditEducation from '../EditGoals/EditEducation';
import EditVacation from '../EditGoals/EditVacation';

import AddRetirement from '../AddGoals/AddRetirement';
import AddMarriage from '../AddGoals/AddMarriage';
import AddWealth from '../AddGoals/AddWealth';
import AddEducation from '../AddGoals/AddEducation';
import AddVacation from '../AddGoals/AddVacation';
import ViewCashflow from '../EditGoals/ViewCashflow';

import { Modal, ModalBody } from 'react-bootstrap';

export default function DashboardGoals() {

    // eslint-disable-next-line
    const [goals, setGoals] = useState([]);
    // eslint-disable-next-line
    const [retirement, setRetirement] = useState([]);
    // eslint-disable-next-line
    const [goalType, setGoalType] = useState();
    // eslint-disable-next-line
    const [goalId, setGoalId] = useState([]);
    const EditGoal = (type, goal_id) => {

        setGoalType(type);
        setGoalId(goal_id);

    }

    const [total, setTotal] = useState();

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {
                setGoals(response.data.goals);

                let find_retirement = response.data.goals.find(product => product.goal_type === "Retirement");
                setRetirement(find_retirement);
                sessionStorage.setItem("retire_expense", parseInt(find_retirement.total_abcd)/12);
                setTotal(response.data.goal_count);
            });
    }, []);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // eslint-disable-next-line
    const [goalslist, setGoalslist] = useState([
        /*{
            "id": 1,
            "name": "Retirement",
            "type": "Retirement",
            "icon": "mutualfunds_tab",
            checked: false
        },*/
        {
            "id": 2,
            "name": "Dream Car",
            "type": "Wealth",
            "icon": "pig_tab",
            checked: true
        },
        {
            "id": 3,
            "name": "Vacation",
            "type": "Vacation",
            "icon": "chartbar_tab",
            checked: true
        },
        {
            "id": 4,
            "name": "Big Wedding",
            "type": "Marriage",
            "icon": "gold_tab",
            checked: true
        },
        {
            "id": 5,
            "name": "Dream Bike",
            "type": "Wealth",
            "icon": "office_tab",
            checked: true
        },
        {
            "id": 6,
            "name": "Education",
            "type": "Education",
            "icon": "coins_tab",
            checked: true
        },
        {
            "id": 7,
            "name": "Dream Home",
            "type": "Wealth",
            "icon": "moneytime_tab",
            checked: true
        },
        {
            "id": 8,
            "name": "Add Own goal",
            "type": "Wealth",
            "icon": "plant_tab",
            checked: false
        }
    ]);

    const [addGoal, setAddGoal] = useState();
    const [aspire, setAspire] = useState(null);
    const [goal, setGoal] = useState();
    const [isActive, setActive] = useState(false);
    const [cashflow, setCashflow] = useState([]);
    const [goalName, setGoalName] = useState();

    const toggleClass = (type, goal_id, goalName) => {

        setActive(true);
        setGoal(type);
        setGoalId(goal_id);
        setGoalName(goalName);

        const profile_id = sessionStorage.getItem('profile_id');
        if (profile_id !== null) {

            if (type === 'Education') {
                axios.post(`${apiUrl}goal-education-cashflow`, { "profile_id": profile_id, "goal_id": goal_id }).then(
                    (response, data) => {

                        setCashflow(response.data.cashflow);

                    });
            } else {
                axios.post(`${apiUrl}goal-cashflow`, { "profile_id": profile_id, "goal_id": goal_id }).then(
                    (response, data) => {



                        setCashflow(response.data.cashflow);

                    });
            }

        }
    };



    return (
        <>

           
            

            <Modal show={show} onHide={handleClose}>
                <ModalBody>
                    <div className="p25">
                        <h1 className='font20 fw600'>Add Goal</h1>
                        <div className="font13 color495 ">These are not even goals, but ust haves to financially safe life</div>
                        <div className="add_goal_list mt25">
                            <ul>


                                {goalslist.map((data, key) => {
                                    return (
                                        <li className={data.icon} key={key}>
                                            <label>
                                                <input type="checkbox" value={data.aspire} onClick={() => { setAddGoal(data.type); setAspire(data.name); handleClose() }} />
                                                <span className="greentick" />
                                                <div className="invest_outer">
                                                    <div className="tabsIcon" />
                                                    <div className="invest_list_text mt15">{data.name}</div>
                                                </div>
                                            </label>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {
                addGoal === "Retirement" && (<AddRetirement setAddGoal={setAddGoal} aspire={aspire} />)
            }
            {
                addGoal === "Marriage" && (<AddMarriage setAddGoal={setAddGoal} aspire={aspire} />)
            }
            {
                addGoal === "Wealth" && (<AddWealth setAddGoal={setAddGoal} aspire={aspire} />)
            }
            {
                addGoal === "Education" && (<AddEducation setAddGoal={setAddGoal} aspire={aspire} />)
            }
            {
                addGoal === "Vacation" && (<AddVacation setAddGoal={setAddGoal} aspire={aspire} />)
            }


            <div className="goalsbanner ">
                <div onClick={() => EditGoal(retirement.goal_type, retirement.goal_id)}>
                    <div className="font13 fw500 color182">{retirement.full_name} Retirement Corpus</div>
                    <div className="font11 fw500 color0d6">{retirement.achived_by}</div>
                </div>
                <div onClick={() => toggleClass(retirement.goal_type, retirement.goal_id, 'Retirement')}>
                    <div className="font18 fw500 color182">{retirement.target_amt}</div>
                    <img src='/images/retire-icon.png' alt  ='retirement' />
                </div>
            </div>

            <div className="flex_center justify_center pl30 pr30 pt20 pb20">
                <div className="font13 fw500 color182">{total} Goals in the financial journey</div>
                <div className="addgoal_btn">
                    <button type="button" className="btn btn-light color182 font12 fw500" onClick={handleShow}><span className="goal_blueclr mr5" /> Add Goal</button>
                </div>
            </div>

            <div className="goallist_outer">
                <div className="goallist_title color8c9 font11 fw600">CRUCIAL GOALS</div>
                <ul className="goallist">

                    {
                        goals.map((data, index) => {
                            return (
                                <>
                                    {
                                        data.goal_type === "Education" && (
                                            <>
                                                <li>
                                                    <div className="goallist_left">
                                                        <div className="font11 fw500 color0d6">{data.achived_by}</div>
                                                        <div className="goallist_icon" ><img src={'/images/goals/' + data.goal_name.replace(/ /g, '') + '.png'} alt='goal' /></div>
                                                        <div className="font13 fw500 color182">{data.full_name} {data.aspire ? data.aspire : data.goal_name}</div>
                                                        <div className="goaledit_icon ml4" onClick={() => EditGoal(data.goal_type, data.goal_id)} />
                                                    </div>
                                                    <div className="rangebox ml12" onClick={() => toggleClass(data.goal_type, data.goal_id, data.goal_name)}>
                                                        <div className="goalprogress">
                                                            <progress value={data.achived_percentage} max="100" title={data.achived_percentage}></progress>
                                                        </div>
                                                        <div className="font13 fw500 color182 goalprogressNumber">{inWords(data.suggested_fund).replace(/ /g, '')}</div>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    }
                                </>
                            )
                        })
                    }

                </ul>
            </div>


            <div className="goallist_outer mt20">
                <div className="goallist_title color8c9 font11 fw600">LUXURY GOALS</div>
                <ul className="goallist">

                    {
                        goals.map((data, index) => {
                            return (
                                <>
                                    {
                                        (data.goal_type === "Wealth" || data.goal_type === "Marriage" || data.goal_type === "Vacation") && (
                                            <>
                                                <li>
                                                    <div className="goallist_left">
                                                        <div className="font11 fw500 color0d6">{data.achived_by}</div>
                                                        <div className="goallist_icon" ><img src={'/images/goals/' + data.goal_name.replace(/ /g, '') + '.png'} alt='goal' /></div>
                                                        <div className="font13 fw500 color182">{data.full_name} {data.aspire ? data.aspire : data.goal_name}</div>
                                                        <div className="goaledit_icon ml4" onClick={() => EditGoal(data.goal_type, data.goal_id)} />
                                                    </div>
                                                    <div className="rangebox ml12" onClick={() => toggleClass(data.goal_type === "Vacation" ? "Education" : data.goal_type, data.goal_id, data.goal_name)}>
                                                        <div className="goalprogress">
                                                            <progress value={data.achived_percentage} max="100" title={data.achived_percentage}></progress>
                                                        </div>
                                                        <div className="font13 fw500 color182 goalprogressNumber">{inWords(data.suggested_fund).replace(/ /g, '')}</div>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    }
                                </>
                            )
                        })
                    }



                </ul>
            </div>


            {
                goalType === "Retirement" && (<EditRetirement setGoalType={setGoalType} goalId={goalId} />)
            }
            {
                goalType === "Marriage" && (<EditMarriage setGoalType={setGoalType} goalId={goalId} />)
            }
            {
                goalType === "Wealth" && (<EditWealth setGoalType={setGoalType} goalId={goalId} />)
            }
            {
                goalType === "Education" && (<EditEducation setGoalType={setGoalType} goalId={goalId} />)
            }
            {
                goalType === "Vacation" && (<EditVacation setGoalType={setGoalType} goalId={goalId} />)
            }

            {
                isActive === true && (<ViewCashflow toggleClass={toggleClass} goal={goal} goalName={goalName} setActive={setActive} cashflow={cashflow} />)
            }
        </>
    )
}
