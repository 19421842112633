import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { apiUrl, inWords } from '../global';

export default function NetworthAnalysis() {


  const [liabilities, setLiabilities] = useState();
  const [networth, setNetworth] = useState();

  const [riskDesc, setRiskDesc] = useState();
  const [riskQna, setRiskQna] = useState([]);
  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');
    axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
      (response, data) => {

        setLiabilities(response.data.liability_sum)
        setNetworth(response.data.networth);

        setRiskDesc(response.data.risk_profile.description)

        const quest = response.data.risk_profile.q_n_a ? JSON.parse(response.data.risk_profile.q_n_a) : '';
        setRiskQna(quest ? quest.question : []);
      });
  }, []);

  console.log(riskQna);
  // eslint-disable-next-line
  const [equity, setEquity] = useState();
  const [debt, setDebt] = useState();
  const [other, setOther] = useState();

  const [total, setTotal] = useState();

  useEffect(() => {

    const profile_id = sessionStorage.getItem('profile_id');
    axios.get(`${apiUrl}investment/group-dashboard/${profile_id}`).then(
      (response, data) => {


        let find_equity = response.data.sum.find(product => product.name === "Equity");
        setEquity(find_equity ? find_equity.subTotal : '00');

        let find_debt = response.data.sum.find(product => product.name === "Debt");
        setDebt(find_debt ? find_debt.subTotal : '00');

        let find_others = response.data.sum.find(product => product.name === "Property");
        setOther(find_others ? find_others.subTotal : '00');

        // setTotal((find_equity.subTotal + find_debt.subTotal + find_others.subTotal));
      });

  }, []);

  useEffect(() => {

    setTotal((parseInt(equity) + parseInt(debt) + parseInt(other)));

  }, [equity, debt, other]);

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Networth Analysis</span></div>

      <div className="font22 fw700 color0C4 pt30">Networth Analysis</div>

      <div className="totalSaving_box mt15">
        <div>
          <div class="font12 fw600 color6d7">TOTAL ASSETS</div>
          <div class="font24 fw500 color263 mt12">{inWords(total)}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">-</div>
        </div>
        <div>
          <div class="font12 fw600 color6d7">TOTAL LIABILITIES </div>
          <div class="font24 fw500 color263 mt12">{liabilities}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">=</div>
        </div>
        <div>
          <div class="font12 fw600 color6d7">TOTAL NETWORTH</div>
          <div class="font24 fw500 color263 mt12">{networth}</div>
        </div>
      </div>
      <div className="font12 fw500 color78B mt15">For details of your existing liabilities , please see the Loans Management section.</div>

      <div className="lineDevider mt40 mb40" />

      <div className="font22 fw700 color0C4">Risk Profile</div>
      <div className="riskbar_outer mt50">
        <div className="riskbar_sctn">
          {
            riskDesc === "Very Caution" && (<div class="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#22AF4A' }}><img src="images/icons/tooltip.png" alt='Very Caution' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Very Caution</div>
        </div>
        <div className="riskbar_sctn">
          {
            riskDesc === "Caution" && (<div class="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#3CC97E' }}><img src="images/icons/tooltip.png" alt='Caution' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Caution</div>
        </div>
        <div className="riskbar_sctn">
          {
            riskDesc === "Moderate" && (<div class="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#FCD64F' }}><img src="images/icons/tooltip.png" alt='Moderate' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Moderate</div>
        </div>
        <div className="riskbar_sctn">
          {
            riskDesc === "Aggressive" && (<div class="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#FD9D4F' }}><img src="images/icons/tooltip.png" alt='Aggressive' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Aggressive</div>
        </div>
        <div className="riskbar_sctn">
          {
            riskDesc === "Very Aggressive" && (<div class="font13 fw500 color485" style={{ 'margin-top': '-29px', 'color': '#FF5942' }}><img src="images/icons/tooltip.png" alt='Very Aggressive' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Very Aggressive</div>
        </div>
      </div>


      <div className="lineDevider mt40 mb40" />

      <div className="font22 fw700 color0C4">Risk Profile QNA</div>

      {riskQna.map((item, index) => {
        return (
          <>

            <div key={index}>
              <label className="font16 fw600 color6d7 mt15">{item.label}</label>
              <div className="row">
                {item.answers.map((company, index) => {
                  return (
                    <>
                      <div className="col-12 col-sm-12">
                        <div className="radio_btn">
                          <label>
                            <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} checked={company.ans_id === item.ans_value ? true : false} className='resetofgoal_box mr8' />
                            <span className='font13 fw500 color485'>{company.name}</span>
                          </label>
                        </div>
                      </div>
                    </>
                  );
                }
                )}

              </div>

            </div>

            <div className='mt15' />

          </>
        );
      })}

    </>
  )
}
