import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import questions from './mock.json';
import SideImg from '../../img/BasicProfile.png';

export default function QnaLast({ setSteps }) {

    //const [response, setResponse] = useState([]);


    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();


    const [checked, setChecked] = useState(true); 
    const handleChange = () => {   
      setChecked(!checked);      
    }; 

    const onSubmit = data => {
        sessionStorage.setItem("QnaLast", JSON.stringify(data));

        const postData = {
            QnaFirst: JSON.parse(sessionStorage.getItem('QnaFirst')),
            QnaJson: JSON.parse(sessionStorage.getItem('QnaJson')),
            QnaLast: JSON.parse(sessionStorage.getItem('QnaLast')),
            Qna: questions
        }
      
        console.log(postData);
       
      
        axios.post('https://lmslive.finnovate.in/php/tallyapi.php', postData).then(
            function (res) {
                sessionStorage.setItem("userData", JSON.stringify(res.data));
                sessionStorage.setItem("tallyso_finn_is", JSON.stringify(res.data.id));
                console.log(sessionStorage.getItem('tallyso_finn_is'));
                setSteps(4);
                /*var json = sessionStorage.getItem("userData");
                console.log(JSON.parse(json).score_breakup);*/


            }
        )
    }

    // const [authorization_ , setAuthorization]= useState(true); 



    return (
        <Fragment>

            <div className="mainwrapper">

                <div className='form_title'>
                    <div className="mt30 font20 fw600">Almost there</div>
                    <div className="font15 color495">We never spam. And don't call without your permission. </div>
                </div>

                <div className="row formWpr mt25">
                    <div className="col-lg-8 formouter">
                        <ul className="form_swipe_tab font12 fw600">
                           
                        </ul>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="formtitle flex_center justify_center">
                                <div>
                                    <div className="font20 fw600">Get my FinnFit Report</div>
                                    <div className="font15 color495 ">You will receive the report on email.</div>
                                </div>
                                <div className='d-none d-md-block'>

                                    <button className="bluebtn font13 fw600" type='submit' disabled={!isDirty && !isValid}>Save
                                        <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                        </svg></span></button>
                                </div>
                            </div>

                            {/* Form */}
                            <div className="form_prnt pt25 pl35 pr35 pb35">
                                <div className="custome_form">

                                    <div className="form-row">

                                        <div className="form-group col-md-6 pr20">
                                            <label htmlFor="emailAddress" className="font14 fw600">Email address</label>
                                            <input type="text" className="form-control" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                                            <span className="text-danger"> {errors.emailAddress && errors.emailAddress.message}</span>
                                        </div>

                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-6 pr20">
                                            <label htmlFor="mobileNumber" className="font14 fw600">Mobile number</label>
                                            <div className="input-group mb2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text fw500 font14 color212">+91-</div>
                                                </div>
                                                <input type="tel" className="form-control" id="mobileNumber" name="mobileNumber" autoComplete='off' {...register("mobileNumber", { required: 'Mobile Number is required', pattern: { value: /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/, message: 'Invalid Mobile Number' } })} placeholder="Mobile Number" />

                                            </div>
                                            <span className="text-danger"> {errors.mobileNumber && errors.mobileNumber.message}</span>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12 pr20">
                                            <label htmlFor="authorization" className="font15 color495 " >
                                                <input type="checkbox" value="1" checked={checked} onClick={()=>handleChange() }
                                                    name="authorization" {...register("authorization", { required: false })} /> I, hereby authorize Finnovate to contact me via call, WhatsApp, Email or sms to discuss further regarding my financial fitness.
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12 pr20">
                                            <label className="font15 color495 " style={{'color': 'gray'}}>
                                                Your information is safe and protected by encryption.
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="saveBtn d-block d-sm-none">
                                <button type="submit" class="btn btn-primary" disabled={!isDirty && !isValid}>Save and Continue <span class="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" stroke-width="1.30435" stroke-miterlimit="10"></path></svg></span></button>
                            </div>

                        </form>
                        {/* Form End */}

                    </div>

                    <div className="col-lg-4 qouteouter d-none d-md-block">
                        <div className="qoutetext font18 fw500 color212 pl45 pr45">
                            <div className="qoutesign">
                                &#8220;
                            </div>
                            FinnFit is a customized financial fitness program that partners with doctors so that you can become well-off and enjoy the fruits of your labour, into your winter years.
                        </div>


                        <div className="qouteimg"><img src={SideImg} alt='Completed'/></div>
                    </div>
                </div>



            </div>
        </Fragment>
    )
}
