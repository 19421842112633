import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../components/global'
import SideImg from '../img/BasicProfile.png';

export default function Login() {

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    const [password, setpassword] = useState('')
    const [togglePassword, setTogglePassword] = useState(false)
    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }

    const handleChange = (e) => {
        const { value } = e.target;
        if (e.target.name === 'password') {
            setpassword(value);
        }
    }


    const onSubmit = data => {

        const Postdata = {
            "email": data.emailAddress,
            "password": data.password
        };

        axios.post(`${apiUrl}login`, Postdata)
            .then(function (response) {


                SweetAlert.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Signed in Successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                }).then(function () {

                    sessionStorage.setItem('token', response.data.data.token);
                    const pdata = { "email": data.emailAddress };

                    axios.post(`${apiUrl}profile/view`, pdata).then(function (response) {
                        sessionStorage.setItem('profile_id', response.data.profile_id);
                        sessionStorage.setItem('full_name', response.data.first_name + '\'s');
                        sessionStorage.setItem('profile_details_id', response.data.profile_details_id);

                        if (response.data.step > 3) {
                            window.location.href = "/dashboard";
                        } else {
                            window.location.href = "/basic-profile";
                        }

                    });

                    //

                });
            })
            .catch(e => {
                SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
            });

    }

    return (
        <Fragment>

            <div className="innercontainer mb40">
                <div className="mainwrapper">

                    <div className='form_title'>
                        <div className="mt30 font20 fw600">Client Login</div>
                    </div>


                    <div className="row formWpr mt25">
                        <div className="col-lg-8 formouter">


                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">Login</div>
                                        <div className="font15 color495 ">Basic details</div>
                                    </div>
                                    <div>

                                        <a href='/register' className="bluebtn font13 fw600">Register
                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                            </svg></span></a>
                                    </div>
                                </div>

                                {/* Form */}
                                <div className="form_prnt pt25 pl35 pr35 pb35">
                                    <div className="custome_form">

                                        <div className="form-row">

                                            <div className="form-group col-md-6 pr20">
                                                <label htmlFor="emailAddress" className="font14 fw600">Email address</label>
                                                <input type="text" className="form-control" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                                                <span className="text-danger"> {errors.emailAddress && errors.emailAddress.message}</span>
                                            </div>


                                            <div className="form-group col-md-6 pl20">
                                                <label htmlFor="password" className="font14 fw600">Password</label>

                                                <input className="form-control" type={togglePassword ? "text" : "password"} name="password" autoComplete='off' {...register("password", { required: 'Password is required', minLength: { value: 5, message: 'Password is too short' }, maxLength: { value: 15, message: 'Password is too long' } })} placeholder="Password" onChange={handleChange} value={password} />
                                                <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                                                <span className="text-danger"> {errors.password && errors.password.message}</span>
                                            </div>




                                            <button className="bluebtn font13 fw600" type='submit' disabled={!isDirty && !isValid}>Log In
                                                <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                                </svg></span></button>

                                        </div>
                                    </div>
                                </div>
                            </form>
                            {/* Form End */}
                        </div>

                        <div className="col-lg-4 qouteouter d-none d-md-block">
                            <div className="qoutetext font18 fw500 color212 pl45 pr45">

                                <div className="qoutesign">
                                    &#8220;
                                </div>
                                Creating a Financial Plan is the first step towards achieving financial freedom!
                            </div>


                            <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                        </div>
                    </div>

                </div>
            </div>

        </Fragment>
    )
}
