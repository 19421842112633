import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { apiUrl, ageCalculate } from '../global';
//import SweetAlert from 'sweetalert2'
import formatAmount from 'indian-currency-formatter';
import SideImg from '../../img/Goals.png';

export default function GoalScreen1({ setSubstep, substep, nextsubStep, clientName }) {

  const { register, handleSubmit, reset, setFocus, formState: { errors, isValid } } = useForm();

  const [kidsDetails, setKidsDetails] = useState([]);
  const [income, setIncome] = useState(null);

  const [retire, setRetire] = useState(null);

  // eslint-disable-next-line
  const [assumptions, setAssumptions] = useState([]);

  useEffect(() => {

    const postData = {
      'profile_id': sessionStorage.getItem('profile_id')
    }

    axios.post(`${apiUrl}profile/kids-edu`, postData).then(function (response) {
      setKidsDetails(response.data);
    })

    axios.post(`${apiUrl}get_assumption_by_profile_id`, postData).then(function (response) {
      setAssumptions(response.data);
    })




    axios.post(`${apiUrl}profile/self-retirement`, postData).then(function (response) {
      setIncome(formatAmount(response.data[0].total_abcd / 12));
      setPassiveIncome((response.data[0].total_abcd / 12));
      setRetire({ passiveIncome: response.data[0].total_abcd / 12 });

      sessionStorage.setItem('self_goal_id', response.data[0].goal_id)
    })


  }, []);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(retire);
    // eslint-disable-next-line
  }, [retire]);


  // eslint-disable-next-line
  const [eduSuccess, setEducSuccess] = useState(0);

  const setPassiveIncome = (e) => {

    setIncome(formatAmount(e.target.value));
  }
  const onSubmit = data => {


    if (data.EducationArr) {

      data.EducationArr.forEach((education, index) => {

        if (education.g_amount.replace(/,/g, "") > 0 && education.pg_amount.replace(/,/g, "") > 0) {

          const required_after_cal = 17 - education.age;
          const required_after = required_after_cal < 1 ? 1 : required_after_cal;
          const required_till = required_after + 6;
          const postData = {
            "goal_type": "Education",
            "aspire": "Education",
            "goal_name": "Education",
            "goal_id": education.goal_id,
            "profile_id": sessionStorage.getItem('profile_id'),
            "plan_for": {
              "profile_details_id": education.prof_det_id,
              "current_age": education.age < 1 ? 1 : education.age,
              "relation": sessionStorage.getItem('g_relation'),
            },
            "yearly_expense": {
              "0": education.g_amount * education.gcurrencyDigit,
              "1": education.pg_amount * education.pgcurrencyDigit
            },
            "no_years": {
              "0": 4,
              "1": 2
            },
            "goal_params": {
              "age": education.age < 1 ? 1 : education.age,
              "post_inflation": 7,
              "post_return": 7,
              "goal_inflation": 7,
              "required_after": required_after,
              "required_till": required_till
            },
            "risk_profile": {
              "available": false,
              "pre_return": 8,
              "risk_profile_id": sessionStorage.getItem('profile_id')
            },
            "assumptions": {
              "assumption_id": false,
              "inflation": 7.5,
              "income_grow": "10"
            },
            "total_abcd": [{
              "amount": parseInt(education.g_amount.replace(/,/g, "")) + parseInt(education.pg_amount.replace(/,/g, "")),
              "frequency": 12,
              "no_of_times": 1,
              "gap": 0
            }],
            "actual": [{
              "actual_y": 0,
              "frequency": 1,
              "actual_ls": 1,
              "grow": "0"

            }]
          };

          axios.post(`${apiUrl}goals/add`, postData)
            .then(function (response, data) {
              setEducSuccess(index + 1);
            })
            .catch(function (error) {
              setEducSuccess(0);
            });
        }

      });
    }

    const retire_age = parseInt(assumptions.retire_age);
    const age = ageCalculate(sessionStorage.getItem('self_dob'));
    const life_expectancy = parseInt(assumptions.life_expentancy);
    const relation = "self";
    const required_after = retire_age - age;
    const required_till = life_expectancy - age;
    const aspire = "Retirement";
    const retireData = {
      "goal_type": "Retirement",
      "goal_name": "Retirement",
      "auto_reset": "0",
      "aspire": aspire,
      "goal_id": sessionStorage.getItem('self_retire_goal_id'),
      "profile_id": sessionStorage.getItem('profile_id'),
      "plan_for": {
        "profile_details_id": sessionStorage.getItem('profile_details_id'),
        "current_age": age,
        "relation": relation
      },
      "goal_params": {
        "age": age,
        "retire_age": retire_age,
        "life_expectancy": life_expectancy,
        "post_inflation": 7,
        "post_return": 8,
        "goal_inflation": 7,
        "required_after": required_after,
        "required_till": required_till
      },
      "risk_profile": {
        "available": false,
        "pre_return": 10,
        "risk_profile_id": sessionStorage.getItem('profile_id')
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": 7.5,
        "income_grow": "10"
      },
      "total_abcd": [{
        "amount": data.passiveIncome.replace(/,/g, ""),
        "frequency": 12,
        "no_of_times": 1,
        "gap": 0
      }],
      "actual": [{
        "actual_y": 0,
        "frequency": 1,
        "actual_ls": 1,
        "grow": "0"

      }]
    };

    axios.post(`${apiUrl}goals/add`, retireData)
      .then(function (response) {


        axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
          (response, data) => {
            setSubstep(nextsubStep(substep, 3))
          }
        );

      });


  }

  const onTodoChange = (e) => {
    setIncome(formatAmount(e.target.value.replace(/,/g, "")));
  }

  return (
    <Fragment>

      <div className="mainwrapper">

        <div className="form_title">
          <div className="mt30 font20 fw600">{clientName} Goal</div>
          <div className="font15 color495">Few questions to understand your family's protection</div>
        </div>
        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li className="act">1</li>
              <li>2</li>
              <li>3</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Crucial goals</div>
                  <div className="font15 color495 ">These are not even goals, but must-haves to a financially safe life.</div>
                </div>
                <div>

                  <button className="bluebtn font13 fw600 d-none d-md-block" disabled={income === null ? "disabled" : ""}>Save
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}

              <div className="p35">

                <div className="form-row">
                  <div>
                    <div className="color212 font14 fw600">Monthly passive income required <span className="colorfa8">*</span>
                    </div>
                    <ul className="steps_tag font14 fw500 color182 mt15">
                      <li>
                        <label>
                          <input type="radio" name="suggestedIncome" value="80000" onClick={(e) => { setPassiveIncome(e); setFocus('passiveIncome'); }} />
                          <span className="rdo_text_box">
                            &#8377;80,000
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type="radio" name="suggestedIncome" value="100000" onClick={(e) => { setPassiveIncome(e); setFocus('passiveIncome'); }} />
                          <span className="rdo_text_box">
                            &#8377;1,00,000
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type="radio" name="suggestedIncome" value="120000" onClick={(e) => { setPassiveIncome(e); setFocus('passiveIncome'); }} />
                          <span className="rdo_text_box">
                            &#8377;1,20,000
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type="radio" name="suggestedIncome" value="150000" onClick={(e) => { setPassiveIncome(e); setFocus('passiveIncome'); }} />
                          <span className="rdo_text_box">
                            &#8377;1,50,000
                          </span>
                        </label>
                      </li>
                      <li>
                        <div className="custome_form searchinput">
                          <div className="form-row">
                            <div className="form-group">
                              <input type="text" className="form-control font14 color212 fw500" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter desired amount" name="passiveIncome" {...register("passiveIncome", { required: 'Monthly passive income required' })} value={income} onChange={onTodoChange} autoFocus="autoFocus" />
                              <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <span className="text-danger"> {errors.passiveIncome && errors.passiveIncome.message}</span>
                  </div>
                </div>



                {
                  Object.keys(kidsDetails).map((kids, i) => {

                    return (<>

                      {
                        kidsDetails[kids].relation === "child" ?

                          <>
                            <div className="formdevider mt35 mb35" />

                            <div className="color212 font15 fw600">{kidsDetails[kids].full_name} Education</div>

                            <div className="form-row form_prnt mt20">


                              <div className="col-md-6 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw600">UG goal for {kidsDetails[kids].full_name}</label>
                                <div className="input-group selectarrow">
                                  <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name={`EducationArr[${i}]g_amount`} autoComplete='off' defaultValue={kidsDetails[kids].ug_amount} {...register(`EducationArr.${i}.g_amount`, { required: 'Required. Enter zero to Skip' })} maxLength={3} minLength={1} />
                                  <select className="custom-select" id="inputGroupSelect01" {...register(`EducationArr.${i}.gcurrencyDigit`)}>
                                    <option selected value="100000">Lakhs</option>
                                    <option value="10000000">Crore</option>
                                  </select>
                                  <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                                <span className="text-danger"> {errors.EducationArr?.[i]?.g_amount?.message}</span>

                              </div>
                              <div className="col-md-6 pl20">
                                <label htmlFor="inputEmail4" className="font14 fw600">PG goal for {kidsDetails[kids].full_name}</label>
                                <div className="input-group selectarrow">
                                  <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name={`EducationArr[${i}]pg_amount`} autoComplete='off' defaultValue={kidsDetails[kids].pg_amount} {...register(`EducationArr.${i}.pg_amount`, { required: 'Required. Enter zero to Skip' })} maxLength={3} minLength={1} />
                                  <select className="custom-select" id="inputGroupSelect01" {...register(`EducationArr.${i}.pgcurrencyDigit`)}>
                                    <option selected value="100000">Lakhs</option>
                                    <option value="10000000">Crore</option>
                                  </select>
                                  <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                                <span className="text-danger"> {errors.EducationArr?.[i]?.pg_amount?.message}</span>
                              </div>
                            </div>
                            <input type="hidden" className="form-control" name={`EducationArr[${i}]prof_det_id`} autoComplete='off' {...register(`EducationArr.${i}.prof_det_id`)} defaultValue={kidsDetails[kids].profile_details_id} />
                            <input type="hidden" className="form-control" name={`EducationArr[${i}]age`} autoComplete='off' {...register(`EducationArr.${i}.age`)} defaultValue={ageCalculate(kidsDetails[kids].dob)} />
                            <input type="hidden" className="form-control" name={`EducationArr[${i}]goal_id`} autoComplete='off' {...register(`EducationArr.${i}.goal_id`)} defaultValue={kidsDetails[kids].goal_id} />

                            <div className="formdevider mt35 mb35" />
                          </>

                          : ''
                      }

                    </>)
                  })
                }


              </div>

              <div className="saveBtn d-block d-sm-none">
                <button type='submit' disabled={!isValid} className="btn btn-primary">Save and Continue <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
              </div>

            </form>

            {/* Form End */}

          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              Hard to predict monthly passive income at your retirement age. Check most entered values <a href="/dashboard">here</a>.
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}
