import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { apiUrl } from '../../global';
import formatAmount from 'indian-currency-formatter';

export default function EditHealthInsurance({ commitments }) {


    const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

    const onSubmit = data => {


        const Postdata = {
            "prop_id": sessionStorage.getItem('profile_id'),
            "profile_id": sessionStorage.getItem('profile_id'),
            "profile_details_id": sessionStorage.getItem('profile_details_id'),
            "proposer": sessionStorage.getItem('profile_details_id'),
            "type": 9,
            "sub_type": 1,
            "owner": sessionStorage.getItem('profile_id'),
            "instr_name": 'Health',
            "invest_type": 0,
            "invest_amount": data.premiumAmount.replace(/,/g, ""),
            "amount": data.premiumAmount.replace(/,/g, ""),
            "cost_amount": data.premiumAmount.replace(/,/g, ""),
            "insured_value": data.selfMoneyType * data.sumAssured,
            "current_value": data.selfMoneyType * data.sumAssured,
            "frequency": data.selfFrequency,
            "moneyDigits": data.selfMoneyType,
            "ppt": 1,
            "policy_term": 1,
            "rider_id": data.insured,
            "invest_id": data.selfInvestId
        };

        axios.post(`${apiUrl}investment/add-investment`, Postdata)
            .then(function (response) {

                window.location.reload();

            })
    }

    const [health, setHealth] = useState(null);

    const [premiumAmount, setPremiumAmount] = useState();
    const handleChange = (e) => {

        const { value } = e.target;

        if (e.target.name === 'premiumAmount') {
            setPremiumAmount(formatAmount(value.replace(/,/g, "")));
        }

    };

    const [selfName, setSelfName] = useState();

    useEffect(() => {

        axios.post(`${apiUrl}profile/term-data`, { profile_id: sessionStorage.getItem('profile_id') }).then(function (response, data) {
            const res = response.data[0];
            setSelfName(res.selfName);

            const moneyDigits = res.healthsumAssured.toString().length >= 8 ? 10000000 : 100000;

            setHealth({ healthInsurance: res.healthInsurance, sumAssured: res.healthsumAssured, selfMoneyType: moneyDigits, premiumAmount: formatAmount(res.healthpremiumAmount), selfInvestId: res.healthInvestId, insured: res.healthCoverIds, selfFrequency: res.healthFrequency });
        });

    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(health);
        // eslint-disable-next-line
    }, [health]);

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>

                <input type="hidden" name="selfInvestId" {...register("selfInvestId")} value="" />

                <div className="form_prnt">
                    <div className="expandtab font12 color353 fw500">{selfName} Term Insurance</div>

                    <div className="custome_form">
                        <div className="form-row mt20">
                            <div className="col-md-12 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw500">Sum assured</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" name="sumAssured" aria-label="Text input with dropdown button" autoComplete='off' {...register("sumAssured", { required: 'Sum assured is required' })} maxLength={3} minLength={1} />
                                    <select className="custom-select font14" id="inputGroupSelect01" name='selfMoneyType' {...register("selfMoneyType")}>
                                        <option value="100000">Lakhs</option>
                                        <option value="10000000">Crore</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                            <span className="text-danger"> {errors.sumAssured && errors.sumAssured.message}</span>
                        </div>
                        <div className="form-row mt20">
                            <div className="col-md-12 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw500">Premium amount</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" name="premiumAmount" autoComplete='off' {...register("premiumAmount", { required: 'Premium Amount is required' })} onChange={handleChange} value={premiumAmount} />
                                    <select className="custom-select font14" id="inputGroupSelect01" name='selfFrequency' {...register("selfFrequency")}>
                                        <option value="12">Monthly</option>
                                        <option value="4">Quarterly</option>
                                        <option value="2">Half Yearly</option>
                                        <option value="1" selected>Yearly</option>
                                        <option value="0">Lumpsum</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                            <span className="text-danger"> {errors.premiumAmount && errors.premiumAmount.message}</span>
                        </div>
                    </div>
                </div>


                <div className="saveBtn backsaveBtn">
                    <button type="button" class="btn btn-primary">Cancel </button>
                    <button type="submit" class="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>
                </div>
            </form>
        </Fragment >
    )
}
