import React, { useState, useEffect } from 'react'
import { apiUrl, inWords } from '../../global';
import axios from 'axios';
import EditInvestment from '../EditInvestment/EditInvestment';

export default function DashboardInvestment() {

    const [isActive, setActive] = useState(false);
    const [assetClass, setAssetClass] = useState(null);

    const [heading, setHeading] = useState("Commitments");
    const [subheading, setSubheading] = useState("Commitments");

    const toggleClass = (status, assetClass, heading, subheading) => {
        setActive(status);
        setAssetClass(assetClass);

        setHeading(heading);
        setSubheading(subheading);
    };

    // eslint-disable-next-line
    const [investment, setInvestment] = useState([]);
    // eslint-disable-next-line
    const [equity, setEquity] = useState();
    const [debt, setDebt] = useState();
    const [other, setOther] = useState();

    const [total, setTotal] = useState();

    // eslint-disable-next-line
    const [equityY, setEquityY] = useState();
    const [debtY, setDebtY] = useState();
    const [otherY, setOtherY] = useState();
    // eslint-disable-next-line
    const [totalY, setTotalY] = useState();


    const [equityBar, setEquityBar] = useState(0);
    const [debtBar, setDebtBar] = useState(0);
    const [otherBar, setOtherBar] = useState(0);

    const [equityBarY, setEquityBarY] = useState(0);
    const [debtBarY, setDebtBarY] = useState(0);
    const [otherBarY, setOtherBarY] = useState(0);
        
  

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}investment/group-dashboard/${profile_id}`).then(
            (response, data) => {
                setInvestment(response.data.data);

                let find_equity = response.data.sum.find(product => product.name === "Equity");
                setEquity(find_equity ? inWords(find_equity.subTotal) : '00');

                let find_debt = response.data.sum.find(product => product.name === "Debt");
                setDebt(find_debt ? inWords(find_debt.subTotal) : '00');

                let find_others = response.data.sum.find(product => product.name === "Property");
                let gold_others = response.data.sum.find(product => product.name === "Gold");
                gold_others = gold_others ? gold_others.subTotal : 0;
                let otherSum = find_others.subTotal + gold_others;
                setOther(find_others ? inWords(otherSum) : '');

                setTotal(inWords(response.data.final));

                let find_equityY = response.data.sum.find(product => product.name === "Equity");
                setEquityY(find_equityY ? inWords(find_equityY.yearly_sum) : '0');

                let find_debtY = response.data.sum.find(product => product.name === "Debt");

                console.log('find_debtY', find_debtY)
                setDebtY(find_debtY ? inWords(find_debtY.yearly_sum) : 0);
                

                let find_othersY = response.data.sum.find(product => product.name === "Property");
                let gold_othersY = response.data.sum.find(product => product.name === "Gold");
                gold_others = gold_othersY ? gold_othersY.yearly_sum : 0;
                let otherSumY = (find_othersY ? find_othersY.yearly_sum : 0) + gold_others;
                
                setOtherY(find_othersY ? inWords(otherSumY) : '');

                setTotalY(inWords(response.data.yearlySum));

                console.log('response.data.yearlySum', response.data.yearlySum);

                setEquityBar(find_equity ? Math.round((find_equity.subTotal/response.data.final)*100) : 0);
                setDebtBar(find_debt ? Math.round((find_debt.subTotal/response.data.final)*100) : 0);
                setOtherBar(otherSum > 0 ? Math.round((otherSum/response.data.final)*100) : 0);


                setEquityBarY((find_equityY && response.data.yearlySum > 0)? Math.round((find_equityY.yearly_sum/response.data.yearlySum)*100) : 0);
                setDebtBarY((find_debtY && response.data.yearlySum > 0) ? Math.round((find_debtY.yearly_sum/response.data.yearlySum)*100) : 0);
                setOtherBarY((otherSumY && response.data.yearlySum > 0) ? Math.round((otherSumY/response.data.yearlySum)*100) : 0);

                
            });

    }, []);

    console.log('equityBarY', equityBarY, 'debtBarY', debtBarY, 'otherBarY', otherBarY);

    return (
        <>


            <div className="db_investments_outer">
                <div className="db_investments_title">
                    <div className="font14 fw500 color182">Total investments</div>
                    <div className={!total ? "font18 fw500 color182 digitOpacity" : "font18 fw500 color182"}>{!total ? '00' : total}</div>
                </div>
            </div>

            <div className="db_cashflow_outer pt20 pl30 pr30">
                <ul className="nav nav-pills mb-3 font12 fw500 color353" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="pills-currentallocation-tab" data-toggle="pill" href="#pills-currentallocation" role="tab" aria-controls="pills-currentallocation" aria-selected="true">Current allocation</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-suggestallocation-tab" data-toggle="pill" href="#pills-suggestallocation" role="tab" aria-controls="pills-suggestallocation" aria-selected="false">Yearly allocation</a>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-currentallocation" role="tabpanel" aria-labelledby="pills-currentallocation-tab">

                        <div class="progress multi-progress">
                            <div class="progress-bar bg-equity" role="progressbar" style={{ 'width': equityBar+'%' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar bg-debt" role="progressbar" style={{ 'width': debtBar+'%' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar bg-other" role="progressbar" style={{ 'width': otherBar+'%' }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>


                        <div className="cashflow_inflow_wpr cashflow_outflow_wpr">
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title font11 fw600 color6d7 lightgreen_dot">
                                    EQUITY <span className={!equity ? "color182 ml8 digitOpacity" : "color182 ml8"}>{!equity ? '00' : equity}</span> <span class="editBtn" onClick={() => toggleClass(true, 'equity', 'Equity Investments', 'MF & Direct Equity')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                </div>
                                <div className="incomeinfo_grid3 mt15">

                                    {investment.map((data, key) => {


                                        return (<>
                                            {
                                                data.cat_name === "Equity" && (<>
                                                    <div>
                                                        <div className="font12 fw500 color6d7">{data.type_name}</div>
                                                        <div className="font16 fw500 color162">{inWords(data.current_value)}</div>
                                                    </div>


                                                </>)
                                            }
                                        </>);
                                    })}

                                </div>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title justify_center font11 fw600 color6d7 mediumgreen_dot">
                                    <div>
                                        DEBT <span className={!debt ? "color182 ml8 digitOpacity" : "color182 ml8"}>{!debt ? '00' : debt}</span> <span class="editBtn" onClick={() => toggleClass(true, 'debt', 'Debt Investments', 'deposit & ppf')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                    </div>
                                </div>
                                <div className="incomeinfo_grid3 mt15">

                                    {investment.map((data, key) => {


                                        return (<>
                                            {
                                                data.cat_name === "Debt" && (<>
                                                    <div>
                                                        <div className="font12 fw500 color6d7">{data.type_name}</div>
                                                        <div className="font16 fw500 color162">{inWords(data.current_value)}</div>
                                                    </div>
                                                </>)
                                            }
                                        </>);
                                    })}

                                </div>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title justify_center font11 fw600 color6d7 darkgreen_dot">
                                    <div>
                                        OTHERS <span className={!other ? "color182 ml8 digitOpacity" : "color182 ml8"}>{!other ? '00' : other}</span> <span class="editBtn" onClick={() => toggleClass(true, 'other', 'Other Investments', 'property & gold')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                    </div>
                                </div>
                                <div className="incomeinfo_grid3 mt15">
                                    {investment.map((data, key) => {


                                        return (<>
                                            {
                                                (data.cat_name === "Property" || data.cat_name === "Gold") && (<>
                                                    <div>
                                                        <div className="font12 fw500 color6d7">{data.cat_name === "Property" ? data.heading + ' ' + data.type_name : data.type_name}</div>
                                                        <div className="font16 fw500 color162">{inWords(data.current_value)}</div>
                                                    </div>
                                                </>)
                                            }
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>


                    </div>

                    {/* Yearly Allocation Tab */}

                    <div className="tab-pane fade" id="pills-suggestallocation" role="tabpanel" aria-labelledby="pills-suggestallocation-tab">

                    <div class="progress multi-progress">
                            <div class="progress-bar bg-equity" role="progressbar" style={{ 'width': equityBarY+'%' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar bg-debt" role="progressbar" style={{ 'width': debtBarY+'%' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar bg-other" role="progressbar" style={{ 'width': otherBarY+'%' }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                        <div className="cashflow_inflow_wpr cashflow_outflow_wpr">
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title font11 fw600 color6d7 lightgreen_dot">
                                    EQUITY <span className={equityY <= 0 ? "color182 ml8 digitOpacity" : "color182 ml8"}>{equityY <= 0 ? '00' : equityY}</span> <span class="editBtn" onClick={() => toggleClass(true, 'equity', 'Equity Investments', 'MF & Direct Equity')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                </div>
                                <div className="incomeinfo_grid3 mt15">

                                    {investment.map((data, key) => {


                                        return (<>
                                            {
                                                data.cat_name === "Equity" && (<>
                                                    <div>
                                                        <div className="font12 fw500 color6d7">{data.type_name}</div>
                                                        <div className="font16 fw500 color162">{inWords(data.y_amount)}</div>
                                                    </div>


                                                </>)
                                            }
                                        </>);
                                    })}

                                </div>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title justify_center font11 fw600 color6d7 mediumgreen_dot">
                                    <div>
                                        DEBT <span className={debt <= 0 ? "color182 ml8 digitOpacity" : "color182 ml8"}>{debtY <= 0 ? '00' : debtY}</span> <span class="editBtn" onClick={() => toggleClass(true, 'debt', 'Debt Investments', 'deposit & ppf')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                    </div>
                                </div>
                                <div className="incomeinfo_grid3 mt15">

                                    {investment.map((data, key) => {


                                        return (<>
                                            {
                                                data.cat_name === "Debt" && (<>
                                                    <div>
                                                        <div className="font12 fw500 color6d7">{data.type_name}</div>
                                                        <div className="font16 fw500 color162">{inWords(data.y_amount)}</div>
                                                    </div>
                                                </>)
                                            }
                                        </>);
                                    })}

                                </div>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title justify_center font11 fw600 color6d7 darkgreen_dot">
                                    <div>
                                        OTHERS <span className={other <= 0 ? "color182 ml8 digitOpacity" : "color182 ml8"}>{otherY <= 0 ? '00' : otherY}</span> <span class="editBtn" onClick={() => toggleClass(true, 'other', 'Other Investments', 'property & gold')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                    </div>
                                </div>
                                <div className="incomeinfo_grid3 mt15">
                                    {investment.map((data, key) => {


                                        return (<>
                                            {
                                                (data.cat_name === "Property" || data.cat_name === "Gold") && (<>
                                                    <div>
                                                        <div className="font12 fw500 color6d7">{data.cat_name === "Property" ? data.heading + ' ' + data.type_name : data.type_name}</div>
                                                        <div className="font16 fw500 color162">{inWords(data.y_amount)}</div>
                                                    </div>
                                                </>)
                                            }
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            {
                isActive === true && (<EditInvestment toggleClass={toggleClass} setActive={setActive} assetClass={assetClass} heading={heading} subheading={subheading} />)
            }
        </>
    )
}
