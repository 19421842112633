import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { apiUrl } from '../../global';
import formatAmount from 'indian-currency-formatter';


export default function EditInvestmentById({ investment, i, setActive }) {

    console.log('EditInvestment ', investment);

    const Back = () => {
        setActive(false);
    }

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    const onSubmit = data => {

        const Postdata = {
            "prop_id": data.profileId,
            "profile_id": data.profileId,
            "profile_details_id": data.profile_details_id,
            "type": data.type,
            "owner": data.profileId,
            "invest_type": 0,
            "sub_type": data.sub_type,
            "instr_name": data.instr_name,
            "frequency": data.frequency,
            "current_value": data.currentVal * data.moneyDigits,
            "cost_amount": data.currentVal * data.moneyDigits,
            "amount": data.invested.replace(/,/g, ""),
            "invest_amount": data.invested.replace(/,/g, ""),
            "ppt": 1,
            "policy_term": 1,
            "invest_id": data.invest_id,
            "moneyDigits": data.moneyDigits,
            "value_total": data.currentVal * data.moneyDigits,
        };


        axios.post(`${apiUrl}investment/add-investment`, Postdata)
            .then(function (response) {
                window.location.reload(true)
            })

    }

    // eslint-disable-next-line
    const [invested, setInvested] = useState(investment.amount > 0 ? formatAmount(Math.round(investment.amount / (investment.frequency > 0 ? investment.frequency : 1))) : '0');

    const handleChange = (e) => {

        const { value } = e.target;

        if (e.target.name === 'invested') {
            setInvested(formatAmount(value.replace(/,/g, "")));
        }

    };


    return (
        <>
            <Fragment>

                <div class="card card-body">
                    <div className="form_prnt">
                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <input type="hidden" name="type" {...register("type")} defaultValue={investment.type} />
                            <input type="hidden" name="sub_type" {...register("sub_type")} defaultValue={investment.sub_type} />
                            <input type="hidden" name="instr_name" {...register("instr_name")} defaultValue={investment.instr_name} />
                            <input type="hidden" name="invest_id" {...register("invest_id")} defaultValue={investment.invest_id} />

                            <input type="hidden" name="profileId" {...register("profileId")} defaultValue={investment.profile_id !== null ? investment.profile_id : sessionStorage.getItem("profile_id")} />
                            <input type="hidden" name="profile_details_id" {...register("profile_details_id")} defaultValue={investment.profile_details_id !== null ? investment.profile_details_id : sessionStorage.getItem('profile_details_id')} />

                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">Current value</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="currentVal" {...register("currentVal", { required: 'Required.' })} defaultValue={investment.current_value > 0 ? investment.current_value.toString().length >= 8 ? investment.current_value / 10000000 : investment.current_value / 100000 : ''} />
                                        <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")} defaultValue={investment.current_value.toString().length >= 8 ? 10000000 : 100000}>
                                            <option value="100000">Lakhs</option>
                                            <option value="10000000">Crore</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.currentVal && errors.currentVal.message}</span>
                                </div>
                            </div>

                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">Periodic investment</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="invested" {...register("invested", { required: 'Required.' })} value={invested} onChange={handleChange} />
                                        <select className="custom-select font14" id="inputGroupSelect01" name="frequency" {...register("frequency")} defaultValue={investment.frequency}>
                                            <option value="12">Monthly</option>
                                            <option value="4">Quarterly</option>
                                            <option value="2">Half Yearly</option>
                                            <option value="1">Yearly</option>
                                            <option value="0">Lumpsum</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.invested && errors.invested.message}</span>
                                </div>
                            </div>

                            <div className="saveBtn backsaveBtn">
                                <button type="button" class="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" class="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>
                            </div>

                        </form>
                    </div>
                </div>
            </Fragment>
        </>
    )
}
