import React, { Fragment, useEffect, useState } from 'react'

import Gold from '../investments/Gold'
import FixedDeposits from '../investments/FixedDeposits'
import MutualFund from '../investments/MutualFund'
import Nps from '../investments/Nps'
import PpfEpf from '../investments/PpfEpf'
import RealEstate from '../investments/RealEstate'
import Stocks from '../investments/Stocks'
import SideImg from '../../img/investment.png';

export default function InvestmentScreen2({setSubstep, prevsubStep, substep, StepChange, steps, nextStep, lifeStage}) {

  // eslint-disable-next-line
  const [investment, setInvestment] = useState(JSON.parse(sessionStorage.getItem('investments')));

  const activeAsset = (id) =>{
    setActive(id);
  }

  let iValues = investment.filter((item) => {
    return item.checked === true
 })
  // eslint-disable-next-line
  const [name, setName] = useState(sessionStorage.getItem('full_name'));

 const [active, setActive] = useState(iValues[0].id);
 //eslint-disable-next-line
  const [maxCount, setCount] = useState(iValues.length);

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    console.log(iValues);
  }, [iValues]);
  return (

    
    <Fragment>
      <div className="mainwrapper">

      <div className="form_title">
        <div className="mt30 font20 fw600">{name} Investment</div>
        <div className="font15 color495">we need to map a plan to match your goal. so we need your income info</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li onClick={() => setSubstep(prevsubStep(substep))}>1</li>
              <li className="act">2</li>
            </ul>




            {active === 1 && (<MutualFund active={active} iValues={iValues} maxCount={maxCount} investment={investment} activeAsset={activeAsset} current={current} setCurrent={setCurrent} StepChange={StepChange} steps={steps} nextStep={nextStep} lifeStage={lifeStage}/>)}
            {active === 2 && (<FixedDeposits active={active} iValues={iValues} maxCount={maxCount} investment={investment} activeAsset={activeAsset} current={current} setCurrent={setCurrent}StepChange={StepChange} steps={steps} nextStep={nextStep} lifeStage={lifeStage}/>)}
            {active === 3 && (<Stocks active={active} iValues={iValues} maxCount={maxCount} investment={investment} activeAsset={activeAsset} current={current} setCurrent={setCurrent} StepChange={StepChange} steps={steps} nextStep={nextStep} lifeStage={lifeStage}/>)}
            {active === 4 && (<Gold active={active} iValues={iValues} maxCount={maxCount} investment={investment} activeAsset={activeAsset} current={current} setCurrent={setCurrent} StepChange={StepChange} steps={steps} nextStep={nextStep} lifeStage={lifeStage}/>)}
            {active === 5 && (<RealEstate active={active} iValues={iValues} maxCount={maxCount} investment={investment} activeAsset={activeAsset} current={current} setCurrent={setCurrent} StepChange={StepChange} steps={steps} nextStep={nextStep} lifeStage={lifeStage}/>)}
            {active === 6 && (<PpfEpf active={active} iValues={iValues} maxCount={maxCount} investment={investment} activeAsset={activeAsset} current={current} setCurrent={setCurrent} StepChange={StepChange} steps={steps} nextStep={nextStep} lifeStage={lifeStage}/>)}
            {active === 7 && (<Nps active={active} iValues={iValues} maxCount={maxCount} investment={investment} activeAsset={activeAsset} current={current} setCurrent={setCurrent} StepChange={StepChange} steps={steps} nextStep={nextStep} lifeStage={lifeStage}/>)}
            {/* Form End */}


          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              If you have other expenses, please click add expenses button and add them.
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection'/></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}
