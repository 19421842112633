import React, { Fragment, useState } from 'react'
import '../../css/desireinvestment.css';
import { useForm } from 'react-hook-form'
import SideImg from '../../img/investment.png';

export default function InvestmentScreen1({ setSubstep, substep, nextsubStep }) {

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

  // eslint-disable-next-line
  const [investment, setInvestment] = useState([
    {
      "id": 1,
      "name": "Mutual Fund",
      "type": 4,
      "sub_type": 9,
      "icon": "mutualfunds_tab",
      checked: true
    },
    {
      "id": 2,
      "name": "Fix Deposits",
      "type": 4,
      "sub_type": 9,
      "icon": "pig_tab",
      checked: true
    },
    {
      "id": 3,
      "name": "Stocks",
      "type": 4,
      "sub_type": 9,
      "icon": "chartbar_tab",
      checked: true
    },
    {
      "id": 4,
      "name": "Gold",
      "type": 4,
      "sub_type": 9,
      "icon": "gold_tab",
      checked: true
    },
    {
      "id": 5,
      "name": "Real Estate",
      "type": 4,
      "sub_type": 9,
      "icon": "office_tab",
      checked: false
    },
    {
      "id": 6,
      "name": "PPF/EPF",
      "type": 4,
      "sub_type": 9,
      "icon": "coins_tab",
      checked: true
    },
    {
      "id": 7,
      "name": "NPS Fund",
      "type": 4,
      "sub_type": 9,
      "icon": "moneytime_tab",
      checked: true
    },
    {
      "id": 8,
      "name": "Other Invest.",
      "type": 4,
      "sub_type": 9,
      "icon": "plant_tab",
      checked: false
    }
  ]);
  // eslint-disable-next-line
  const [name, setName] = useState(sessionStorage.getItem('full_name'));

  /*const handleCheckClick = (id) => {
    setInvestment(investment.map((checkbox) => checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox));
  };*/

  const onSubmit = data => {

    sessionStorage.setItem("protectionData", JSON.stringify(data));
    sessionStorage.setItem("investments", JSON.stringify(investment));

    setSubstep(nextsubStep(substep, 2))

  }

  return (
    <Fragment>

      <div className="mainwrapper">
        <div className="form_title">
          <div className="mt30 font20 fw600">{name} Investment</div>
          <div className="font15 color495">we need to map a plan to match your goal. so we need your income info</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li className="act">1</li>
              <li>2</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Your investments</div>
                  <div className="font15 color495 ">An Approximate value of your investments as of today</div>
                </div>
                <div>

                  <button className="bluebtn font13 fw600  d-none d-md-block" disabled={!isDirty && !isValid}>Next
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}


              <div className="p25">
                <div className="font16 fw500 color212">Please enter the details for the following type of investments</div>
                <div className="investment_list mt25">
                  <ul>


                    {investment.map((data, key) => {
                      return (
                        <li className={data.icon} key={key}>
                          <label>
                            <input type="checkbox" {...register("investmentType")} value={data.aspire} disabled /*onChange={() => handleCheckClick(data.id)}*/ />
                            <span className="greentick" />
                            <div className="invest_outer">
                              <div className="tabsIcon" />
                              <div className="invest_list_text mt15">{data.name}</div>
                            </div>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                  <span className="text-danger">{errors.goalType && 'At least one checkobox must be selected'}</span>
                </div>
              </div>


              <div className="saveBtn d-block d-sm-none">
                <button type='submit' disabled={!isDirty && !isValid} className="btn btn-primary">Save and Continue <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
              </div>
            </form>

            {/* Form End */}

          </div>

          <div className="col-lg-4 qouteouter  d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              Don’t worry about enclosing PAN details to finnovate. Your details will be secure and encrypted.
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection'/></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}
