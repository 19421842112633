import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../components/global'
import SideImg from '../img/BasicProfile.png';

export default function ULoginForgotPassword() {

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    const [reset, setReset] = useState();

    useEffect(() => {

        const search = window.location.search;
        const params = new URLSearchParams(search);

        console.log(params);
        const resetLink = params.get('reset');

        if (resetLink === '1') {
            setReset(1);
        } else {
            setReset(0);
        }

    }, []);

    console.log(reset);

    const [password, setpassword] = useState('')
    const [cpassword, setCpassword] = useState('')
    const [togglePassword, setTogglePassword] = useState(false)
    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }

    const handleChange = (e) => {
        const { value } = e.target;
        if (e.target.name === 'password') {
            setpassword(value);
        }
        if (e.target.name === 'cpassword') {
            setCpassword(value);
        }

    }

    const onSubmit = data => {

        const Postdata = {
            "email": data.emailAddress,
            "password": data.password
        };

        axios.post(`${apiUrl}admin/login`, Postdata)
            .then(function (response) {

                if (response.data.status === 101) {
                    SweetAlert.fire("Warning", "" + response.data.msg + "", "warning");

                } else {

                    SweetAlert.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Signed in Successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    }).then(function () {

                        sessionStorage.setItem('admin_id', response.data.admin_id);
                        sessionStorage.setItem('admin_name', response.data.admin_name);
                        sessionStorage.setItem('admin_role', response.data.role);
                        window.location.href = "/admin-dashboard";

                    });


                }

            })
            .catch(e => {
                SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
            });

    }

    return (
        <Fragment className="Ulogin">
            <div className='Ulogin'>
                <div className="innercontainer mb40">
                    <div className="mainwrapper">

                        <div className="mt30 font20 fw600 colorFFF">Admin Forgot Password</div>

                        <div className="row formWpr mt25">
                            <div className="col-lg-8 formouter">

                                {
                                    reset === 1 ? (<>

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="formtitle flex_center justify_center">
                                                <div>
                                                    <div className="font20 fw600">Admin Reset Password</div>
                                                    <div className="font15 color495 ">Admin details</div>
                                                </div>
                                                <div>

                                                    <a href='/ulogin' className="bluebtn font13 fw600">Ulogin
                                                        <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                                        </svg></span></a>
                                                </div>
                                            </div>

                                            {/* Form */}
                                            <div className="form_prnt pt25 pl35 pr35 pb35">
                                                <div className="custome_form">

                                                    <div className="form-row">

                                                        <div className="form-group col-md-6 pr20">
                                                            <label htmlFor="password" className="font14 fw600">Password</label>

                                                            <input className="form-control" type={togglePassword ? "text" : "password"} name="password" autoComplete='off' {...register("password", { required: 'Password is required', minLength: { value: 5, message: 'Password is too short' }, maxLength: { value: 15, message: 'Password is too long' } })} placeholder="Password" onChange={handleChange} value={password} />
                                                            <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                                                            <span className="text-danger"> {errors.password && errors.password.message}</span>
                                                        </div>

                                                        <div className="form-group col-md-6 pl20">
                                                            <label htmlFor="password" className="font14 fw600">Confirm Password</label>

                                                            <input className="form-control" type={togglePassword ? "text" : "password"} name="cpassword" autoComplete='off' {...register("cpassword", { required: 'Password is required', minLength: { value: 5, message: 'Password is too short' }, maxLength: { value: 15, message: 'Password is too long' } })} placeholder="Confirm Password" onChange={handleChange} value={cpassword} />
                                                            <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                                                            <span className="text-danger"> {errors.password && errors.password.message}</span>
                                                        </div>


                                                        <button className="bluebtn font13 fw600" type='submit' disabled={!isDirty && !isValid}>Set New Password
                                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                                            </svg></span></button>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </>) :

                                        (<>

                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="formtitle flex_center justify_center">
                                                    <div>
                                                        <div className="font20 fw600">Admin Forgot Password</div>
                                                        <div className="font15 color495 ">Admin details</div>
                                                    </div>
                                                    <div>

                                                        <a href='/ulogin' className="bluebtn font13 fw600">Ulogin
                                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                                            </svg></span></a>
                                                    </div>
                                                </div>

                                                {/* Form */}
                                                <div className="form_prnt pt25 pl35 pr35 pb35">
                                                    <div className="custome_form">

                                                        <div className="form-row">

                                                            <div className="form-group col-md-12 pr20">
                                                                <label htmlFor="emailAddress" className="font14 fw600">Admin Email address</label>
                                                                <input type="text" className="form-control" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                                                                <span className="text-danger"> {errors.emailAddress && errors.emailAddress.message}</span>
                                                            </div>



                                                            <button className="bluebtn font13 fw600" type='submit' disabled={!isDirty && !isValid}>Send Reset Password Link
                                                                <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                                                </svg></span></button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </>)
                                }


                                {/* Form End */}
                            </div>

                            <div className="col-lg-4 qouteouter d-none d-md-block">
                                <div className="qoutetext font18 fw500 color212 pl45 pr45">

                                    <div className="qoutesign">
                                        &#8220;
                                    </div>
                                    Don’t worry about enclosing PAN details to finnovate. Your details will be secure and encrypted.
                                </div>


                                <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
