import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { apiUrl } from '../global';
import SideImg from '../../img/Goals.png';

export default function GoalScreen2({ setSubstep, substep, nextsubStep, StepChange, steps, nextStep, prevsubStep, setGoalType, clientName }) {

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

  // eslint-disable-next-line
  const [goalCount, setGoalCount] = useState(0);
  const onSubmit = data => {

    sessionStorage.setItem("protectionData", JSON.stringify(data));
    sessionStorage.setItem("goals", JSON.stringify(goals));

    let goalCheck = goals.find(product => product.checked === true);


    if (goalCheck) {
      setGoalType(data.goalType);
      setSubstep(nextsubStep(substep, 3))

    } else {
      setGoalCount(0);
      return;
    }


  }

  const [goals, setGoals] = useState([{
    "id": 1,
    "aspire": "Own a House",
    "goalName":"House",
    "type": "Wealth",
    checked: false
  }, {
    "id": 2,
    "aspire": "Buy a Car",
    "goalName":"Car",
    "type": "Wealth",
    checked: false
  }, {
    "id": 3,
    "aspire": "Big Vacation",
    "goalName":"Vacation",
    "type": "Wealth",
    checked: false
  },
  {
    "id": 4,
    "aspire": "Big Wedding",
    "goalName":"Marriage",
    "type": "Marriage",
    checked: false
  },
  {
    "id": 5,
    "aspire": "10 year wealth plan",
    "goalName":"Wealth",
    "type": "Wealth",
    checked: false
  },
  {
    "id": 6,
    "aspire": "5 year wealth plan",
    "goalName":"Wealth",
    "type": "Wealth",
    checked: false
  },
  {
    "id": 7,
    "aspire": "Add Custom goal",
    "goalName":"Wealth",
    "type": "Wealth",
    checked: false
  }]);

  const handleCheckClick = (id) => {
    setGoals(goals.map((checkbox) => checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox));

  };

  const skipGoals = () => {
    axios.post(`${apiUrl}profile/update-stepper`, { profile_id: sessionStorage.getItem('profile_id'), step: steps + 1 }).then(function (response, data) {
      //StepChange(nextStep(steps));
      window.location.href = "/dashboard";
    });
  }
  console.log();

  return (
    <Fragment>




      <div className="mainwrapper">

        <div className="form_title">
          <div className="mt30 font20 fw600">{clientName} Goal</div>
          <div className="font15 color495">Few questions to understand your family's protection</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li onClick={() => setSubstep(prevsubStep(substep))}>1</li>
              <li className="act">2</li>
              <li>3</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Lifestyle goals</div>
                  <div className="font15 color495 ">The goals that are focused towards a good lifestyle. </div>
                </div>
                <div>

                  <button className="bluebtn font13 fw600 d-none d-md-block" disabled={goals.find(product => product.checked === true) !== undefined  ? "" : "disabled"}>Next
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}

              <div className="p35">

                <div className="form-row">
                  <div>
                    <div className="color212 font14 fw600">Add new goal (select one of the lifestyle goal)
                    </div>
                    <ul className="steps_tag font14 fw500 color182 mt15">

                      {goals.map((checkbox) => (
                        <li key={checkbox.id}>
                          <label>
                            <input type="checkbox" name="goalType" {...register("goalType")} value={checkbox.aspire} onChange={() => handleCheckClick(checkbox.id)} />
                            <span className="rdo_text_box">
                              {checkbox.aspire}
                            </span>
                          </label>
                        </li>
                      ))}

                        {
                          goals.find(product => product.checked === true) !== undefined ? '' : <><li>
                          <label>
                            <input type="checkbox" onClick={() => skipGoals()} />
                            <span className="rdo_text_box skipGoal">
                              Skip Goals
                            </span>
                          </label>
                        </li></>
                        }
                      
                    </ul>
                    <span className="text-danger">{errors.goalType && 'At least one checkobox must be selected'}</span>
                  </div>
                </div>


              </div>

              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={() => setSubstep(prevsubStep(substep))}> Back </button>
                <button type='submit' disabled={!isDirty && !isValid} className="btn btn-primary"> Save </button>
              </div>

            </form>
            {/* Form End */}

          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              Hard to predict monthly passive income at your retirement age. Check most entered values <a href="/dashboard">here</a>.
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}
