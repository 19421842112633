import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { apiUrl } from '../global';
import SideImg from '../../img/Protection.png';

export default function CashflowScreen3({ StepChange, setSubstep, substep, prevsubStep, nextsubStep, steps, nextStep, clientName }) {

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();


  // eslint-disable-next-line
  const [property, setProperty] = useState([{
    "id": 1,
    "propertyName": "Primary",
    "propertyType": "Residential",
    "type":7,
    "sub_type":1,
    checked: true
  }, {
    "id": 2,
    "propertyName": "Secondary",
    "propertyType": "Commercial",
    "type":7,
    "sub_type":2,
    checked: true
  }, {
    "id": 3,
    "propertyName": "Land",
    "propertyType": "Land",
    "type":7,
    "sub_type":2,
    checked: true
  }]);


  /*
  
    const [propertyCount, setPropertyCount] = useState(3);
  
    const decrement = () => {
      if (propertyCount === 1) return;
      setPropertyCount((propertyCount) => propertyCount - 1);
    };
  
    const increment = () => {
      if (propertyCount === 4) return;
      setPropertyCount((propertyCount) => propertyCount + 1);
    };
  
    */

  const skipStep = () => {

    setSubstep(nextsubStep(substep, 4))

  }

  const onSubmit = data => {


    const profileId = sessionStorage.getItem('profile_id');


    data.propertyArr.forEach((goal, index) => {

      if ((goal.propertyValue !== null || goal.propertyCurrValue !== null) && goal.propertyName !== null) {

        const Postdata = {
          "prop_id": profileId,
          "profile_id": profileId,
          "profile_details_id": sessionStorage.getItem('profile_details_id'),
          "type": goal.type,
          "owner": profileId,
          "instr_name": goal.propertyName,
          "property_type": goal.propertyType,
          "sub_type": goal.sub_type,
          "frequency": 0,
          "cost_amount": goal.propertyValue * goal.propertyValueType,
          "current_value": goal.propertyCurrValue * goal.propertyCurrValueType,
          "amount": goal.propertyValue * goal.propertyValueType,
          "invest_amount": goal.propertyValue * goal.propertyValueType,
          "invest_id": ""
        };

        axios.post(`${apiUrl}investment/add-investment`, Postdata)
          .then(function (response) {

          })
          .catch(function (error) {
            console.log(error);
            SweetAlert.fire(
              'Property',
              'Failed. Something went wrong. Retry again',
              'error'
            )
          });

      }


    });

    sessionStorage.setItem("expenseData", JSON.stringify(data))
    setSubstep(nextsubStep(substep, 4))

  }
  return (
    <Fragment>

      <div className="mainwrapper">
        <div className="form_title">
          <div className="mt30 font20 fw600">{clientName} Cashflow</div>
          <div className="font15 color495">we need to map a plan to match your goal. so we need your income info</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li>1</li>
              <li onClick={() => setSubstep(prevsubStep(substep))}>2</li>
              <li className="act">3</li>
              <li>4</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Properties</div>
                  <div className="font15 color495 ">Also a bit approximate idea about your commitments</div>
                </div>
                <div>

                  <button className="bluebtn font13 fw600 d-none d-md-block" disabled={!isDirty && !isValid}>Save
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}

              <div className="p35">

                <div className="form-row">

                  {
                    property.map((e, i) => <>

                      <div className="form_prnt">

                        <div className="form-row justify-content-between">


                          <div className="col-md-3 pr20">
                            <label htmlFor="inputEmail4" className="font14 fw600">Property Name</label>
                                <input type="text" className="form-control font14 color212 fw500" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter property name" name={`propertyArr.${i}.propertyName`} defaultValue={e.propertyName} {...register(`propertyArr.${i}.propertyName`)} />
                                <span className="text-danger"> {errors.propertyArr?.[i]?.propertyName?.message}</span>
                          </div>

                          <div className="col-md-4 pr20">
                            <label htmlFor="inputEmail4" className="font14 fw600">Property buying value</label>
                            <div className="input-group selectarrow select50">
                              <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" name={`propertyArr.${i}.propertyValue`} {...register(`propertyArr.${i}.propertyValue`)} maxLength={3} minLength={1}/>
                              <select className="custom-select" id="inputGroupSelect01" name={`propertyArr.${i}.propertyValueType`} {...register(`propertyArr.${i}.propertyValueType`)}>
                                <option value="100000" selected>Lakhs</option>
                                <option value="10000000">crore</option>
                              </select>
                              <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                            </div>
                          </div>


                          <div className="col-md-4 pl20">
                            <label htmlFor="inputEmail4" className="font14 fw600">Property current value</label>
                            <div className="input-group selectarrow select50">
                              <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" name={`propertyArr.${i}.propertyCurrValue`} {...register(`propertyArr.${i}.propertyCurrValue`)} maxLength={3} minLength={1}/>
                              <select className="custom-select" id="inputGroupSelect01" name={`propertyArr.${i}.propertyCurrValueType`} {...register(`propertyArr.${i}.propertyCurrValueType`)}>
                                <option value="100000" selected>Lakhs</option>
                                <option value="10000000">Crores</option>
                              </select>
                              <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                            </div>
                          </div>
                          <input type={"hidden"} name={`propertyArr.${i}.propertyType`} defaultValue={e.propertyType} {...register(`propertyArr.${i}.propertyType`)} />

                          <input type={"hidden"} name={`propertyArr.${i}.type`} defaultValue={e.type} {...register(`propertyArr.${i}.type`)} />
                          <input type={"hidden"} name={`propertyArr.${i}.sub_type`} defaultValue={e.sub_type} {...register(`propertyArr.${i}.sub_type`)} />
                        </div>

                      </div>

                      <div className="formdevider mt35 mb35" />

                    </>)
                  }

                </div>
              </div>

              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={() => setSubstep(prevsubStep(substep))}> Back </button>
                <button type='submit' disabled={!isDirty && !isValid} className="btn btn-primary"> Save </button>
              </div>

            </form>
            {/* Form End */}
            <div className='pr-5'>

              <div class="mob_left_right mb5" style={{ 'text-align': 'center' }}>
                <button type="button" class="btn btn-primary backtowizardBtn font14 fw600" onClick={() => skipStep()}>Skip Properties</button>
              </div>

            </div>

          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              If you have other expenses, please click add expenses button and add them.
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}
