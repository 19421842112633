import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { apiUrl, inWords } from '../../global';
import EditIncome from '../EditCashflow/EditIncome';
import EditExpense from '../EditCashflow/EditExpense';
import EditCommitments from '../EditCashflow/EditCommitments';
import EditEmi from '../EditCashflow/EditEmi';
import Chart from 'react-apexcharts'

export default function DashboardCashflow() {


    const [totalIncome, setTotalIncome] = useState(null);
    const [totalExpense, setTotalExpense] = useState(null);
    const [totalOutflow, setTotalOutflow] = useState(null);

    const [totalLoan, setTotalLaon] = useState(null);

    const [selfIncome, setSelfIncome] = useState(null);
    const [spouseIncome, setSpouseIncome] = useState(null);
    const [RentalIncome, setRentalIncome] = useState(null);
    const [InvestmentIncome, setInvestmentIncome] = useState(null);
    const [Bonus, setBonus] = useState(null);
    const [rentalExpense, setRentalExpense] = useState(null);
    const [Household, setHousehold] = useState(null);
    const [ChildrenEducation, setChildrenEducation] = useState(null);
    const [utilityBills, setUtilityBills] = useState(null);
    const [lifestyle, setLifestyle] = useState(null);
    const [vacation, setVacation] = useState(null);

    const [emi, setEmi] = useState([]);
    const [commitments, setCommitments] = useState([]);

    const [termData, setTermData] = useState([]);

    const [isActive, setActive] = useState(false);
    const toggleClass = (form) => {
        setActive(form);
    };

    // eslint-disable-next-line
    const [spouseLi, setSpouseLi] = useState([]);

    // eslint-disable-next-line
    const [spouseDetails, setSpouseDetails] = useState([]);

    // eslint-disable-next-line
    const [assumptions, setAssumptions] = useState([]);

    const [commitmentTotal, setCommitmentTotal] = useState(0);
    useEffect(() => {


        const profile_id = sessionStorage.getItem('profile_id');

        axios.post(`${apiUrl}profile/term-data`, { profile_id: sessionStorage.getItem('profile_id') }).then(function (response, data) {

            console.log(response.data);
            setTermData(response.data[0]);
            setCommitmentTotal(parseInt(response.data[0].healthpremiumAmount ? response.data[0].healthpremiumAmount : 0) + parseInt(response.data[0].spousepremiumAmount ? response.data[0].spousepremiumAmount : 0) + parseInt(response.data[0].selfpremiumAmount ? response.data[0].selfpremiumAmount : 0));
        });


        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {
                if (response.data.status === 100) {

                    setEmi(response.data.liability);
                    setCommitments(response.data.risk_inv);

                    setTotalOutflow(response.data.outflow);

                    setTotalIncome(response.data.incomeSum.incomeSum);

                    setTotalExpense(response.data.expenseSum.expenseSum ? response.data.expenseSum.expenseSum : '0');

                    setTotalLaon(response.data.emiSum.emiSum ? inWords(response.data.emiSum.emiSum) : '0');

                    const remaining = parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow);

                    console.log('response.data.risk_goals', response.data.risk_goals);

                    let spouse_li_goal = response.data.risk_goals.find(product => product.plan_for === parseInt(sessionStorage.getItem('spouse_prof_det_id')));

                    console.log('spouse_li_goal', spouse_li_goal);
                    setSpouseLi(spouse_li_goal ? spouse_li_goal : '');

                    let spouse_details = response.data.prof_det.find(product => product.relation === "spouse");
                    setSpouseLi(spouse_li_goal);

                    setSpouseDetails(spouse_details);

                    setAssumptions(response.data.assumption);

                    setChartData({
                        series: [parseInt(response.data.expenseSum.expenseSum), parseInt(response.data.emiSum.emiSum), parseInt(response.data.ligiSum.lifeSum), parseInt(remaining)],
                        options: {
                            chart: {
                                type: 'donut',
                            },
                            colors: ["#FFDC5F", "#FBAF3C", "#FB923C", "#EAF2D8"],
                            dataLabels: {
                                enabled: false
                            },
                            tooltip: {
                                enabled: true,
                                y: {
                                    formatter: function (val) {
                                        return "Amount " + inWords(val)
                                    },
                                    title: {
                                        formatter: function (seriesName) {
                                            return ''
                                        }
                                    }
                                }
                            },
                            plotOptions: {
                                pie: {
                                    startAngle: -90,
                                    endAngle: 90,
                                    offsetY: 10,
                                    donut: {
                                        labels: {
                                            show: true,
                                            name: {
                                                show: true,
                                            },
                                            value: {
                                                show: true,
                                            },
                                            total: {
                                                show: true,
                                                showAlways: true,
                                                label: "Remaining",
                                                formatter: function (value) {
                                                    const sum = remaining;
                                                    return inWords(sum)
                                                },
                                            },
                                        },
                                    }
                                }
                            },
                            grid: {
                                padding: {
                                    bottom: -80
                                }
                            },
                            responsive: [{
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: 200
                                    },
                                    legend: {
                                        position: 'bottom'
                                    }
                                }
                            }]
                        }
                    });

                    response.data.incomes.forEach(item => {

                        switch (item.sub_type) {
                            case "IncomeSelf":
                                setSelfIncome(item.total_amount);
                                break;
                            case "IncomeSpouse":
                                setSpouseIncome(item.total_amount);
                                break;

                            case 'RentalIncome':
                                setRentalIncome(item.total_amount);
                                break;

                            case 'InvestmentIncome':
                                setInvestmentIncome(item.total_amount);
                                break;

                            case 'Bonus':
                                setBonus(item.total_amount);
                                break;

                            case 'Household':

                                setHousehold(item.total_amount);
                                break;

                            case 'rentalExpense':

                                setRentalExpense(item.total_amount);
                                break;

                            case 'ChildrenEducation':

                                setChildrenEducation(item.total_amount);
                                break;

                            case 'UtilityBills':

                                setUtilityBills(item.total_amount);
                                break;


                            case 'Lifestyle':

                                setLifestyle(item.total_amount);
                                break;


                            case 'Vacation':

                                setVacation(item.total_amount);
                                break;


                            default:
                                break;
                        }
                    })

                }
            });
    }, [commitmentTotal]);

    // eslint-disable-next-line
    const [chartData, setChartData] = useState({
        series: [14, 4, 10],
        colors: ["#FFDC5F", "#FB923C"],
        options: {
            chart: {
                type: 'donut',
            },
            legend: {
                show: false
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 90,
                    offsetY: 10
                }
            },
            grid: {
                padding: {
                    bottom: -80
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }]
        }
    });

    return (
        <>

            <div className="db_cashflow_outer pt20 pl30 pr30">
                <ul className="nav nav-pills mb-3 font12 fw500 color353" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="pills-overview-tab" data-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="true">Overview</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-inflow-tab" data-toggle="pill" href="#pills-inflow" role="tab" aria-controls="pills-inflow" aria-selected="false">Inflow {inWords(totalIncome)}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-outflow-tab" data-toggle="pill" href="#pills-outflow" role="tab" aria-controls="pills-outflow" aria-selected="false">Outflow {inWords(totalOutflow)}</a>
                    </li>
                    {/*<li className="nav-item">
                        <a className="nav-link" id="pills-taxation-tab" data-toggle="pill" href="#pills-taxation" role="tab" aria-controls="pills-taxation" aria-selected="false">Taxation</a>
                        </li>*/}
                </ul>
                <div className="tab-content pt10" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab">
                        <div className="cashflow_list pb15">
                            <div className="cashflow_list_title font15 fw500 color182">
                                Total Inflow
                            </div>
                            <div className="font12 fw500 color626">
                                Your family’s total income including returns and inheritance.
                            </div>
                            <div className={!totalIncome ? "font20 fw500 color182 mt12 digitOpacity" : "font24 fw500 color182 mt12"}>{totalIncome > 0 ? inWords(totalIncome) : '00'}</div>
                        </div>
                        <div className="cashflow_list pb15 mt20">
                            <div className="cashflow_list_title orangedotclr font15 fw500 color182">
                                Total Outflow
                            </div>
                            <div className="font12 fw500 color626">
                                Your family’s total income including returns and inheritance.
                            </div>
                            <div className={!totalOutflow ? "font20 fw500 color182 mt12 digitOpacity" : "font24 fw500 color182 mt12"}>{totalOutflow > 0 ? inWords(totalOutflow) : '00'}</div>
                        </div>

                        <div id="chart">
                            <Chart options={chartData.options} series={chartData.series} height="350" type="donut" />
                        </div>

                    </div>
                    <div className="tab-pane fade" id="pills-inflow" role="tabpanel" aria-labelledby="pills-inflow-tab">
                        <div className="cashflow_inflow_wpr">
                            <div className="cashflow_inflow_title">
                                <div>
                                    <div className="font13 fw500 color6d7">Total Inflow</div>

                                    {
                                        totalIncome > 0 ? <div className="font24 fw500 color182">{inWords(totalIncome)}</div> : <div className="font24 fw500 color182 digitOpacity">00</div>
                                    }

                                </div>
                                <div>
                                    <button type="button" className="btn btn-light addinflow_btn font12 fw500 color182" onClick={() => toggleClass('income')} ><span className="cashflow_peechclr mr5" /> Add</button>
                                </div>
                            </div>
                            <div className="inflow_progressbar mt15">
                                <progress value="0" max="100"></progress>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title font11 fw600 color6d7">
                                    EARNED INCOME <span class="editBtn" onClick={() => toggleClass('income')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                </div>
                                <div className="incomeinfo mt15">
                                    <div>
                                        <div className="font12 fw500 color6d7">Self income</div>
                                        <div className={!selfIncome ? "font16 fw500 color162 digitOpacity " : "font16 fw500 color162"}>{selfIncome > 0 ? inWords(selfIncome) : '00'}</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">Variable inc.</div>
                                        <div className={!Bonus ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"} >{Bonus > 0 ? inWords(Bonus) : '00'}</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">Spouse’s inc.</div>
                                        <div className={!spouseIncome ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"} >{spouseIncome > 0 ? inWords(spouseIncome) : '00'}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title font11 fw600 color6d7">
                                    OTHER INCOME <span class="editBtn" onClick={() => toggleClass('income')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                </div>
                                <div className="incomeinfo mt15">
                                    <div>
                                        <div className="font12 fw500 color6d7">Rental</div>
                                        <div className={!RentalIncome ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"}>{RentalIncome > 0 ? inWords(RentalIncome) : '00'}</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">Investment</div>
                                        <div className={!InvestmentIncome ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"} >{InvestmentIncome > 0 ? inWords(InvestmentIncome) : '00'}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-outflow" role="tabpanel" aria-labelledby="pills-outflow-tab">
                        <div className="cashflow_inflow_wpr cashflow_outflow_wpr">
                            <div className="cashflow_inflow_title">
                                <div>
                                    <div className="font13 fw500 color6d7">Total Outflow</div>
                                    <div className={!totalOutflow ? "font24 fw500 color182 digitOpacity" : "font24 fw500 color182"}>{totalOutflow > 0 ? inWords(totalOutflow) : '00'}</div>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-light addinflow_btn font12 fw500 color182" onClick={() => toggleClass('expense')}><span className="cashflow_orngclr mr5" /> Add</button>
                                </div>
                            </div>
                            <div className="inflow_progressbar mt15">
                                <progress value="0" max="100"></progress>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title font11 fw600 color6d7 orangclr_dot">
                                    EXPENSE <span className={!totalExpense ? "color182 ml8 digitOpacity" : "color182 ml8"}>{!totalExpense ? '00' : inWords(totalExpense)}</span> <span class="editBtn" onClick={() => toggleClass('expense')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                </div>
                                <div className="incomeinfo mt15">
                                    <div>
                                        <div className="font12 fw500 color6d7">Household</div>
                                        <div className={!Household ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"}>{Household > 0 ? inWords(Household) : '00'}</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">Education</div>
                                        <div className={!ChildrenEducation ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"}>{ChildrenEducation > 0 ? inWords(ChildrenEducation) : '00'}</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">Vacation</div>
                                        <div className={!vacation ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"}>{vacation > 0 ? inWords(vacation) : '00'}</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">Others</div>
                                        <div className={!(rentalExpense + utilityBills + lifestyle) ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"}>{(rentalExpense + utilityBills + lifestyle) > 0 ? inWords(rentalExpense + utilityBills + lifestyle) : '00'}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title justify_center font11 fw600 color6d7 orangclr_dot">
                                    <div>
                                        EMI <span className={!totalLoan ? "color182 ml8 digitOpacity" : "color182 ml8"}>{!totalLoan ? '00' : totalLoan}</span> <span class="editBtn" onClick={() => toggleClass('emi')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                    </div>
                                    <div className="switch_btn font12 fw500">
                                        Suggestion
                                        <label className="switch ml8">
                                            <input type="checkbox" checked />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="incomeinfo mt15">

                                    {
                                        emi.map((data, index) => {
                                            return (
                                                <>
                                                    <div>
                                                        <div className="font12 fw500 color6d7">{data.instr_name}</div>
                                                        <div className="font16 fw500 color162">{inWords(data.emi * 12)}</div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title justify_center font11 fw600 color6d7 darkorangclr_dot">
                                    <div>
                                        COMMITMENTS <span className="color182 ml8">{inWords(commitmentTotal)}</span> <span class="editBtn" onClick={() => toggleClass('commitments')}> <i class="fa-solid fa-pencil"></i> Edit</span>
                                    </div>

                                    <div className="switch_btn font12 fw500">
                                        Suggestion
                                        <label className="switch ml8">
                                            <input type="checkbox" />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="incomeinfo mt15">
                                    <div>
                                        <div className="font12 fw500 color6d7">Health Insurance</div>
                                        <div className={!termData.healthpremiumAmount ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"} >{(!termData.healthpremiumAmount) ? '00' : inWords((termData.healthpremiumAmount * termData.healthFrequency))}</div>
                                    </div>
                                    <div>

                                        <div className="font12 fw500 color6d7">Term Insurance</div>
                                        <div className={!termData.selfpremiumAmount ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"}>{!termData.selfpremiumAmount ? '00' : inWords(parseInt(termData.selfpremiumAmount * termData.selfFrequency))}</div>
                                    </div>

                                    <div>

                                        <div className="font12 fw500 color6d7">Spouse Term Insurance</div>
                                        <div className={(!termData.spousepremiumAmount) ? "font16 fw500 color162 digitOpacity" : "font16 fw500 color162"}>{!termData.spousepremiumAmount ? '00' : inWords(parseInt(termData.spousepremiumAmount * termData.spouseFrequency), 0)}</div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/*<div className="tab-pane fade" id="pills-taxation" role="tabpanel" aria-labelledby="pills-taxation-tab">
                        <div className="cashflow_inflow_wpr cashflow_outflow_wpr">
                            <div className="cashflow_inflow_title">
                                <div>
                                    <div className="font13 fw500 color6d7">Total tax paid for FY2021-2022</div>
                                    <div className="font24 fw500 color182">2.8L</div>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-light addinflow_btn font12 fw500 color182"><span className="edit_icon mr5" /> Add</button>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>

            {
                isActive === "income" && (<EditIncome toggleClass={toggleClass} setActive={setActive} spouseLi={spouseLi} spouseDetails={spouseDetails} assumptions={assumptions} />)
            }
            {
                isActive === "expense" && (<EditExpense toggleClass={toggleClass} setActive={setActive} />)
            }
            {
                isActive === "emi" && (<EditEmi toggleClass={toggleClass} setActive={setActive} emi={emi} />)
            }
            {
                isActive === "commitments" && (<EditCommitments toggleClass={toggleClass} setActive={setActive} commitments={commitments} />)
            }


        </>
    )
}
