import React, { Fragment, useEffect, useState } from 'react'
import DashboardLeft from '../components/dashboard/DashboardLeft'
import DashboardRight from '../components/dashboard/DashboardRight'
import DashboardHeader from '../components/layout/DashboardHeader'
import axios from 'axios';
import { apiUrl, inWords } from '../components/global'
import arrowImg from '../img/svg/arrow.svg';

export default function Dashboard() {

    const [target, setTarget] = useState();
    const [assets, setAssets] = useState();
    const [retirement, setRetirement] = useState([]);
    const [incomeGrow, setIncomeGrow] = useState();

    // eslint-disable-next-line
    const [savingTarget, setSavingTarget] = useState("");
    const [ongoingSaving, setOngoingSaving] = useState("");

    const [familyCount, setFamilyCount] = useState();
    const [assumptions, setAssumptions] = useState([]);
    const [riskDescription, setRiskDescription] = useState();
    const [totalTarget, setTotalTarget] = useState(0);

    const [totalAssets, setTotalAssets] = useState(0);

    // eslint-disable-next-line
    const [name, setName] = useState(sessionStorage.getItem('full_name'));

    const [stepper, setStepper] = useState(0);
    const [roi, setRoi] = useState();
    const [tillDate, setTillDate] = useState();

    const [roiOption, setRoiOption] = useState();
    const [riskAssetMax, setRiskAssetMax] = useState();

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');


        /*
        
        const config = {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
        };

        axios.post(`${apiUrl}prof-liability/delete`, { id: 170, profile_id: 301 }, config)
            .then(function (response) {
                console.log(response.data);
            })
*/

        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {
                setTarget(response.data.target_amount);
                setTotalTarget(response.data.total_target);

                setAssets(response.data.plan_assets);

                setTotalAssets(response.data.total_plan_assets)

                let find_retirement = response.data.goals.find(product => product.goal_type === "Retirement" && product.relation === "self");
                setRetirement(find_retirement);

                setIncomeGrow(parseInt(response.data.assumption.income_grow));
                setStepper(response.data.self_data.stepper);

                if (response.data.profgoalSummary.cashflow_opt === "1") {
                    setSavingTarget((response.data.profgoalSummary.suggested_fund - (response.data.InvestmentSummary.c_amount - response.data.InvestmentSummary.cash_amount) * response.data.profgoalSummary.shortfall_lumpsum) / response.data.profgoalSummary.shortfall_yearly_with_increase);
                } else if (response.data.profgoalSummary.cashflow_opt === "2") {
                    setSavingTarget(parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow));
                } else if (response.data.profgoalSummary.cashflow_opt === "3") {
                    setSavingTarget(response.data.profgoalSummary.custom_amount);
                }

                setFamilyCount(response.data.familyCount);
                setAssumptions(response.data.assumption);
                setOngoingSaving(response.data.InvestmentSummary.y_amount);
                setRiskDescription(response.data.risk_profile.description ? response.data.risk_profile.description : 'Set');

                setRoi(parseFloat(response.data.profgoalSummary.pre_return * 100).toFixed(2));

                setTillDate(response.data.self_data.till_date);

                setRoiOption(response.data.profgoalSummary.roiOption);

                setRiskAssetMax(response.data.risk_profile.max_val);


                let spouse_prof_det_id = response.data.prof_det.find(product => product.relation === "spouse");

                sessionStorage.setItem('spouse_prof_det_id', spouse_prof_det_id.profile_details_id);

            });


    }, []);

    return (
        <Fragment>
            <DashboardHeader />
            <div className="innercontainer mb40">
                <div className="mainwrapper">

                    <div className='form_title'>

                        <div className="mt30 font21 fw600 color384">{name} Financial Fitness Plan</div>
                        <div className="font16 color626">Calculation of required amount to achieve desired financial goals.</div>

                        {
                            stepper < 6 ? <div className='mt30'><a className='btn btn-primary wizard-btn backtowizardBtn font14 fw600' href='/basic-profile'>Back to wizard</a> </div> : ''
                        }

                    </div>


                    <div className="infoBox_wpr mt25">
                        <div className="infoBox_outer act">
                            <div className="infoBox_title">Total need to achieve all the goals</div>
                            <div className="mt15 infoBox_number goldtext">
                                {target}
                            </div>
                            <div className="infoBox_footertext mt20">{retirement.target_amt} Retirement corpus</div>
                        </div>

                        <div className="infoBox_outer">
                            <div className="infoBox_title">Your total investments for goals so far</div>
                            <div className="mt15 infoBox_number greentext">

                                {
                                    assets ? inWords(assets) : '----'
                                }
                                <progress value={(parseInt(totalAssets) / parseInt(totalTarget)) * 100} max="100"></progress>
                            </div>
                            <div className="infoBox_footertext mt20">{inWords(parseInt(totalTarget) - parseInt(totalAssets))} remaining</div>
                        </div>

                        <div className="infoBox_outer">
                            <div className="infoBox_title">
                                Next year’s need
                                <div>Today <img src={arrowImg} alt='Date' /> {tillDate}</div>
                            </div>
                            <div className="mt15 infoBox_number bluetext">
                                {
                                    savingTarget > 0 ? inWords(savingTarget) : '----'
                                }
                            </div>
                            <div className="infoBox_footertext mt20">{incomeGrow > 0 ? 'with ' + incomeGrow + '% increase yearly' : 'No yearly increase'}</div>
                        </div>

                        <div className="infoBox_outer">
                            <div className="infoBox_title">Yearly systemetic ongoing investment (this year)</div>
                            <div className="mt15 infoBox_number goldtext">
                                {
                                    ongoingSaving > 0 ? inWords(ongoingSaving) : '----'
                                }
                                <progress value={ongoingSaving / savingTarget} max="100"></progress>
                            </div>
                            <div className="infoBox_footertext mt20">{Math.round(ongoingSaving / savingTarget)}% of next year’s need</div>
                        </div>

                        <div className="infoBox_outer">
                            <div className="infoBox_title">Your remaining need to achieve next year’s target</div>
                            <div className="mt15 infoBox_number redtext">
                                {(savingTarget - ongoingSaving) > 0 ? inWords((savingTarget - ongoingSaving)) : '0'}
                            </div>
                            <div className="infoBox_footertext mt20 bluetext">Get investment advise</div>
                        </div>
                    </div>


                    <div className="dashboard_outer mt30">
                        <DashboardLeft familyCount={familyCount} assumptions={assumptions} riskDescription={riskDescription} name={name} roi={roi} roiOption={roiOption} riskAssetMax={riskAssetMax} />
                        <DashboardRight />
                    </div>


                </div>
            </div>
        </Fragment>
    )
}
