import React, { useState, useEffect } from 'react'
import { apiUrl } from '../global';
import axios from 'axios';

const friendsArray = [
    {
        "ques_id": 152,
        "key": "gender",
        "ans_id": null
    },
    {
        "ques_id": 153,
        "key": "age",
        "ans_id": null
    },
    {
        "ques_id": 154,
        "key": "occupation",
        "ans_id": null
    },
    {
        "ques_id": 155,
        "key": "IncomeSelf",
        "ans_id": null
    },
    {
        "ques_id": 156,
        "key": "IncomeSpouse",
        "ans_id": null
    },
    {
        "ques_id": 157,
        "key": "expense_perc",
        "ans_id": null
    },
    {
        "ques_id": 158,
        "key": "emi_perc",
        "ans_id": null
    },
    {
        "ques_id": 159,
        "key": "dependents",
        "ans_id": null
    },
    {
        "ques_id": 160,
        "key": "exp_inv_equity_mf",
        "ans_id": null
    },
    {
        "ques_id": 161,
        "key": "illustrative_portfolio",
        "ans_id": null
    },
    {
        "ques_id": 162,
        "key": "worry",
        "ans_id": null
    },
    {
        "ques_id": 163,
        "key": "financial_decission",
        "ans_id": null
    },
    {
        "ques_id": 164,
        "key": "equity_perc",
        "ans_id": null
    },
    {
        "ques_id": 165,
        "key": "acco_status",
        "ans_id": null
    },
    {
        "ques_id": 166,
        "key": "invest_knowledge",
        "ans_id": null
    }
];

export default function GenerateRiskProfile({ toggleClass, setActive, riskDescription }) {

    const Back = () => {
        setActive(false);
    }


    const [riskq, setriskq] = useState([])
    const [steps, setSteps] = useState(0);

    const [apiCall, setApiCall] = useState(false);
    // eslint-disable-next-line
    const [friends, setFriends] = useState(friendsArray); // Setting default value

    const StepChange = (step) => {
        setSteps(step);
    }

    const previousButton = () => {
        if (steps === 0) return;
        StepChange(steps - 1);
    }


    const stepNext = (e) => {


        let ques_id = e.target.getAttribute("ques_id");
        setFriends(
            friends.map((friend) =>
                friend.ques_id === parseInt(ques_id)
                    ? { ...friend, ans_id: e.target.value }
                    : { ...friend }
            )
        );

        if (steps === 14) {
            console.log('submit');
            setApiCall(true);
        } else {
            setSteps(steps + 1);
        }

    }

    useEffect(() => {


        if (apiCall === true) {
            const postData = {
                "set_id": 3,
                "fname": "sanket",
                "lname": "mota",
                "mobile": 9568952565,
                "profile_id": sessionStorage.getItem("profile_id"),
                "dob": "1990-10-20",
                "email": "ashutoshajgaonkar@hotmail.com",
                "weight": 1,
                "goaltype_id": 7,
                "form_data": friends
            }

            axios.post(`${apiUrl}generate-risk`, postData)
                .then(function (response) {

                    const gpostData = { profile_id: sessionStorage.getItem('profile_id') };
                    axios.post(`${apiUrl}goals/reset`, gpostData)
                        .then(function (response) {
                            axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
                                (response, data) => {
                                    window.location.reload();
                                }
                            );
                        })
                })
        }
    }, [friends, apiCall]);


    useEffect(() => {
        const PostData = { weight: 1, set_id: 3, goaltype_id: 7 }
        axios.post(`${apiUrl}risk-question`, PostData).then(
            (response, data) => {
                setriskq(response.data.response_data.question);
                sessionStorage.setItem('questions', JSON.stringify(response.data.response_data.question));
            }
        );
    }, []);




    return (
        <div className="rightSide_menu_outer">
            <div className="rightSide_menu">
                <div className="rightSide_menu_header">
                    <div>
                        <button type="button" class="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                    </div>
                    <div className="font14 fw500 color182">
                        Risk Profile
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div className="topBanner pl25">
                    <div className="font18 fw600 color182 mt50">Generate Risk Profile</div>
                    <div className="font14 fw500 color485">{sessionStorage.getItem('full_name')} family’s Risk Profile</div>
                </div>
                <div className="pl20 pr20">



                    <ul class="risk_tag font14 fw500 color182 mt15">

                        {riskq.map((item, index) => {
                            return (
                                <>

                                    <div className={steps === index ? 'show' : 'hide'} >

                                        <div class="color212 font14 fw600 mb25">{item.label} <span class="colorfa8">*</span></div>

                                        {item.answers.map((company, index) => {
                                            return (
                                                <>
                                                    <li>
                                                        <label>
                                                            <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onClick={stepNext} />
                                                            <span class="rdo_text_box">{company.name}</span>
                                                        </label>
                                                    </li>
                                                </>
                                            );
                                        }
                                        )}
                                    </div>


                                </>)
                        })}
                    </ul>



                    <div className="saveBtn backsaveBtn">
                        <button type="button" class="btn btn-primary" onClick={previousButton}>Previous </button>
                    </div>


                </div>

            </div>
        </div>

    )
}
