import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { apiUrl } from '../../global';

export default function EditPropertiesById({ investment, i }) {

    console.log('EditInvestment ', investment);

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    const onSubmit = data => {

        const Postdata = {
            "prop_id": data.profileId,
            "profile_id": data.profileId,
            "profile_details_id": data.profile_details_id,
            "type": data.type,
            "owner": data.profileId,
            "invest_type": 0,
            "sub_type": data.sub_type,
            "instr_name": data.instr_name,
            "frequency": data.frequency,
            "current_value": data.currentVal * data.moneyDigits,
            "cost_amount": data.currentVal * data.moneyDigits,
            "amount": data.buyVal * data.moneyDigitsBuyValue,
            "invest_amount": data.buyVal * data.moneyDigitsBuyValue,
            "tenure": 1,
            "invest_id": data.invest_id,
            "moneyDigits": data.moneyDigits,
            "value_total": data.currentVal * data.moneyDigits,
            "linked":data.linked
        };


        axios.post(`${apiUrl}investment/add-investment`, Postdata)
            .then(function (response) {
                window.location.reload(true)
            })

    }



    return (
        <>
            <Fragment>
                <div class="card card-body">
                    <div className="form_prnt">
                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <input type="hidden" name="type" {...register("type")} defaultValue={investment.type} />
                            <input type="hidden" name="sub_type" {...register("sub_type")} defaultValue={investment.sub_type} />
                            <input type="hidden" name="instr_name" {...register("instr_name")} defaultValue={investment.instr_name} />
                            <input type="hidden" name="invest_id" {...register("invest_id")} defaultValue={investment.invest_id} />
                            <input type="hidden" name="linked" {...register("linked")} defaultValue={investment.linked} />
                            <input type="hidden" name="frequency" {...register("frequency")} defaultValue={investment.frequency} />
                            <input type="hidden" name="profileId" {...register("profileId")} defaultValue={investment.profile_id !== null ? investment.profile_id : sessionStorage.getItem("profile_id")} />
                            <input type="hidden" name="profile_details_id" {...register("profile_details_id")} defaultValue={investment.profile_details_id !== null ? investment.profile_details_id : sessionStorage.getItem('profile_details_id')} />
                            


                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">Current value</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="currentVal" {...register("currentVal", { required: 'Required.' })} defaultValue={(investment && investment.current_value > 0) ? investment.current_value.toString().length >= 8 ? investment.current_value / 10000000 : investment.current_value / 100000 : 0} maxLength={3} minLength={1} />
                                        <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")} defaultValue={(investment && investment.current_value > 0) ? investment.current_value.toString().length >= 8 ? 10000000 : 100000 : 100000}>
                                            <option value="100000">Lakhs</option>
                                            <option value="10000000">Crore</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.currentVal && errors.currentVal.message}</span>
                                </div>
                            </div>

                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">Buy value</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="buyVal" {...register("buyVal", { required: 'Required.' })} defaultValue={(investment && investment.amount > 0) ? investment.amount.toString().length >= 8 ? investment.amount / 10000000 : investment.amount / 100000 : 0} />
                                        <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigitsBuyValue' {...register("moneyDigitsBuyValue")} defaultValue={(investment && investment.amount > 0) ? investment.amount.toString().length >= 8 ? 10000000 : 100000 : 100000}>
                                            <option value="100000">Lakhs</option>
                                            <option value="10000000">Crore</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.buyVal && errors.buyVal.message}</span>
                                </div>
                            </div>


                            <div className="saveBtn backsaveBtn">
                                <button type="button" class="btn btn-primary">Cancel </button>
                                <button type="submit" class="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>
                            </div>

                        </form>
                    </div>
                </div>
            </Fragment>
        </>
    )
}
