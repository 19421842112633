import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { apiUrl, inWords } from '../../global';
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./slider.css";

export default function DashboardTargets() {

    const [value, setValue] = useState(5);

    const setIncomeGrow = (e) =>{
        setValue(e[1]);
    }

    // eslint-disable-next-line
    const [finalValue, setFinalValue] = React.useState(null);

    const [ongoingSaving, setOngoingSaving] = useState("");
    // eslint-disable-next-line
    const [cashflowOption, setCashflowOption] = useState("");

    const [fund, setFund] = useState("");
    const [shortYearly, setShortYearly] = useState("");
    const [actual, setActual] = useState('');
    // eslint-disable-next-line
    const [savingTarget, setSavingTarget] = useState("");
    const [totalSaving, setTotalSaving] = useState('');
    // eslint-disable-next-line
    const [customAmount, setCustomAmount] = useState("");

    const [selfAge, setSelfAge] = useState();
    const [retireAge, setRetireAge] = useState();
    const [lifeExpentancy, setLifeExpentancy] = useState();
    const [desireValue, setDesireValue] = useState(10);

    const [recommended, setRecommended] = useState();

    const [minSlider, setMinSlider] = useState();
    const [maxSlider, setMaxSlider] = useState();
    const [stepUp, setSetpUp] = useState(10000);

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {

                setOngoingSaving(response.data.InvestmentSummary.y_amount);
                setCashflowOption(response.data.profgoalSummary.cashflow_opt);
                setTotalSaving(parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow));
                setFund(response.data.profgoalSummary.suggested_fund);
                setShortYearly(response.data.profgoalSummary.shortfall_yearly_with_increase);
                setActual(response.data.InvestmentSummary.c_amount !== null ? (parseInt(response.data.InvestmentSummary.c_amount) + parseInt(response.data.InvestmentSummary.cash_amount)) * response.data.profgoalSummary.shortfall_lumpsum : 0);
                setCustomAmount(response.data.profgoalSummary.custom_amount);

                if (response.data.profgoalSummary.cashflow_opt === "1") {
                    setSavingTarget((response.data.profgoalSummary.suggested_fund - (response.data.InvestmentSummary.c_amount - response.data.InvestmentSummary.cash_amount) * response.data.profgoalSummary.shortfall_lumpsum) / response.data.profgoalSummary.shortfall_yearly_with_increase);
                } else if (response.data.profgoalSummary.cashflow_opt === "2") {
                    setSavingTarget(parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow));
                } else if (response.data.profgoalSummary.cashflow_opt === "3") {
                    setSavingTarget(response.data.profgoalSummary.custom_amount);
                }

                setLifeExpentancy(parseInt(response.data.assumption.life_expentancy));
                setRetireAge(parseInt(response.data.assumption.retire_age));
                setSelfAge(parseInt(response.data.self_data.current_age));
                setValue(parseInt(response.data.assumption.income_grow));

                setRecommended((response.data.profgoalSummary.suggested_fund - (response.data.InvestmentSummary.c_amount - response.data.InvestmentSummary.cash_amount) * response.data.profgoalSummary.shortfall_lumpsum) / response.data.profgoalSummary.shortfall_yearly_with_increase)

                setDesireValue(savingTarget > 0 ? (savingTarget / 100000).toFixed(2) : recommended);


                setSetpUp(Math.max(parseInt(totalSaving) + parseInt(ongoingSaving), recommended) <= 1000000 ? 10000 : Math.max(parseInt(totalSaving) + parseInt(ongoingSaving), recommended) > 2000000 ? 100000 : 50000);

                
                setMinSlider(Math.floor(Math.max(parseInt(totalSaving) + parseInt(ongoingSaving), recommended) / (stepUp*100)) * (stepUp*10) );
                setMaxSlider((Math.floor(Math.max(parseInt(totalSaving) + parseInt(ongoingSaving), recommended) / (stepUp*10)) * (stepUp) ) * 20);
                
            });
    }, [fund, actual, shortYearly, savingTarget, recommended, stepUp, minSlider, totalSaving, ongoingSaving]);

    const UpdateAssumption = (value, assumptionType) => {


        const profile_id = sessionStorage.getItem('profile_id');

        let postData = '';
        if (assumptionType === "income_grow") {
            postData = { profile_id: profile_id, assumption: { income_grow: value } };
        } else if (assumptionType === "retire_age") {
            postData = { profile_id: profile_id, assumption: { retire_age: value } };
        } else if (assumptionType === "life_expentancy") {
            postData = { profile_id: profile_id, assumption: { life_expentancy: value } };
        }

        axios.post(`${apiUrl}profile-assumptions/update_assumption`, postData)
            .then(function (response) {

                const gpostData = { profile_id: profile_id };
                axios.post(`${apiUrl}goals/reset`, gpostData)
                    .then(function (response) {
                        axios.post(`${apiUrl}profile/summary-update`, { profile_id: profile_id }).then(
                            (response, data) => {
                                window.location.reload();
                            }
                        );
                    })
            })
    }

    // eslint-disable-next-line
    const cashflowOpt = (option, amount) => {

        let custom = option === 3 ? (desireValue * 100000) : amount;

        axios.post(`${apiUrl}profile/cashflow-option`, { profile_id: sessionStorage.getItem('profile_id'), 'cashflow_opt': option, 'custom_value': custom }).then(
            function (response) {
                window.location.reload();
            }
        )
    }

    const setDesireSlider = (e) => {
        console.log(e);

        setDesireValue(e[1]);
    }

    return (
        <>
            <div className="targetbar_outer mt25">
                <div className="targetbar_title">
                    <div className="font14 fw500 color182">Desired target</div>
                    <div>
                        <div className="tragetvalue font13 fw700 colorf47">
                            <span className="orangeDot mr5" />{desireValue}L
                        </div>
                    </div>
                </div>
                <div className="targetbar mt10">
                    <form className="target_range_bar">
                        <div className="form-group">

                            <RangeSlider id="range-slider-orange"
                                className="single-thumb"
                                value={[0, desireValue]}
                                defaultValue={[0, desireValue]}
                                thumbsDisabled={[true, false]}
                                rangeSlideDisabled={true}
                                step={stepUp/100000}
                                min={minSlider/100000}
                                max={maxSlider/100000}
                                onInput={e => setDesireSlider(e)}
                                onThumbDragEnd={e => cashflowOpt(3, desireValue)}
                                name={"desire"}
                            />

                        </div>
                    </form>
                    <div className="flex_center justify_center mt8">
                        <div className="font12 fw500 color9ba">{inWords(minSlider)}</div>
                        <div className="font12 fw500 color9ba">{inWords(maxSlider)}</div>
                    </div>
                </div>
                <div className="targetvalue_outer mt25">
                    <div className="targetvalue_prnt">
                        <div className="valuecount valuecount_gold font16 fw600">{inWords(ongoingSaving)}</div>
                        <div className="font12 fw500 color626">Yearly ongoing</div>
                    </div>
                    <div className="targetvalue_prnt" onClick={() => cashflowOpt(2, totalSaving)}>
                        <div className="valuecount valuecount_green font16 fw600">
                            {totalSaving ? inWords(parseInt(totalSaving)) : '----'}</div>
                        <div className="font12 fw500 color626">Ongoing + surplus</div>
                    </div>
                    <div className="targetvalue_prnt" onClick={() => cashflowOpt(1, recommended)}>
                        <div className="valuecount valuecount_blue font16 fw600"><div className="f-16 f-w-600">{inWords(recommended)}</div>
                        </div>
                        <div className="font12 fw500 color626">Recommended</div>
                    </div>
                </div>
                <div className="devider_line mt10" />
            </div>

            <div className="targetbar_outer mt25">
                <div className="targetbar_title">
                    <div className="font14 fw500 color182">Yearly investment increment</div>
                    <div>
                        <div className="tragetvalue font13 fw700">
                            {value}%
                        </div>
                    </div>
                </div>
                <div className="targetbar mt20">
                    <form className="target_range_bar">
                        <div className="form-group">
                            {/*<input type="range" className="form-control-range" id="formControlRange" />*/}

                            <RangeSlider id="range-slider-grey"
                                className="single-thumb"
                                value={[0, value]}
                                desireValue={[0, value]}
                                thumbsDisabled={[true, false]}
                                rangeSlideDisabled={false}
                                step={1}
                                min={0}
                                max={20}
                                onInput={e => setIncomeGrow(e)}
                                onThumbDragEnd={e => UpdateAssumption(value, 'income_grow')}
                            />




                        </div>
                    </form>
                    <div className="flex_center justify_center mt8">
                        <div className="font12 fw500 color9ba">0%</div>
                        <div className="font12 fw500 color9ba">20%</div>
                    </div>
                </div>
            </div>
            <div className="devider_line mt10" />

            <div className="targetbar_outer mt25">
                <div className="targetbar_title">
                    <div className="targetbar_title_left font14 fw500 color182">Active years & Retired years</div>
                    <div className="targetbar_title_right">
                        <div className="color353 font13 fw700">
                            {selfAge}
                        </div>
                        <div className="ml10 mr10 color353">-</div>
                        <div className="tragetvalue font13 fw700 color353">
                            {retireAge}
                        </div>
                        <div className="ml10 mr10 color353">-</div>
                        <div className="tragetvalue font13 fw700 color353">
                            {lifeExpentancy}
                        </div>
                    </div>
                </div>
                <div className="targetbar mt20">
                    <form className="target_range_bar">





                        <div className="form-group">

                            {
                                /*
                    
                                <RangeSlider
                                                    id="range-slider-yellow"
                                                    value={retireAge}
                                                    onChange={e => setRetireAge(e.target.value)}
                                                    onAfterChange={e => UpdateAssumption(e.target.value, 'retire_age')}
                                                    min={selfAge + 1}
                                                    max={80}
                                                    step={1}
                                                    tooltip={"off"}
                                                    className="form-control-range multislider1"
                                                />
                                                <RangeSlider
                                                    id="range-slider-yellow"
                                                    value={lifeExpentancy}
                                                    onChange={e => setLifeExpentancy(e.target.value)}
                                                    onAfterChange={e => UpdateAssumption(e.target.value, 'life_expentancy')}
                                                    min={retireAge + 5}
                                                    max={100}
                                                    step={1}
                                                    tooltip={"off"}
                                                    className="form-control-range multislider2"
                                                />
                                                
                                */
                            }


                            <div className="title">Custom CSS</div>
                            <RangeSlider id="range-slider-grey"
                                className="single-thumb multislider1"
                                defaultValue={[0, retireAge]}
                                thumbsDisabled={[false, false]}
                                rangeSlideDisabled={false} 
                                value={[0, retireAge]}
                                onInput={e => setRetireAge(e[1])}
                                onThumbDragEnd={e => UpdateAssumption(retireAge, 'retire_age')}
                                min={selfAge + 1}
                                max={80}
                                step={1}

                                />
                            <RangeSlider id="range-slider-grey"
                                className="multislider2"
                                value={[0, lifeExpentancy]}
                                min={50}
                                max={100}
                                step={1}
                                defaultValue={[0, lifeExpentancy]}
                                thumbsDisabled={[true, false]}
                                rangeSlideDisabled={true} 
                                onInput={e => setLifeExpentancy(e[1])}
                                onThumbDragEnd={e => UpdateAssumption(lifeExpentancy, 'life_expentancy')}
                                />
                        </div>
                    </form>
                    <div className="flex_center justify_center mt8">
                        <div className="font12 fw500 color9ba">{selfAge + 1}</div>
                        <div className="font12 fw500 color9ba">100</div>
                    </div>
                </div>
            </div>


        </>
    )
}
