import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { apiUrl, ageCalculate } from '../global';
//import SweetAlert from 'sweetalert2';
//import Wealth from './goals/Wealth';
//import Marriage from './goals/Wealth';
//import Retirement from './goals/Retirement';
import SideImg from '../../img/Goals.png';

export default function GoalScreen3({ setSubstep, substep, StepChange, steps, nextStep, prevsubStep, goalType, setGoalType, clientName }) {

  const { register, handleSubmit, setFocus, formState: { errors } } = useForm();


  const [maxRequired, setMaxRequired] = useState(30);

  //const [rangeValue, onChange] = useState(1);

  // eslint-disable-next-line
  const [year1, setYear1] = useState(1);

  // eslint-disable-next-line
  const [year2, setYear2] = useState(1);

  // eslint-disable-next-line
  const [year3, setYear3] = useState(1);

  // eslint-disable-next-line
  const [year4, setYear4] = useState(18);

  // eslint-disable-next-line
  const [year5, setYear5] = useState(10);

  // eslint-disable-next-line
  const [year6, setYear6] = useState(5);

  // eslint-disable-next-line
  const [year7, setYear7] = useState(1);

  const setOnClick = (e, type) =>{
    switch (type) {
      case 'year1':
        setYear1('');
        break;

      case 'year2':
        setYear2('');
        break;

      case 'year3':
        setYear3('');
        break;

      case 'year4':
        setYear4('');
        break;

      case 'year7':
        setYear7('');
        break;
      default:
        break;
    }
  }

  const setYearInput = (e, type) => {

    switch (type) {
      case 'year1':
        setYear1(parseInt(e.target.value) > maxRequired ? parseInt(maxRequired) : parseInt(e.target.value) ? parseInt(e.target.value) : 1);
        break;

      case 'year2':
        setYear2(parseInt(e.target.value) > maxRequired ? parseInt(maxRequired) : parseInt(e.target.value) ? parseInt(e.target.value) : 1);
        break;

      case 'year3':
        setYear3(parseInt(e.target.value) > maxRequired ? parseInt(maxRequired) : parseInt(e.target.value) ? parseInt(e.target.value) : 1);
        break;

      case 'year4':
        setYear4(parseInt(e.target.value) > maxRequired ? parseInt(maxRequired) : parseInt(e.target.value) ? parseInt(e.target.value) : 18);
        break;

      case 'year5':
        setYear5(parseInt(e.target.value) > maxRequired ? parseInt(maxRequired) : parseInt(e.target.value) ? parseInt(e.target.value) : 1);
        break;

      case 'year6':
        setYear6(parseInt(e.target.value) > maxRequired ? parseInt(maxRequired) : parseInt(e.target.value) ? parseInt(e.target.value) : 1);
        break;

      case 'year7':
        setYear7(parseInt(e.target.value) > maxRequired ? parseInt(maxRequired) : parseInt(e.target.value) ? parseInt(e.target.value) : 1);
        break;
      default:
        break;
    }

  }

  const decrementYear1 = (e) => {
    if (year1 === 1) return;
    setYear1((year1) => year1 - 1);
    setFocus("goalsArr.0.year");
  };

  const incrementYear1 = (e) => {
    if (year1 === maxRequired) return;
    setYear1((year1) => year1 + 1);
    setFocus("goalsArr.0.year");
  };

  const decrementYear2 = () => {
    if (year2 === 1) return;
    setYear2((year2) => year2 - 1);
    setFocus("goalsArr.1.year");
  };

  const incrementYear2 = () => {
    if (year2 === maxRequired) return;
    setYear2((year2) => year2 + 1);
    setFocus("goalsArr.1.year");
  };

  const incrementYear3 = () => {
    if (year3 === maxRequired) return;
    setYear3((year3) => year3 + 1);
    setFocus("goalsArr.2.year");
  };
  const decrementYear3 = () => {
    if (year3 === 1) return;
    setYear3((year3) => year3 - 1);
    setFocus("goalsArr.2.year");
  };

  const incrementYear4 = () => {
    if (year4 === maxRequired) return;
    setYear4((year4) => year4 + 1);
    setFocus("goalsArr.3.year");
  };
  const decrementYear4 = () => {
    if (year4 === 18) return;
    setYear4((year4) => year4 - 1);
    setFocus("goalsArr.3.year");
  };

  const incrementYear5 = () => {
    if (year5 === maxRequired) return;
    setYear5((year5) => year5 + 1);
    setFocus("goalsArr.4.year");
  };
  const decrementYear5 = () => {
    if (year5 === 1) return;
    setYear5((year5) => year5 - 1);
    setFocus("goalsArr.4.year");
  };

  const incrementYear6 = () => {
    if (year6 === maxRequired) return;
    setYear6((year6) => year6 + 1);
    setFocus("goalsArr.5.year");
  };
  const decrementYear6 = () => {
    if (year6 === 1) return;
    setYear6((year6) => year6 - 1);
    setFocus("goalsArr.5.year");
  };

  const incrementYear7 = () => {
    if (year7 === maxRequired) return;
    setYear7((year7) => year7 + 1);
    setFocus("goalsArr.6.year");
  };
  const decrementYear7 = () => {
    if (year7 === 1) return;
    setYear7((year7) => year7 - 1);
    setFocus("goalsArr.6.year");
  };

  // eslint-disable-next-line
  const [goals, setGoals] = useState(JSON.parse(sessionStorage.getItem('goals')));

  const [healthFor, setHealthFor] = useState([]);

  // eslint-disable-next-line
  const [assumptions, setAssumptions] = useState([]);

  const [selfDetails, setSelfDetails] = useState([]);

  useEffect(() => {


    axios.get(`${apiUrl}profile-details/getfamily/` + sessionStorage.getItem('profile_id')).then(function (response, data) {
      setHealthFor(response.data);

      let self = response.data.find(product => product.relation === "self");
      setSelfDetails(self);

    });

    const postData = {
      'profile_id': sessionStorage.getItem('profile_id')
    }

    axios.post(`${apiUrl}get_assumption_by_profile_id`, postData).then(function (response) {
      setAssumptions(response.data);
    })


  }, []);


  useEffect(() => {
    setMaxRequired(parseInt((assumptions.life_expentancy - 1) - parseInt(ageCalculate(selfDetails.dob))));
  }, [maxRequired, selfDetails, assumptions]);

  //const [rangeValue, onChange] = useState(1);

  const onSubmit = data => {

    const profileId = sessionStorage.getItem('profile_id');


    data.goalsArr.forEach((goal, index) => {


      var profDetArr = goal.planFor.split('_');
      const profile_details_id = profDetArr[0];
      const age = ageCalculate(profDetArr[1]);

      if (goal.goal_type === "Marriage") {

        const PostData = {
          "goal_type": "Marriage",
          "goal_name": goal.goal_name !== "" ? goal.goal_name : "Marriage",
          "aspire": goal.aspire !== "" ? goal.aspire : "Marriage",
          "profile_id": profileId,
          "plan_for": {
            "profile_details_id": profile_details_id
          },
          "goal_params": {
            "age": age,
            "retire_age": sessionStorage.getItem('current_age'),
            "life_expectancy": parseInt(assumptions.life_expectancy),
            "post_inflation": 7,
            "post_return": 7,
            "goal_inflation": 7,
            "required_after": parseInt(year4),
            "required_till": parseInt(year4)
          },
          "risk_profile": {
            "available": false,
            "pre_return": 8,
            "risk_profile_id": profileId
          },
          "assumptions": {
            "assumption_id": false,
            "inflation": 7.5,
            "income_grow": "10"
          },
          "total_abcd": [
            {
              "amount": goal.amount * goal.moneyDigits,
              "frequency": 1,
              "no_of_times": 1,
              "gap": 0
            }
          ],
          "actual": [
            {
              "actual_y": 0,
              "frequency": 1,
              "actual_ls": 1,
              "grow": "0"
            }
          ]
        };

        axios.post(`${apiUrl}goals/add`, PostData)
          .then(function (response) {


          });

      } else {

        const life_expectancy = parseInt(assumptions.life_expectancy);

        const PostData = {
          "goal_type": "Wealth",
          "goal_name": goal.goal_name !== "" ? goal.goal_name : "Wealth",
          "aspire": goal.aspire !== "" ? goal.aspire : "House",
          "profile_id": profileId,
          "plan_for": {
            "profile_details_id": profile_details_id
          },
          "goal_params": {
            "age": age,
            "retire_age": goal.year,
            "life_expectancy": life_expectancy,
            "post_inflation": 7,
            "post_return": 7,
            "goal_inflation": 7,
            "required_after": goal.year,
            "required_till": goal.year
          },
          "risk_profile": {
            "available": false,
            "pre_return": 8,
            "risk_profile_id": profileId
          },
          "assumptions": {
            "assumption_id": false,
            "inflation": 7.5,
            "income_grow": "10"
          },
          "total_abcd": [
            {
              "amount": goal.amount * goal.moneyDigits,
              "frequency": 1,
              "no_of_times": 1,
              "gap": 0
            }
          ],
          "actual": [
            {
              "actual_y": 0,
              "frequency": 1,
              "actual_ls": 1,
              "grow": "0"
            }
          ]
        };

        axios.post(`${apiUrl}goals/add`, PostData)
          .then(function (response) {

          });
      }
    });


    axios.post(`${apiUrl}profile/update-stepper`, { profile_id: sessionStorage.getItem('profile_id'), step: steps + 1 }).then(function (response, data) {
      //StepChange(nextStep(steps));


      const gpostData = { profile_id: sessionStorage.getItem('profile_id') };
      axios.post(`${apiUrl}goals/reset`, gpostData)
        .then(function (response) {
          axios.post(`${apiUrl}profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
            (response, data) => {
              window.location.href = "/dashboard";
            }
          );
        })

    });



  }
  return (
    <Fragment>

      <div className="mainwrapper">

        <div className="form_title">
          <div className="mt30 font20 fw600">{clientName} Goal</div>
          <div className="font15 color495">Few questions to understand your family's protection</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li>1</li>
              <li onClick={() => setSubstep(prevsubStep(substep))}>2</li>
              <li className="act">3</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Lifestyle goals</div>
                  <div className="font15 color495 ">The goals that are focused toward a good lifestyle</div>
                </div>
                <div>

                  <button className="bluebtn font13 fw600 d-none d-md-block" type='submit'>Save
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}
              <div className="p35">
                {goals.map((data, i) => {
                  return (data.checked === true && (
                    <Fragment>
                      <div key={i}>


                        <div className="color212 font15 fw600">{data.aspire}</div>
                        <div className="form_prnt mt20">
                          <div className="form-row justify-content-between">
                            <div className="col-md-4 pr20 mb20">
                              <label htmlFor="inputEmail4" className="font14 fw600">Required Amount as of Today</label>
                              <div className="input-group selectarrow select50">
                                <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name={`goalsArr[${i}]amount`} autoComplete='off' {...register(`goalsArr.${i}.amount`, { required: 'Amount is required' })} maxLength={3} minLength={1} />
                                <select className="custom-select" id="inputGroupSelect01" name={`goalsArr[${i}]moneyDigits`} {...register(`goalsArr.${i}.moneyDigits`)}>
                                  <option selected value="100000">Lakhs</option>
                                  <option value="10000000">Crore</option>
                                </select>
                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                              </div>
                              <span className="text-danger"> {errors.goalsArr?.[i]?.amount?.message}</span>
                            </div>

                            <input type="hidden" value={data.goalName} name={`goalsArr[${i}]goal_name`} autoComplete='off' {...register(`goalsArr.${i}.goal_name`)} />
                            <input type="hidden" value={data.aspire} name={`goalsArr[${i}]aspire`} autoComplete='off' {...register(`goalsArr.${i}.aspire`)} />
                            <input type="hidden" value={data.id} name={`goalsArr[${i}]gId`} autoComplete='off' {...register(`goalsArr.${i}.gId`)} />


                            {
                              data.id === 1 && (<><div className="col-md-4 pl20 mb20">
                                <div className="font14 color212 fw600">In how many years ?</div>
                                <div className="mt8 quantity_wpr">
                                  <button onClick={() => decrementYear1()} type="button">-</button>
                                  <input type="text" className="font14 fw500 ml15 mr15" value={year1} name={`goalsArr[${i}]year`} autoComplete='off' {...register(`goalsArr.${i}.year`, { required: 'years is required' })} autoFocus="autoFocus" onChange={(e) => setYearInput(e, 'year1')} onClick={(e) => setOnClick(e, 'year1')}/>
                                  <button onClick={() => incrementYear1()} type="button" className='greenBtn'>+</button>
                                </div><br />
                                <span className="text-danger"> {errors.goalsArr?.[i]?.year?.message}</span>
                              </div> </>)

                            }


                            {
                              data.id === 2 && (<><div className="col-md-4 pl20 mb20">
                                <div className="font14 color212 fw600">In how many years ?</div>
                                <div className="mt8 quantity_wpr">
                                  <button onClick={() => decrementYear2()} type="button">-</button>
                                  <input type="text" className="font14 fw500 ml15 mr15" value={year2} name={`goalsArr[${i}]year`} autoComplete='off' {...register(`goalsArr.${i}.year`, { required: 'years is required' })} onChange={(e) => setYearInput(e, 'year2')} onClick={(e) => setOnClick(e, 'year2')}/>
                                  <button onClick={() => incrementYear2()} type="button" className='greenBtn'>+</button>
                                </div><br />
                                <span className="text-danger"> {errors.goalsArr?.[i]?.year?.message}</span>
                              </div></>)

                            }

                            {
                              data.id === 3 && (<><div className="col-md-4 pl20 mb20">
                                <div className="font14 color212 fw600">In how many years ?</div>
                                <div className="mt8 quantity_wpr">
                                  <button onClick={() => decrementYear3()} type="button">-</button>
                                  <input type="text" className="font14 fw500 ml15 mr15" value={year3} name={`goalsArr[${i}]year`} autoComplete='off' {...register(`goalsArr.${i}.year`, { required: 'years is required' })} onChange={(e) => setYearInput(e, 'year3')} onClick={(e) => setOnClick(e, 'year3')}/>
                                  <button onClick={() => incrementYear3()} type="button" className='greenBtn'>+</button>
                                </div><br />
                                <span className="text-danger"> {errors.goalsArr?.[i]?.year?.message}</span>
                              </div></>)

                            }


                            {
                              data.id === 4 && (<><div className="col-md-4 pl20 mb20">
                                <div className="font14 color212 fw600">Age at marriage</div>
                                <div className="mt8 quantity_wpr">
                                  <button onClick={() => decrementYear4()} type="button">-</button>
                                  <input type="text" className="font14 fw500 ml15 mr15" value={year4} name={`goalsArr[${i}]year`} autoComplete='off' {...register(`goalsArr.${i}.year`, { required: 'years is required' })} onChange={(e) => setYearInput(e, 'year4')} onClick={(e) => setOnClick(e, 'year4')}/>
                                  <button onClick={() => incrementYear4()} type="button" className='greenBtn'>+</button>
                                </div><br />
                                <span className="text-danger"> {errors.goalsArr?.[i]?.year?.message}</span>
                              </div></>)

                            }


                            {
                              data.id === 5 && (<><div className="col-md-4 pl20 mb20">
                                <div className="font14 color212 fw600">In how many years ?</div>
                                <div className="mt8 quantity_wpr">
                                  <button onClick={() => decrementYear5()} type="button">-</button>
                                  <input type="text" className="font14 fw500 ml15 mr15" value={year5} name={`goalsArr[${i}]year`} autoComplete='off' {...register(`goalsArr.${i}.year`, { required: 'years is required' })} onChange={(e) => setYearInput(e, 'year5')} onClick={(e) => setOnClick(e, 'year5')} readOnly />
                                  <button onClick={() => incrementYear5()} type="button" className='greenBtn'>+</button>
                                </div><br />
                                <span className="text-danger"> {errors.goalsArr?.[i]?.year?.message}</span>
                              </div></>)

                            }

                            {
                              data.id === 6 && (<><div className="col-md-4 pl20 mb20">
                                <div className="font14 color212 fw600">In how many years ?</div>
                                <div className="mt8 quantity_wpr">
                                  <button onClick={() => decrementYear6()} type="button">-</button>
                                  <input type="text" className="font14 fw500 ml15 mr15" value={year6} name={`goalsArr[${i}]year`} autoComplete='off' {...register(`goalsArr.${i}.year`, { required: 'years is required' })} onChange={(e) => setYearInput(e, 'year6')} onClick={(e) => setOnClick(e, 'year6')}/>
                                  <button onClick={() => incrementYear6()} type="button" className='greenBtn'>+</button>
                                </div><br />
                                <span className="text-danger"> {errors.goalsArr?.[i]?.year?.message}</span>
                              </div></>)

                            }

                            {
                              data.id === 7 && (<><div className="col-md-4 pl20 mb20">
                                <div className="font14 color212 fw600">In how many years ?</div>
                                <div className="mt8 quantity_wpr">
                                  <button onClick={() => decrementYear7()} type="button">-</button>
                                  <input type="text" className="font14 fw500 ml15 mr15" value={year7} name={`goalsArr[${i}]year`} autoComplete='off' {...register(`goalsArr.${i}.year`, { required: 'years is required' })} onChange={(e) => setYearInput(e, 'year7')} onClick={(e) => setOnClick(e, 'year7')}/>
                                  <button onClick={() => incrementYear7()} type="button" className='greenBtn'>+</button>
                                </div><br />
                                <span className="text-danger"> {errors.goalsArr?.[i]?.year?.message}</span>
                              </div></>)

                            }

                            <div className="col-md-4 pl30 mb20">
                              <div className="font14 color212 fw600">Goal focused On</div>
                              <div className="selectgoal propertysearch mt10">
                                <div className="input-group selectarrow">
                                  <select className="custom-select font14 color212 fw500" name={`goalsArr[${i}]planFor`} autoComplete='off' {...register(`goalsArr.${i}.planFor`, { required: 'goal for is required' })}>
                                    <option value="" selected>select</option>
                                    {healthFor.map((data, key) => {
                                      return (
                                        <option value={data.profile_details_id + '_' + data.dob} data-remove={data.first_name}>{data.first_name + ' ' + data.last_name}</option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <span className="text-danger"> {errors.goalsArr?.[i]?.planFor?.message}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <input type="hidden" className="form-control" name={`goalsArr[${i}]goal_type`} autoComplete='off' {...register(`goalsArr.${i}.goal_type`)} defaultValue={data.type} />
                      <div className="formdevider mt35 mb35" />
                    </Fragment>)
                  );
                })}
              </div>

              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={() => setSubstep(prevsubStep(substep))}> Back </button>
                <button type='submit' className="btn btn-primary"> Save </button>
              </div>

            </form>
            {/* Form End */}
          </div>
          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              Hard to predict monthly passive income at your retirement age. Check most entered values <a href="/dashboard">here</a>.
            </div>
            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
