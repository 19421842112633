import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BasicDetails from './ProfileStepper/BasicDetails'
import Protection from './ProfileStepper/Protection'
import Goals from './ProfileStepper/Goals'
import Cashflow from './ProfileStepper/Cashflow'
import Investments from './ProfileStepper/Investments'
import Compeleted from '../components/wizard/Compeleted'
import Header from '../components/layout/Header'
import axios from 'axios';
import { apiUrl } from '../components/global'
export default function ProfileStepper() {

    // eslint-disable-next-line
    const [members, setMembers] = useState([]);
    const [lifeStage, setLifeStage] = useState(null);
    const [childCount, setChildCount] = useState(1);


    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axios.get(`${apiUrl}profile/summary/${profile_id}`).then(
            (response, data) => {
                if (response.data.status === 100) {
                    
                    if(response.data.self_data.stepper === "1"){
                        setSteps(1);
                    }else{
                        setSteps(6);
                        
                    }
                    sessionStorage.setItem('profile_details_id', response.data.self_data.profile_details_id);
                    sessionStorage.setItem('current_age', response.data.self_data.current_age)
                    if(response.data.self_data.lifeStage !== null){

                        setLifeStage(response.data.self_data.lifeStage);

                        setChildCount((response.data.self_data.lifeStage === "single" || response.data.self_data.lifeStage === "married") ? 0 : (response.data.child_cnt !== 0 ? response.data.child_cnt : 1));
                    }
                    


                }
            });

        axios.get(`${apiUrl}profile-details/getfamily/${profile_id}`).then(
            (response, data) => {
                setMembers(response.data);

                //let spouse_prof_det_id = response.data.find(product => product.relation === "spouse");

                //sessionStorage.setItem('spouse_prof_det_id', spouse_prof_det_id ? spouse_prof_det_id.profile_details_id : null);

             
            });

    }, []);

    let navigate = useNavigate();

    const loggedInUser = sessionStorage.getItem("token");
    if (loggedInUser === null) {
        navigate("/sign-in");
    }

    const [steps, setSteps] = useState(6);

    const StepChange = (steps) => {
        setSteps(steps);
    }

    return (

        <Fragment>
            <Header steps={steps} />
            <div className="innercontainer mb40">


                {steps === 1 && (<BasicDetails StepChange={StepChange} steps={steps} childCount={childCount} setChildCount={setChildCount} lifeStage={lifeStage} setLifeStage={setLifeStage}/>)}
                {steps === 2 && (<Protection StepChange={StepChange} steps={steps} lifeStage={lifeStage}/>)}
                {steps === 3 && (<Goals StepChange={StepChange} steps={steps} lifeStage={lifeStage}/>)}
                {steps === 4 && (<Cashflow StepChange={StepChange} steps={steps} lifeStage={lifeStage}/>)}
                {steps === 5 && (<Investments StepChange={StepChange} steps={steps} lifeStage={lifeStage}/>)}
                {steps === 6 && (<Compeleted StepChange={StepChange} steps={steps} />)}
                
            </div>
        </Fragment>


    )
}