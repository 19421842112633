
import React, { Fragment } from 'react'

import img_1 from '../../img/qna-response/1_0-10.png';
import img_2 from '../../img/qna-response/2_11-15.png';
import img_3 from '../../img/qna-response/3_16-19.png';
import img_4 from '../../img/qna-response/4_20.png';
import SideImg from '../../img/BasicProfile.png';

export default function QnaResponse({ setSteps }) {



    let json = '';
    if (sessionStorage.getItem("userData") !== null) {
        json = JSON.parse(sessionStorage.getItem("userData"));
        setSteps(4);
    } else {
        setSteps(1);
    }



    // let FinnFit_Score = json.score / 20;
    /* let FinnFit_Score_text = '';
     let bg_class = '';
        if (FinnFit_Score <= 0.5) {
            FinnFit_Score_text = 'Your Financial Fitness needs urgent intervention.';
            bg_class = 'box-bg-red'; //#ff5942
    
        }
    
        if (FinnFit_Score > 0.5 && FinnFit_Score < 0.8) {
            FinnFit_Score_text = 'Your Financial Fitness can be better.';
            bg_class = 'box-bg-blue'; // #FD9D4F 
        }
        if (FinnFit_Score >= 0.8) {
            FinnFit_Score_text = 'Your Financial Fitness is in decent shape.';
            bg_class = 'box-bg-green'; // #e3b922eb yello
    
        }
        if (FinnFit_Score === 1) {
            FinnFit_Score_text = 'Your Financial Fitness is top notch!!'
            bg_class = 'box-bg-green'; // #22af4a green
    
        } */


    const params = () => {
        const f = JSON.parse(sessionStorage.getItem("QnaFirst"));
        const l = JSON.parse(sessionStorage.getItem("QnaLast"));
        sessionStorage.setItem("user_params", JSON.stringify({
            "name": f.fullName,
            "mobile": l.mobileNumber,
            "email": l.emailAddress,
        }));
        console.log(sessionStorage.getItem("user_params"));
        window.location = '/register';
    }

    const bookSlot = () => {
        const f = JSON.parse(sessionStorage.getItem("QnaFirst"));
        const l = JSON.parse(sessionStorage.getItem("QnaLast"));

        window.location = 'https://calendly.com/finnovate/financial-fitness-consultation?name='+f.fullName+'&email='+l.emailAddress;
    }

    let image = img_1;
    let FinnFit_Score_text = '';
    let FinnFit_Score_text_2 = '';
    let qna_response_title_style = '';
    //json.score =7;
    if (json.score <= 10) {
        image = img_1;
        FinnFit_Score_text = 'Your Financial Fitness needs urgent intervention.';
        FinnFit_Score_text_2 = 'Sign up for your free session today';
        //#ff5942 red
        qna_response_title_style = {
            color: 'white',
            background: '#ff5942',
        }
    }
    if (json.score >= 11 && json.score <= 15) {
        image = img_2;
        FinnFit_Score_text = 'Your Financial Fitness can be better.';
        FinnFit_Score_text_2 = 'You have some building blocks in place';
        // #FD9D4F kesariya
        qna_response_title_style = {
            color: 'white',
            background: '#FD9D4F',
        }
    }
    if (json.score >= 16 && json.score <= 19) {
        image = img_3;
        FinnFit_Score_text = 'Your Financial Fitness is in decent shape.';
        FinnFit_Score_text_2 = 'A few tweaks and you will be on your way to a perfect 20/20 score.';
        //#fcd64f yellow
        qna_response_title_style = {
            color: 'white',
            background: '#fcd64f',
        }
    }
    if (json.score >= 20) {
        image = img_4;
        FinnFit_Score_text = 'Your Financial Fitness is top notch!!'
        FinnFit_Score_text_2 = 'Want a free session anyways?';
        // #22af4a green
        qna_response_title_style = {
            color: 'white',
            background: '#22af4a',
        }
    }

    const QnaFirst = JSON.parse(sessionStorage.getItem("QnaFirst"));
    //  const QnaLast = JSON.parse(sessionStorage.getItem("QnaLast"));

    return (

        <Fragment>

            <div className="mainwrapper">
                <div className='form_title'>
                    <div className="mt30 font20 fw600">Get my FinnFit Report</div>
                    <div className="font15 color495">You will receive an email soon</div>

                </div>

                <div className="row formWpr mt25">
                    <div className="col-lg-8 formouter">


                        <div className="form_prnt pt25 pl35 pr35 pb35">



                            <div className='font20 fw600 mb-3'>
                                {QnaFirst.fullName}, Your FinnFit score is  {json.score}/20
                            </div>

                            <div className={' qna-response-title'} style={{qna_response_title_style}}>
                                {FinnFit_Score_text}
                            </div>
                            <br />

                            <p>FinnFit is a customized financial fitness program that partners with doctors so that you can become well-off and enjoy the fruits of your labour, into your winter years.</p>
                            <br />
                            <div>


                                <img src={image} alt=''></img>
                                <br />
                                <br />
                                <p><strong> {FinnFit_Score_text}</strong></p>
                                <p>{FinnFit_Score_text_2} </p>

                                <p>Book a Complimentary Financial Fitness Counselling session </p>
                                <p>and walk away with a Financial Plan worth 15k today </p>
                                <br />
                                <br />
                                <row className="col-12 d-none d-md-block">
                                    <button type="button" className="btn-sm bluebtn font13 fw600 " style={{
                                        'background': '#EBEEF3',
                                        'color': '#182338',
                                        'margin-right': '20px',
                                        'font-weight': 'normal',
                                        'width': '200px'
                                    }} onClick={() => params()}> Register to Plan</button>
                                    <button type="button" className="bluebtn font13 fw600" onClick={() => bookSlot()} style={{'width': '200px'}}>Book a slot</button>
                                </row>
                                <br />
                                <br />
                                <p>Disclaimer: This score is based on data provided by you. </p>
                            </div>

                            { /*
                            <h1>{json.score_breakup.BudgetingTaxation.key}  <strong>{json.score_breakup.BudgetingTaxation.score_weight}/{json.score_breakup.BudgetingTaxation.total_weight}</strong></h1>

                            <br />
                            <p>{json.score_breakup.BudgetingTaxation.message.score_text}  </p>

                            <br /><br />
                            <h1>{json.score_breakup.BudgetingTaxation.key}  <strong>{json.score_breakup.BudgetingTaxation.score_weight}/{json.score_breakup.BudgetingTaxation.total_weight}</strong> </h1>
                            <br />
                            <p>{json.score_breakup.BudgetingTaxation.message.score_text}   </p>

                            <br /><br />
                            <h1>{json.score_breakup.LoanManagement.key}  <strong>{json.score_breakup.LoanManagement.score_weight}/{json.score_breakup.LoanManagement.total_weight}</strong></h1>
                            <br />
                            <p>{json.score_breakup.LoanManagement.message.score_text}  </p>

                            <br /><br />
                            <h1>{json.score_breakup.RiskManagement.key}  <strong>{json.score_breakup.RiskManagement.score_weight}/{json.score_breakup.RiskManagement.total_weight}</strong></h1>
                            <br />
                            <p>{json.score_breakup.RiskManagement.message.score_text}  </p>

                            <br /><br />
                            <h1>{json.score_breakup.GoalsPlanning.key}  <strong>{json.score_breakup.GoalsPlanning.score_weight}/{json.score_breakup.GoalsPlanning.total_weight}</strong></h1>
                            <br />
                            <p>{json.score_breakup.GoalsPlanning.message.score_text}  </p>

                            <br /><br />
                            <h1>{json.score_breakup.WealthSuccession.key}  <strong>{json.score_breakup.WealthSuccession.score_weight}/{json.score_breakup.WealthSuccession.total_weight}</strong></h1>
                            <br />
                            <p>{json.score_breakup.WealthSuccession.message.score_text} </p>
 */ }

                        </div>

                        <br />
                        <br />
                        <div className='p35'>
                            <p style={{ 'font-size': '9px' }}>Disclaimer:</p>
                            <p style={{ 'font-size': '9px' }}>This financial fitness score is provided for educational and informational purposes only and is not intended to provide investment, tax, or legal advice. The score is calculated based on the information you provide and is not a guarantee of future performance or success. Your personal financial situation is unique, and the score may not reflect your true financial health. It is important to seek professional advice before making any financial decisions. The score is not a substitute for professional judgment and is subject to change without notice. The provider of this score assumes no responsibility for any errors or omissions in the information provided. By using this score, you agree to hold harmless the provider and its affiliates for any losses or damages that may result from any inaccuracies or incompleteness of the information.</p>
                        </div>


                        <div class="saveBtn backsaveBtn d-block d-sm-none">
                            <button type="button" class="btn btn-lg btn-green" onClick={() => params()} >Register to Plan </button>
                            <button type="button" class="btn btn-lg btn-primary" onClick={() => bookSlot()}>Book a slot </button>
                        </div>

                    </div>

                    <div className="col-lg-4 qouteouter d-none d-md-block">
                        <div className="qoutetext font18 fw500 color212 pl45 pr45">
                            <div className="qoutesign">
                                &#8220;
                            </div>
                            Having a written financial plan gives you a measurable goal to work toward. Because you can track your progress, you can reduce doubt or uncertainty about your decisions and make adjustments to help overcome obstacles that could derail you.
                        </div>


                        <div className="qouteimg"><img src={SideImg} alt='Completed'/></div>
                    </div>
                </div>

            </div>
        </Fragment>



    )
}
