import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { apiUrl } from '../components/global'
import SideImg from '../img/BasicProfile.png';
import VerifyOtp from './VerifyOtp';

export default function Register({ ulogin, relManager }) {

    let user_params = JSON.parse(sessionStorage.getItem("user_params"));
    if (user_params == null) {
        user_params = {
            name: '',
            mobile: '',
            email: ''
        }
    }

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();
    const [password, setpassword] = useState('')
    const [togglePassword, setTogglePassword] = useState(false)
    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }


    const handleChange = (e) => {
        const { value } = e.target;
        if (e.target.name === 'password') {
            setpassword(value);
        }
    }

    // eslint-disable-next-line
    const [verifyScreen, setVerifyScreen] = useState(false);
    // eslint-disable-next-line
    const [verifyPostData, setVerifyPostData] = useState([]);

    const onSubmit = Formdata => {

        console.log(Formdata);

        if (ulogin === 1) {

            const Postdata = {
                "name": Formdata.fullName,
                "mobile": Formdata.mobileNumber,
                "email": Formdata.emailAddress,
                "password": Formdata.password,
                "register": 1,
                "rel_manager": sessionStorage.getItem("admin_id") !== null ? sessionStorage.getItem("admin_id") : ''
            };

            axios.post(`${apiUrl}register`, Postdata)
                .then(function (response) {

                    axios.post(`${apiUrl}profile/add`, Postdata).then(function (response) {
                        sessionStorage.setItem('profile_id', response.data.profile_id);

                        axios.post(`${apiUrl}default-risk-profile`, { profile_id: response.data.profile_id }).then(function (response) {
                        });

                        axios.post(`${apiUrl}profile/summary-add`, { profile_id: response.data.profile_id }).then(function (response) {
                        });


                        if (excludechecked === false) {

                            let fullname = Formdata.fullName;
                            var names = fullname.split(' ');

                            const relationship_manager = sessionStorage.getItem('admin_role') === "0" ? 'FINNOVATE' : (sessionStorage.getItem('shortName') !== null ? sessionStorage.getItem('shortName') : 'FINNOVATE');

                            const apiData = {
                                "campaign_id": '9423',
                                "campaign_type": 'TeleSales',
                                "check_condication_1": '0',
                                "check_condication_2": '0',
                                "ignore_duplicate": '1',
                                "ignore_open_task": '1',
                                "check_condication_task": '0',
                                "consider_same_account": '0',
                                "match_col": 'email_and_mobile',
                                "lead_source": 'FinnPlan',
                                "lead_caimpaign": 'FinnPlan',
                                "relationship_manager": relationship_manager,
                                "tele_caller": '1000049',
                                "import_data": {
                                    0: {
                                        "f_name": names[0] ? names[0] : '',
                                        "m_name": '',
                                        "l_name": names[1] ? names[1] : '',
                                        "name": Formdata.fullName,
                                        "mobile": Formdata.mobileNumber,
                                        "email": Formdata.emailAddress
                                    }
                                }
                            };


                            axios.post(`https://lmslive.finnovate.in/import/import-everything-api.php`, apiData).then(function (response) {
                            });

                        }





                        axios.post(`${apiUrl}sendinblue/add-list`, { listid: 19, action: 'insert', email: Formdata.emailAddress, attributes: { EMAIL: Formdata.emailAddress, FULL_NAME: Formdata.mobileNumber, MOBILE: '+91' + Formdata.mobileNumber, SMS: '+91' + Formdata.mobileNumber, LEAD_SOURCE: 4 } }).then(function (response) {
                        });

                        axios.post(`${apiUrl}get-plan-steps`, { profile_id: response.data.profile_id }).then(
                            (response, data) => {
                                if (response.data.status === 100) {
                                    SweetAlert.fire(
                                        'Sign Up',
                                        'Successfully Registered On Finnovate.',
                                        'success'
                                    ).then(function () {
                                        window.location.href = ulogin === 1 ? "admin-dashboard" : "/sign-in";
                                        //window.location.href = "/sign-in"
                                    });
                                }
                            }
                        );



                    });

                })
                .catch(e => {

                    if (e.response.data.data.hasOwnProperty("password")) {
                        SweetAlert.fire({
                            toast: true,
                            icon: 'error',
                            title: e.response.data.data.password[0],
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                            }
                        });
                    } else {
                        SweetAlert.fire("Warning", "" + e.response.data.data.email[0] + "", "warning");
                    }

                });

        } else {

            const Postdata = {
                "full_name": Formdata.fullName,
                "mobile": Formdata.mobileNumber,
                "email": Formdata.emailAddress,
                "password": Formdata.password
            };

            setVerifyPostData(Formdata);

            axios.post(`${apiUrl}add-guestuser`, Postdata).then(
                function (response, data) {


                    console.log(response.data);

                    const Otpdata = {
                        "full_name": Formdata.fullName,
                        "mobile": Formdata.mobileNumber,
                        "email": Formdata.emailAddress,
                        "password": Formdata.password,
                        'guestId': response.data.id
                    };


                    axios.post(`${apiUrl}send-otp`, Otpdata).then(
                        function (response, data) {


                            console.log(response.data);

                            setVerifyScreen(true);
                        }
                    );

                }
            );

        }

    }

    /*const onSubmit = data => {
        sessionStorage.setItem("register", JSON.stringify(data));

        const Postdata = {
            "name": data.fullName,
            "mobile": data.mobileNumber,
            "email": data.emailAddress,
            "password": data.password,
            "register": 1,
            "rel_manager": ''
        };

        axios.post(`${apiUrl}register`, Postdata)
            .then(function (response) {

                axios.post(`${apiUrl}profile/add`, Postdata).then(function (response) {
                    sessionStorage.setItem('profile_id', response.data.profile_id);

                    axios.post(`${apiUrl}default-risk-profile`, { profile_id: response.data.profile_id }).then(function (response) {
                    });

                    axios.post(`${apiUrl}profile/summary-add`, { profile_id: response.data.profile_id }).then(function (response) {
                    });


                    axios.post(`${apiUrl}sendinblue/add-list`, { listid: 19, action: 'insert', email: data.emailAddress, attributes: { EMAIL: data.emailAddress, FULL_NAME: data.mobileNumber, MOBILE: '+91' + data.mobileNumber, SMS: '+91' + data.mobileNumber, LEAD_SOURCE: 4 } }).then(function (response) {
                    });

                    axios.post(`${apiUrl}get-plan-steps`, { profile_id: response.data.profile_id }).then(
                        (response, data) => {
                            if (response.data.status === 100) {
                                SweetAlert.fire(
                                    'Sign Up',
                                    'Successfully Registered On Finnovate.',
                                    'success'
                                ).then(function () {
                                    window.location.href = ulogin === 1 ? "admin-dashboard" : "/sign-in";
                                    //window.location.href = "/sign-in"
                                });
                            }
                        }
                    );



                });

            })
            .catch(e => {

                if (e.response.data.data.hasOwnProperty("password")) {
                    SweetAlert.fire({
                        toast: true,
                        icon: 'error',
                        title: e.response.data.data.password[0],
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    });
                } else {
                    SweetAlert.fire("Warning", "" + e.response.data.data.email[0] + "", "warning");
                }

            });
    }*/


    const [excludechecked, setExcludechecked] = useState(false);
    const excludeLms = (e) => {
        if (e.target.checked) {
            setExcludechecked(true);
        } else {
            setExcludechecked(false);
        }
    }

    return (
        <Fragment>

            <div className="innercontainer mb40">
                <div className="mainwrapper">

                    {
                        ulogin === 1 ? '' :


                            <div className='form_title'>
                                <div className="mt30 font20 fw600">Register</div>
                            </div>
                    }
                    <div className={ulogin === 1 ? "row" : "row formWpr mt25"}>
                        <div className={ulogin === 1 ? "col-lg-12 formouter" : "col-lg-8 formouter"}>

                            {
                                verifyScreen === true && (<VerifyOtp verifyPostData={verifyPostData} setVerifyScreen={setVerifyScreen} />)
                            }

                            {
                                verifyScreen === false && (

                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="formtitle flex_center justify_center">
                                            <div>
                                                <div className="font20 fw600">Enter your details to Register</div>
                                                <div className="font15 color495 ">Basic details</div>
                                            </div>
                                            <div>

                                                {
                                                    ulogin === 1 ? '' :

                                                        <button className="bluebtn font13 fw600" type='button' onClick={() => { window.location.href = "/sign-in" }}>Already Registered, Login In
                                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                                            </svg></span></button>

                                                }
                                            </div>
                                        </div>

                                        {/* Form */}
                                        <div className="form_prnt pt25 pl35 pr35 pb35">
                                            <div className="custome_form">

                                                <div className="form-row">

                                                    <div className="form-group col-md-6 pr20">
                                                        <label htmlFor="fullName" className="font14 fw600">Your full name</label>
                                                        <input type="text" className="form-control" id="fullName" name="fullName" autoComplete='off' {...register("fullName", { required: 'Name is required', minLength: { value: 5, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter full name" defaultValue={verifyPostData ? verifyPostData.full_name : ''} />
                                                        <span className="text-danger"> {errors.fullName && errors.fullName.message}</span>
                                                    </div>

                                                    <div className="form-group col-md-6 pl20">
                                                        <label htmlFor="emailAddress" className="font14 fw600">Email address</label>
                                                        <input type="text" className="form-control" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                                                        <span className="text-danger"> {errors.emailAddress && errors.emailAddress.message}</span>
                                                    </div>

                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 pr20">
                                                        <label htmlFor="mobileNumber" className="font14 fw600">Mobile number</label>
                                                        <div className="input-group mb2">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text fw500 font14 color212">+91-</div>
                                                            </div>
                                                            <input type="tel" className="form-control" id="mobileNumber" name="mobileNumber" autoComplete='off' {...register("mobileNumber", { required: 'Mobile Number is required', pattern: { value: /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/, message: 'Invalid Mobile Number' } })} placeholder="Mobile Number" />

                                                        </div>
                                                        <span className="text-danger"> {errors.mobileNumber && errors.mobileNumber.message}</span>
                                                    </div>


                                                    <div className="form-group col-md-6 pl20">
                                                        <label htmlFor="password" className="font14 fw600">Password</label>

                                                        <input className="form-control" type={togglePassword ? "text" : "password"} name="password" autoComplete='off' {...register("password", { required: 'Password is required', minLength: { value: 5, message: 'Password is too short' }, maxLength: { value: 15, message: 'Password is too long' } })} placeholder="Password" onChange={handleChange} value={password} />
                                                        <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                                                        <span className="text-danger"> {errors.password && errors.password.message}</span>


                                                    </div>

                                                </div>


                                                {

                                                    ulogin === 1 && (<>

                                                        <div className="form-row">
                                                            <div className="form-group col-md-6 pr20">
                                                            </div>

                                                            <div className="form-group col-md-6 pl20">

                                                                <div className="form-group mb-0">
                                                                    <div className="checkbox p-0">
                                                                        <input id="checkbox1" type="checkbox" name={"excludeLms"} className='mr8' onClick={(e) => excludeLms(e)} value={excludechecked} checked={excludechecked} />
                                                                        <label className="font14 fw600" htmlFor="checkbox1">Exlude LMS </label>

                                                                    </div>

                                                                </div>
                                                            </div>



                                                        </div>
                                                    </>)
                                                }




                                                <button className="bluebtn font13 fw600" type='submit' disabled={!isDirty && !isValid}>Register
                                                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                                    </svg></span></button>

                                            </div>
                                        </div>



                                    </form>
                                )
                            }
                            {/* Form End */}

                        </div>

                        {
                            ulogin === 1 ? '' :

                                <div className="col-lg-4 qouteouter d-none d-md-block">
                                    <div className="qoutetext font18 fw500 color212 pl45 pr45">
                                        <div className="qoutesign">
                                            &#8220;
                                        </div>
                                        Creating a Financial Plan is the first step towards achieving financial freedom!
                                    </div>


                                    <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                                </div>

                        }
                    </div>

                </div>
            </div>

        </Fragment>
    )
}
