import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { apiUrl } from '../global';
import SweetAlert from 'sweetalert2';
import formatAmount from 'indian-currency-formatter';

export default function FixedDeposits({ active, iValues, maxCount, nextActive, investment, activeAsset, current, setCurrent, StepChange, steps, nextStep }) {

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const nextStepCall = () => {
    if (current + 1 === maxCount) {
      axios.post(`${apiUrl}profile/update-stepper`, { profile_id: sessionStorage.getItem('profile_id'), step: steps + 1 }).then(function (response, data) {
        StepChange(nextStep(steps));
      });
    } else {
      setCurrent(current + 1);
      activeAsset(iValues[current + 1].id);
    }
  }

  const onSubmit = data => {

    const profileId = sessionStorage.getItem('profile_id');

    if (checkboxCheck === true && (data.selfCurrentVal !== "0" || data.selfInvested !== "0")) {

      const Postdata = {
        "prop_id": profileId,
        "profile_id": profileId,
        "profile_details_id": sessionStorage.getItem('profile_details_id'),
        "type": 2,
        "owner": profileId,
        "invest_type": 0,
        "sub_type": '1',
        "instr_name": 'Deposits',
        "frequency": data.selfFrequency,
        "current_value": data.selfCurrentVal * data.selfMoneyType,
        "cost_amount": data.selfCurrentVal * data.selfMoneyType,
        "amount": data.selfInvested.replace(/,/g, ""),
        "invest_amount": data.selfInvested.replace(/,/g, ""),
        "tenure": 1,
        "month_year": "Y",
        "invest_id": data.selfInvestId,
        "moneyDigits": data.selfMoneyType
      };

      // logical fields - start_date, months
      axios.post(`${apiUrl}investment/add-investment`, Postdata)
        .then(function (response) {
          nextStepCall();
        })
        .catch(function (error) {
          SweetAlert.fire(
            'Deposits',
            'Failed. Something went wrong. Retry again',
            'error'
          )
        });

    } else {

      nextStepCall();

    }



  }

  const [checkboxCheck, setCheckboxCheck] = useState(true);


  const validateCheckbox = (checkbox) => {

    if (checkbox === "no") {
      setCheckboxCheck(false);
    } else {
      setCheckboxCheck(true);
    }

  }


  const [selfInvestedAmount, setSelfInvestedAmount] = useState();

  const handleChange = (e) => {

    const { value } = e.target;

    if (e.target.name === 'selfInvested') {
      setSelfInvestedAmount(formatAmount(value.replace(/,/g, "")));
    }

  };


  const [fillData, setFillData] = useState(null);

  useEffect(() => {

    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null) {
      axios.post(`${apiUrl}investment/by-type-subtype`, { profile_id: profile_id, type: 2, sub_type: 1 }).then(
        (response, data) => {

          setFillData(
            {
              investmentCheck: 'yes',
              selfCurrentVal: (response.data[0].current_value / response.data[0].moneyDigits),
              selfMoneyType: response.data[0].moneyDigits,
              selfInvested: formatAmount(response.data[0].amount / response.data[0].frequency),
              selfFrequency: response.data[0].frequency,
              selfInvestId: response.data[0].invest_id,
            }
          );
        });

    } else {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/sign-in";
    }

  }, []);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(fillData);
    // eslint-disable-next-line
  }, [fillData]);




  return (
    <Fragment>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formtitle flex_center justify_center">
          <div>
            <div className="font20 fw600">Investment</div>
            <div className="font15 color495 ">Also a bit approximate idea about your commitments</div>
          </div>
          <div>


            <button className="bluebtn font13 fw600 d-none d-md-block" type='submit'>Save
              <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
              </svg></span></button>
          </div>
        </div>

        <div className="pt35">

          <div class="investmentTabs mb20">

            <ul>

              {investment.map((data, i) => {

                return (data.checked === true && (<Fragment>

                  <li className={data.id}>
                    <label>
                      <input type="radio" name="assets" />
                      <div class={active === data.id ? 'investmentTabs_text act' : 'investmentTabs_text'}>{data.name}</div>
                    </label>
                  </li>
                </Fragment>)
                );
              })}
            </ul>
          </div>

        </div>

        <div className="custome_form">




          <div className="p35">


            <div className="form-row mb-2">
              <div>
                <div className="color212 font14 fw600">Do you want to add Deposits? <span className="colorfa8">*</span>
                </div>
                <ul className="steps_tag font14 fw500 color182 mt15">
                  <li>
                    <label>
                      <input type="radio" name="investmentCheck" {...register("investmentCheck", { required: 'Required' })} value="yes" onClick={() => validateCheckbox('yes')} checked={checkboxCheck === true ? "checked":""}/>
                      <span className="rdo_text_box">
                        Yes
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" name="investmentCheck" {...register("investmentCheck", { required: 'Required' })} value="no" onClick={() => validateCheckbox('no')} checked={checkboxCheck === false ? "checked":""}/>
                      <span className="rdo_text_box">
                        No
                      </span>
                    </label>
                  </li>
                </ul>
                <span className="text-danger"> {errors.investmentCheck && errors.investmentCheck.message}</span>
              </div>
            </div>


            {

              checkboxCheck === true &&

              (
                <>
                  <div className="form-row mt20">
                    <div className="col-md-6 pr20">
                      <label htmlFor="inputEmail4" className="font14 fw600">Current Value of your deposits <span className="colorfa8">*</span></label>
                      <div className="input-group selectarrow">
                        <input type="text" className="form-control color212 font14 fw500" name="selfCurrentVal" {...register("selfCurrentVal", { required: 'Required. Enter 0 to skip' })} maxLength={3} minLength={1} />
                        <select className="custom-select" id="inputGroupSelect01" name='selfMoneyType' {...register("selfMoneyType")}>
                          <option value="100000" defaultValue={"100000"}>Lakhs</option>
                          <option value="10000000">Crore</option>
                        </select>

                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                      </div>
                      <span className="text-danger"> {errors.selfCurrentVal && errors.selfCurrentVal.message}</span>
                    </div>
                    <div className="col-md-6 pl20">
                      <label htmlFor="inputEmail4" className="font14 fw600">Periodic investment</label>
                      <div className="input-group selectarrow">
                        <input type="text" className="form-control color212 font14 fw500" {...register("selfInvested", { required: 'Required. Enter 0 to skip' })} maxLength={10} minLength={1} onChange={handleChange} value={selfInvestedAmount} />
                        <select className="custom-select" id="inputGroupSelect01" name="selfFrequency" {...register("selfFrequency")}>
                          <option value="12">Monthly</option>
                          <option value="4">Quarterly</option>
                          <option value="2">Half Yearly</option>
                          <option value="1">Yearly</option>
                          <option value="0">Lumpsum</option>
                        </select>
                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                      </div>
                      <span className="text-danger"> {errors.selfInvested && errors.selfInvested.message}</span>
                    </div>
                  </div>
                  <input type="hidden" className="form-control" name="selfInvestId" autoComplete='off' {...register("selfInvestId")} />
                </>
              )
            }


          </div>

        </div>

        <div className="saveBtn d-block d-sm-none">
          <button type='submit' className="btn btn-primary">Save  <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
        </div>

      </form>
    </Fragment>
  )
}
