import React, { useEffect, useState } from 'react'
import { inWords } from '../../global';
import EditEmiById from './EditEmiById';

export default function EditEmi({ toggleClass, setActive, emi }) {

    console.log(emi);

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const [loans, setLoans] = useState([{
        "id": 1,
        "instr_name": "Home Loan",
        "sub_type": 1,
        "type": 20,
        "profile_id": sessionStorage.getItem("profile_id"),
        "profile_details_id": sessionStorage.getItem("profile_details_id"),
        "loan_amount": 0,
        "moneyDigits": 100000,
        "emi": 0,
        "outstanding_loan": 0,
        checked: true
    }, {
        "id": 2,
        "instr_name": "Car Loan",
        "sub_type": 2,
        "type": 20,
        "profile_id": sessionStorage.getItem("profile_id"),
        "profile_details_id": sessionStorage.getItem("profile_details_id"),
        "loan_amount": 0,
        "moneyDigits": 100000,
        "emi": 0,
        "outstanding_loan": 0,
        checked: true
    }, {
        "id": 3,
        "instr_name": "Personal Loan",
        "sub_type": 3,
        "type": 20,
        "profile_id": sessionStorage.getItem("profile_id"),
        "profile_details_id": sessionStorage.getItem("profile_details_id"),
        "loan_amount": 0,
        "moneyDigits": 100000,
        "emi": 0,
        "outstanding_loan": 0,
        checked: true
    }, {
        "id": 4,
        "instr_name": "Other Loan",
        "sub_type": 6,
        "type": 20,
        "profile_id": sessionStorage.getItem("profile_id"),
        "profile_details_id": sessionStorage.getItem("profile_details_id"),
        "loan_amount": 0,
        "moneyDigits": 100000,
        "emi": 0,
        "outstanding_loan": 0,
        checked: true
    }]);

    const [addEmi, setAddEmi] = useState([]);

    useEffect(() => {
        console.log("First call on mount..");
        
        let result = loans.filter(o1 => !emi.some(o2 => parseInt(o1.sub_type) === parseInt(o2.sub_type)));
        console.log('result ', result)
        setAddEmi(result);
        return () => console.log("Cleanup..");
    }, [emi, loans]);



    return (
        <div className="rightSide_menu_outer">
            <div className="rightSide_menu">
                <div className="rightSide_menu_header">
                    <div>
                        <button type="button" class="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                    </div>
                    <div className="font14 fw500 color182">
                        Edit EMI
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div className="topBanner pl25">
                    <div className="font18 fw600 color182 mt50">EMI</div>
                    <div className="font14 fw500 color485">{sessionStorage.getItem('full_name')} family’s commitments</div>
                </div>
                <div className="pl20 pr20">

                    {
                        emi.map((loan, i) => {

                            return (
                                <>
                                    <div className="collapse_outer mt20">
                                        <div className="collapse_title">
                                            <div className="font16 fw600 color263">{loan.instr_name}</div>
                                            <div>
                                                <button className="btn btn-outline-primary font12 fw500 expandBtn" type="button" data-toggle="collapse" data-target={"#collapseExample" + i} aria-expanded="false" aria-controls="collapseExample">
                                                    + Expand
                                                </button>
                                            </div>
                                        </div>
                                        <div className="expandtab_outer mt12">
                                            <div className="expandtab font12 color353 fw500">{loan.full_name} <b>{inWords(loan.loan_amount)}</b></div>
                                        </div>
                                        <div class="collapse" id={"collapseExample" + i}>
                                            <EditEmiById liability={loan} />

                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }


                    {

                        addEmi.length > 0 && (
                            <>
                                {
                                    addEmi.map((loan, i) => {

                                        return (
                                            <>
                                                <div className="collapse_outer mt20">
                                                    <div className="collapse_title">
                                                        <div className="font16 fw600 color263">{loan.instr_name}</div>
                                                        <div>
                                                            <button className="btn btn-outline-primary font12 fw500 expandBtn" type="button" data-toggle="collapse" data-target={"#addcollapseExample" + i} aria-expanded="false" aria-controls="collapseExample">
                                                                + Expand
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="expandtab_outer mt12">
                                                        <div className="expandtab font12 color353 fw500">{loan.full_name} <b>{inWords(loan.loan_amount)}</b></div>
                                                    </div>
                                                    <div class="collapse" id={"addcollapseExample" + i}>
                                                        <EditEmiById liability={loan} />

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </>
                        )
                    }


                </div>

            </div>
        </div>
    )
}
